/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAvatarOptData } from "./actions/index";

import {
  h1hairArray,
  h2hairArray,
  h3hairArray,
  h4hairArray,
  h5hairArray,
  h6hairArray,
  eyeArray,
  h1beardArray,
  h2beardArray,
  h3beardArray,
  h4beardArray,
  h5beardArray,
  h6beardArray,
  glassArray,
  mouthArray,
  shoesArray,
  s1headsArray,
  s2headsArray,
  s3headsArray,
  s4headsArray,
  bottomsArray,
  h1eyeBrowArray,
  h2eyeBrowArray,
  h3eyeBrowArray,
  h4eyeBrowArray,
  h5eyeBrowArray,
  h6eyeBrowArray,
  noseArray,
  s1topArray,
  s2topArray,
  s3topArray,
  s4topArray,
} from "./avatarParts";

const Options = (props) => {
  const {
    navOpt,
    optSkinColor,
    optHairColor,
    optBeardColor,
    optEyebrowColor,
    saveAvatarOptData,
    FetchAvatarReducer,
  } = props;
  const [avOptData, setAvOptData] = useState(
    FetchAvatarReducer?.avatar?.length > 0 ? FetchAvatarReducer.avatar : []
  );
  const [selected, setSelected] = useState({
    part_name: navOpt,
    part_index: -1,
  });

  var indexSelected = parseInt(avOptData[0].partNumber) - 1;
  var dataArray = [];
  var hIncr = false;

  navOpt == "hair" ||
  navOpt == "beard" ||
  navOpt == "top" ||
  navOpt == "head" ||
  navOpt == "eye_brow"
    ? (hIncr = true)
    : (hIncr = false);

  switch (navOpt) {
    case "hair":
      switch (optHairColor) {
        case "h1":
          dataArray = h1hairArray;
          break;
        case "h2":
          dataArray = h2hairArray;
          break;
        case "h3":
          dataArray = h3hairArray;
          break;
        case "h4":
          dataArray = h4hairArray;
          break;
        case "h5":
          dataArray = h5hairArray;
          break;
        case "h6":
          dataArray = h6hairArray;
          break;

        default:
          break;
      }
      indexSelected = parseInt(avOptData[4].partNumber) - 1;
      break;
    case "eyes":
      dataArray = eyeArray;
      indexSelected = parseInt(avOptData[5].partNumber) - 1;
      break;
    case "beard":
      switch (optBeardColor) {
        case "h1":
          dataArray = h1beardArray;
          break;
        case "h2":
          dataArray = h2beardArray;
          break;
        case "h3":
          dataArray = h3beardArray;
          break;
        case "h4":
          dataArray = h4beardArray;
          break;
        case "h5":
          dataArray = h5beardArray;
          break;
        case "h6":
          dataArray = h6beardArray;
          break;

        default:
          break;
      }
      indexSelected = parseInt(avOptData[9].partNumber) - 1;
      break;
    case "glasses":
      dataArray = glassArray;
      indexSelected = parseInt(avOptData[10].partNumber) - 1;
      break;
    case "mouth":
      dataArray = mouthArray;
      indexSelected = parseInt(avOptData[8].partNumber) - 1;
      break;
    case "bottom":
      dataArray = bottomsArray;
      indexSelected = parseInt(avOptData[1].partNumber) - 1;
      break;
    case "eye_brow":
      switch (optEyebrowColor) {
        case "h1":
          dataArray = h1eyeBrowArray;
          break;
        case "h2":
          dataArray = h2eyeBrowArray;
          break;
        case "h3":
          dataArray = h3eyeBrowArray;
          break;
        case "h4":
          dataArray = h4eyeBrowArray;
          break;
        case "h5":
          dataArray = h5eyeBrowArray;
          break;
        case "h6":
          dataArray = h6eyeBrowArray;
          break;

        default:
          break;
      }
      indexSelected = parseInt(avOptData[6].partNumber) - 1;
      break;
    case "head":
      switch (optSkinColor) {
        case "s1":
          dataArray = s1headsArray;
          break;
        case "s2":
          dataArray = s2headsArray;
          break;
        case "s3":
          dataArray = s3headsArray;
          break;
        case "s4":
          dataArray = s4headsArray;
          break;

        default:
          break;
      }
      indexSelected = parseInt(avOptData[3].partNumber) - 1;
      break;
    case "nose":
      dataArray = noseArray;
      indexSelected = parseInt(avOptData[7].partNumber) - 1;
      break;
    case "shoes":
      dataArray = shoesArray;
      indexSelected = parseInt(avOptData[2].partNumber) - 1;
      break;
    case "top":
      switch (optSkinColor) {
        case "s1":
          dataArray = s1topArray;
          break;
        case "s2":
          dataArray = s2topArray;
          break;
        case "s3":
          dataArray = s3topArray;
          break;
        case "s4":
          dataArray = s4topArray;
          break;

        default:
          break;
      }
      indexSelected = parseInt(avOptData[0].partNumber) - 1;
      break;
    default:
      break;
  }

  var handleSelectedOpt = (data) => {
    setSelected({ part_index: data - 1 });
    data != null && data >= 10 ? (data = `${data}`) : (data = "0" + data);

    let index = _.findIndex(
      FetchAvatarReducer.avatar,
      (e) => {
        return e.partName === navOpt;
      },
      0
    );

    if (index >= 0) {
      avOptData[index].partNumber = data;
      avOptData[index].partName = navOpt;
      setSelected({ part_name: navOpt });
    } else {
      avOptData.push({ partNumber: data, partName: navOpt });
    }

    setAvOptData(avOptData);

    var obj = {
      skincolor: optSkinColor,
      haircolor: optHairColor,
      avatar: avOptData,
    };

    saveAvatarOptData(obj);
  };

  return (
    <div
      className={
        hIncr == true
          ? "p-2 sm:p-2 overflow-y-auto h-avatar-c-h-incr"
          : "p-2 sm:p-2 overflow-y-auto h-avatar-content-height"
      }
    >
      {dataArray.length !== 0 &&
        dataArray.map((data, index) => {
          return (
            <div
              key={index}
              className={
                indexSelected == index || selected.part_index == index
                  ? "h-avatar-select-height w-avatar-select-width border-2 border-solid border-primary hover:border-primary m-2 flex items-center justify-center cursor-pointer"
                  : "h-avatar-select-height w-avatar-select-width border border-1px border-solid border-#EEF0F3 hover:border-primary m-2 flex items-center justify-center cursor-pointer"
              }
              onClick={() => handleSelectedOpt(index + 1)}
            >
              <img src={data} />
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    FetchAvatarReducer: state.FetchAvatarReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveAvatarOptData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Options);
