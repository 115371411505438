import axios from "axios";
import _ from "lodash";

import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import { BASE_URL, APP_API_KEY } from "../../../components/Common/constant";

export const SHOW_PHOTO_VIEW_MODAL = "SHOW_MODAL";
export const FETCH_COURSE_VIDEO_DATA = "FETCH_COURSE_VIDEO_DATA";

export function showPhotoViewModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_PHOTO_VIEW_MODAL, payload: obj });
  };
}

export function fetchCourseVideo(session, callback) {
  var url = `${BASE_URL}api/v2/custom_script?type=fetchCourseVideo&eventid=${session.eventid}&userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("fetchCourseVideo res: ", response);

        dispatch({
          type: FETCH_COURSE_VIDEO_DATA,
          payload: { data: response.data },
        });
        callback({ success: 1, data: response.data });
      },
      (error) => {
        callback({ success: 0, data: [] });
      }
    );
  };
}
