import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { confirmAlert } from "react-confirm-alert";
import { useToasts } from "react-toast-notifications";
import {
  handleAddTeamMemberModal,
  updateTeamMember,
  fetchTeamClientEntrantCount,
  deleteTeamMemberSession,
} from "../actions";
import {
  COMMON_FAIL_MESSAGE,
  LOCAL_TO_GMT_TIME,
} from "../../../components/Common/constant";
import LocaleStrings from "../../../languages";

const TeamMemberList = (props) => {
  const { addToast } = useToasts();
  var { printList, currentPage, session } = props;

  const openAddTeam = () => {
    props.handleAddTeamMemberModal({ showModal: true, value: printList });
  };

  const handleWithdraw = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = {
      id: printList.id,
      withdrawndate: LOCAL_TO_GMT_TIME,
    };

    confirmAlert({
      title: LocaleStrings.teammember_withdraw_confirm_title,
      message: `${LocaleStrings.teammember_withdraw_confirm_subtitle} ${printList.firstname} ${printList.lastname} ?`,
      buttons: [
        {
          label: LocaleStrings.button_cancel,
          onClick: () => {},
        },
        {
          label: LocaleStrings.button_withdraw,
          onClick: () => {
            props.updateTeamMember(session, values, (response) => {
              if (response.success === 0) {
                addToast(COMMON_FAIL_MESSAGE, {
                  appearance: "error",
                  autoDismiss: true,
                });
              } else {
                props.pagination(currentPage);
                props.fetchTeamClientEntrantCount(session);
                props.deleteTeamMemberSession(session, printList?.dfid);
                addToast(LocaleStrings.teammember_withdraw_alert_success, {
                  appearance: "success",
                  autoDismiss: true,
                });
              }
            });
          },
        },
      ],
    });
  };

  return (
    <tr className={`${printList?.withdrawndate ? "bg-gray-100" : ""}`}>
      <td className="table-col-td">{printList.firstname}</td>
      <td className="table-col-td">{printList.lastname.charAt(0)}</td>
      <td className="table-col-td">{printList.username}</td>
      <td className="table-col-td">{printList.password}</td>
      <td className="table-col-td">
        {printList?.withdrawndate ? (
          <div>
            <span className="text-red-400">
              {LocaleStrings.button_withdrawn}
            </span>
          </div>
        ) : (
          <div className="d-flex">
            {printList?.ismanager ? (
              ""
            ) : (
              <span
                className="mr-2 cursor-pointer text-blue-400"
                onClick={(e) => handleWithdraw(e)}
              >
                {LocaleStrings.button_withdraw}
              </span>
            )}
            <span
              className="cursor-pointer text-blue-400"
              onClick={(e) => openAddTeam(e)}
            >
              {LocaleStrings.button_edit}
            </span>
          </div>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateTeamMember,
      handleAddTeamMemberModal,
      fetchTeamClientEntrantCount,
      deleteTeamMemberSession,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberList);
