import { SHOW_PHOTO_VIEW_MODAL, FETCH_COURSE_VIDEO_DATA } from "../actions";

export function showPhotoViewModalReducer(state = {}, action) {
  if (action.type === SHOW_PHOTO_VIEW_MODAL) {
    return action.payload;
  }
  return state;
}

export function courseVideoReducer(state = {}, action) {
  if (action.type === FETCH_COURSE_VIDEO_DATA) {
    return action.payload;
  }
  return state;
}
