import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { logout } from "../actions";
import { fetchAvatarDBData } from "../pages/avatarBuilder/actions";
import { fetchHomeScreenData } from "../pages/home/actions";
import { BASE_AZIONV2_URL, FILE_ACCESS_KEY } from "./Common/constant";
import arrowRotation from "../assets/img/arrow-rotation-interface.png";
import menu from "../assets/img/menu.png";
import HomeIcon from "../assets/img/home.png";
import LocaleStrings from "../languages";

const Navbar = (props) => {
  const {
    toggle,
    routes,
    isLoggedIn,
    eventDetails,
    session,
    logout,
    fetchHomeScreenData,
    fetchAvatarDBData,
  } = props;

  const [loadRefresh, setLoadRefresh] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const createLinks = () => {
    const routesFiltered = _.filter(routes, function (list) {
      return list.display;
    }); // custom check to not to display Home in navbar

    // console.log("routesFiltered: ", routesFiltered);
    // console.log("session: ", session);
    return routesFiltered.map((item, idx) => {
      // if (session.ismanager) {
      //   if (session.eventtype == "company") {
      //     if (item.path != "/view-my-team" && item.path != "/manage-class") {
      //       return (
      //         <Link className='p-4 text-white' to={item.path}>
      //           {item.name}
      //         </Link>
      //       );
      //     }
      //   } else {
      //     if (item.path != "/view-my-team" && item.path != "/manage-team") {
      //       return (
      //         <Link className='p-4 text-white' to={item.path}>
      //           {item.name}
      //         </Link>
      //       );
      //     }
      //   }
      // } else {
      //   if (
      //     item.path != "/view-all-teams" &&
      //     item.path != "/manage-class" &&
      //     item.path != "/manage-team"
      //   ) {
      //     return (
      //       <Link className='p-4 text-white' to={item.path}>
      //         {item.name}
      //       </Link>
      //     );
      //   }
      // }

      var textcolor =
        item.path === location.pathname ? "text-white" : "text-refresh";
      // --- new changes ---
      if (session.ismanager) {
        if (session.eventtype == "company") {
          if (item.path != "/manage-class") {
            return (
              <Link
                key={`nav_key_${idx}`}
                className={`p-4 ${textcolor}`}
                to={item.path}
              >
                {" "}
                {item.name == "Home" ? (
                  <span className="">
                    <img src={HomeIcon} />
                  </span>
                ) : (
                  item.name
                )}
              </Link>
            );
          }
        } else {
          if (item.path != "/manage-team") {
            return (
              <Link
                key={`nav_key_${idx}`}
                className={`p-4 ${textcolor}`}
                to={item.path}
              >
                {item.name == "Home" ? (
                  <span className="">
                    <img src={HomeIcon} />
                  </span>
                ) : (
                  item.name
                )}
              </Link>
            );
          }
        }
      } else {
        if (item.path != "/manage-class" && item.path != "/manage-team") {
          return (
            <Link
              key={`nav_key_${idx}`}
              className={`p-4 ${textcolor}`}
              to={item.path}
            >
              {item.name == "Home" ? (
                <span className="">
                  <img src={HomeIcon} />
                </span>
              ) : (
                item.name
              )}
            </Link>
          );
        }
      }
    });
  };

  const handleLogout = () => {
    logout(session);
    history.push("/login");
  };

  const handleRefresh = () => {
    setLoadRefresh(true);
    fetchHomeScreenData(session, (res) => {
      // console.log("res:", res);
      fetchAvatarDBData(session, session.userid, (res1) => {
        // console.log("res: ", res1);
      });
      setLoadRefresh(false);
    });
  };

  // console.log("eventDetails: ", eventDetails);
  let logo = `${BASE_AZIONV2_URL}${
    eventDetails?.resource?.logo ?? "defaults/default_logo.png"
  }?api_key=${FILE_ACCESS_KEY}`;

  return (
    <nav
      className="flex items-center lg:justify-between h-16 bg-transparent m-2 text-white relative font-sans"
      role="navigation"
    >
      {isLoggedIn ? (
        <div
          className="cursor-pointer bg-primary rounded-md lg:hidden"
          onClick={toggle}
          style={{ padding: "12px 10px" }}
        >
          <img src={menu} />
        </div>
      ) : (
        ""
      )}

      {isLoggedIn ? (
        <Link to="/" className="pl-4 cursor-pointer">
          <div className="flex justify-center pb-4 md:pb-1 lg:pb-0">
            <img src={logo} />
          </div>
          {/* <span className="text-xs">{eventDetails?.resource?.name}</span> */}
        </Link>
      ) : (
        <div className="pl-4">
          <div className="flex justify-center pb-4 md:pb-1 lg:pb-0">
            <img src={logo} />
          </div>

          {/* <span className="text-xs">{eventDetails?.resource?.name}</span> */}
        </div>
      )}

      {isLoggedIn ? (
        <div className="pr-8 lg:flex items-center hidden text-base">
          {createLinks()}
          <button
            className="bg-refresh rounded inline-flex items-center py-2 px-4 ml-2"
            onClick={() => handleRefresh()}
          >
            {loadRefresh ? (
              <svg
                className="animate-spin h-4 w-4 rounded-full border-t-2 border-b-2 border-white mr-0"
                // className='animate-spin h-5 w-5 rounded-full border-b border-white mr-3'
                viewBox="0 0 24 24"
              ></svg>
            ) : (
              <img src={arrowRotation} />
            )}
            <span className="ml-2">{LocaleStrings.button_refresh}</span>
          </button>
          <button
            className="bg-refresh rounded inline-flex items-center py-2 px-4 ml-2 cursor-pointer"
            onClick={() => handleLogout()}
          >
            <span className="">{LocaleStrings.button_logout}</span>
          </button>
        </div>
      ) : (
        ""
      )}
    </nav>
  );
};

const mapStateToProps = (state) => {
  // console.log("eventDetails: ", state.eventDetails);

  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    deviceSizeReducer: state.deviceSize,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { logout, fetchHomeScreenData, fetchAvatarDBData },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
