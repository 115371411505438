import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import { addTeacherInterest } from "./action";
import { OPEN_ACCESS } from "../../components/Common/constant";

const data = {
  classes: [
    {
      firstname: "",
      lastname: "",
      schoolname: "",
      region: "",
      noofmembers: null,
      email: "",
      confirmemail: "",
    },
  ],
};

const renderField = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error, warning },
}) => {
  return (
    <div>
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
      </label>
      <div className="mt-1">
        <input
          className="enter-data-steps-input"
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderClasses = ({ fields, meta: { error, submitFailed } }) => {
  reset();
  // console.log("fields: ", fields);
  return (
    <ul>
      {fields.map((cl, index) => (
        <li key={index}>
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <div className="">
                <span className="">Class: {index + 1}</span>
              </div>
              <div className="text-sm">
                {index == 0 ? (
                  ""
                ) : (
                  <button
                    type="button"
                    title="Remove Member"
                    className="mt-2 btn bg-red-700 text-white rounded-lg h-9"
                    style={{ width: 80 }}
                    onClick={() => fields.remove(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.firstname`}
                  type="text"
                  component={renderField}
                  label="First Name"
                  placeholder="Enter your name"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.lastname`}
                  type="text"
                  component={renderField}
                  label="Last Name"
                  placeholder="Enter your last name"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.schoolname`}
                  type="text"
                  component={renderField}
                  label="School Name"
                  placeholder="Enter your school name"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.region`}
                  type="text"
                  component={renderField}
                  label="Region"
                  placeholder="Enter your region"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.noofmembers`}
                  type="number"
                  component={renderField}
                  label="No. of Students"
                  placeholder="Select a number"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.email`}
                  type="email"
                  component={renderField}
                  label="Email Address"
                  placeholder="Enter Your Email"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.confirmemail`}
                  type="email"
                  component={renderField}
                  label="Confirm Email"
                  placeholder="Renter Your Email"
                />
              </div>
            </div>
          </div>
        </li>
      ))}
      <li>
        <div className="flex mt-6 ml-6 items-center">
          <button
            type="button"
            className="mt-2 btn bg-refresh text-white rounded-lg h-9"
            style={{ width: 100 }}
            onClick={() =>
              fields.push({
                firstname: "",
                lastname: "",
                schoolname: "",
                region: "",
                noofmembers: null,
                email: "",
                confirmemail: "",
              })
            }
          >
            Add
          </button>
          <span className="ml-8 text-gray-700">
            Click here if you want to add more classes
          </span>
        </div>
        {submitFailed && error && <span>{error}</span>}
      </li>
    </ul>
  );
};

const RegForm = (props) => {
  const {
    deviceSize,
    session,
    eventDetails,
    handleSubmit,
    pristine,
    reset,
    submitting,
  } = props;

  const onSubmitForm = (values) => {
    var customSession = { apiKey: OPEN_ACCESS, eventid: session.eventid };
    var data = [];
    values?.classes.map((ele) => {
      let obj = {
        eventidfk: session.eventid,
        firstname: ele.firstname,
        lastname: ele.lastname,
        email: ele.email,
        schoolname: ele.schoolname,
        noofmembers: ele.noofmembers,
        region: ele.region,
      };
      data.push(obj);
    });

    props.addTeacherInterest(customSession, data, (response) => {
      // console.log("response :- ", response);
      // reset();
    });
  };

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center">
              <h2 className="text-5xl pb-3 pt-3 mb-3">
                Teachers Register Your Interest
              </h2>
              <span className="">
                Please fill the details below, then click submit.
              </span>
            </div>
            <div
              className="bg-secondary-2 py-2 rounded-lg"
              style={
                deviceSize.width > 768
                  ? { minHeight: 450, overflow: "hidden" }
                  : { minHeight: 850, overflow: "hidden" }
              }
              // style={deviceSize.width > 768 ? { height: 550 } : { height: 950 }}
            >
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="grid grid-cols-3 gap-6 px-12 mt-5">
                  <div className="col-span-3">
                    <FieldArray name="classes" component={renderClasses} />
                  </div>
                </div>
                <div className="flex justify-center mb-3 mt-6">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="mt-4 btn bg-refresh text-white rounded-lg h-9"
                    style={{ width: 150 }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const validate = (values) => {
  var errors = {
    classes: [],
  };

  values?.classes?.forEach((v, i) => {
    errors.classes.push({});
    if (!v.firstname) {
      errors.classes[i].firstname = "Required*";
    } else if (v.firstname.length > 15) {
      errors.classes[i].firstname = "Must be 15 characters or less";
    }

    if (!v.lastname) {
      errors.classes[i].lastname = "Required*";
    }
    if (!v.schoolname) {
      errors.classes[i].schoolname = "Required*";
    }
    if (!v.region) {
      errors.classes[i].region = "Required*";
    }

    if (!v.noofmembers) {
      errors.classes[i].noofmembers = "Required*";
    } else if (isNaN(Number(v.noofmembers))) {
      errors.classes[i].noofmembers = "Must be a number";
    }

    if (!v.email) {
      errors.classes[i].email = "Required*";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v.email)) {
      errors.classes[i].email = "Invalid email address";
    }

    if (!v.confirmemail) {
      errors.classes[i].confirmemail = "Required*";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v.confirmemail)
    ) {
      errors.classes[i].confirmemail = "Invalid email address";
    } else if (v.email != v.confirmemail) {
      errors.classes[i].confirmemail = "Emails do not match";
    }
  });
  return errors;
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
    initialValues: data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addTeacherInterest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RegistrationForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RegForm)
);
