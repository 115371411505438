/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

import shoe from "../../assets/img/shoe.png";
import percentage from "../../assets/img/percentage.png";
import locationstar from "../../assets/img/locationstar.png";
import running from "../../assets/img/ic-directions-run-24px.png";
import watch_video from "../../assets/img/WatchVideo.png";
import plus from "../../assets/img/plus.png";
import minus from "../../assets/img/minus.png";
import markerRed from "../../assets/img/marker_red.png";

import Loader from "../../components/Common/loader";
import {
  BASE_FILES_URL,
  FILE_ACCESS_KEY,
  BASE_AZIONV2_URL,
} from "../../components/Common/constant";
import LocaleStrings from "../../languages";

import Member from "./components/member";
import Timer from "./components/timer";
import EnterData from "../mydata/components/enterdata";

import AvatarBuilder from "../avatarBuilder";
import MyAvatar from "../avatarBuilder/MyAvatar";
import AvatarFace from "../avatarBuilder/MyAvatarFace";
import LegVideo from "./components/leg-video";

import {
  fetchHomeScreenData,
  showMemberModal,
  setHomeData,
  showLegVideoModal,
} from "./actions";
import { showEnterDataModal, fetchEventClientLegs } from "../mydata/actions";
import { fetchAvatarDBData } from "../avatarBuilder/actions";
import { fetchCourseVideo } from "../coursevideo/actions";
import AnimatedNumber from "animated-number-react";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const Home = (props) => {
  const {
    deviceSize,
    loginUserDetails,
    fetchHomeScreenData,
    session,
    showMemberModal,
    homeScreenData,
    showEnterDataModal,
    showEnterDataModalReducer,
    fetchAvatarDBData,
    showMemberModalReducer,
    fetchCourseVideo,
    courseVideoReducer,
    fetchEventClientLegs,
    homeData,
    showLegVideo,
    eventDetails,
  } = props;
  const history = useHistory();

  const [idx, setIdx] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [legno, setLegno] = useState("");
  const [legname, setLegname] = useState("");
  const [teamavgtotal, setTeamavgtotal] = useState("");
  const [teamavgdaily, setTeamavgdaily] = useState("");
  const [pointsreqtofinish, setPointsreqtofinish] = useState(72000);
  const [percentagecomplete, setPercentagecomplete] = useState(0);
  const [legmappath, setLegmappath] = useState("");
  const [markerIndex, setMarkerIndex] = useState(0);
  const [markerArr, setMarkerArr] = useState([{ x: 0, y: 0 }]);

  useEffect(() => {
    // setLoading(true);
    fetchHomeScreenData(session, (res) => {
      // let newMinus = 0;
      if (res.data) {
        //newMinus = pointsreqtofinish - res.data.pointsreqtofinish;
        if (_.isEmpty(homeData)) {
          makeAllData(res.data, 1);
        } else {
          if (homeData.legno == res.data.legno) {
            makeAllData(res.data, 1);
            //setTimeout(() => makeAllData(res.data, 1), 2000);
          } else {
            makeAllData(homeData, 2);
            //setTimeout(() => makeAllData(homeData), 500);
            setTimeout(() => makeConfirmation(homeData.legname), 1000);
          }
        }
      }
      // console.log(res);
      fetchAvatarDBData(session, session.userid, (res1) => {
        // console.log("res: ", res1);
      });
      fetchCourseVideo(session, (res) => {
        // console.log("res: ", res);
      });
      fetchEventClientLegs(session, (res) => {});
      setLoading(false);
    });
    // console.log("loginUserDetails: ", loginUserDetails);
  }, []);

  const makeAllData = (obj, isLeg) => {
    setLegmappath(obj.legmappath);
    setLegno(obj.legno);
    setLegname(obj.legname);
    setTeamavgtotal(Math.floor(obj.teamavgtotal));
    setTeamavgdaily(Math.floor(obj.teamavgdaily));
    setPointsreqtofinish(
      isLeg == 2
        ? 0
        : isLeg == 3
        ? eventDetails.resource?.teamavgptreqtofinisheachlegopen
        : Math.floor(obj.pointsreqtofinish)
    );
    setPercentagecomplete(
      isLeg == 2 ? 100 : isLeg == 3 ? 0 : Math.floor(obj.percentagecomplete)
    );

    let percentangeDiff = 1;
    let markerIndex = 0;
    let arr = [{ x: 0, y: 0 }];
    if (obj.legmappoints) {
      let pointsarr = JSON.parse(obj.legmappoints);

      arr = pointsarr.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.x === item.x && t.y === item.y)
      );

      percentangeDiff = 100 / arr.length;
      var indexCalculation = Math.floor(
        obj.percentagecomplete / percentangeDiff
      );

      if (isLeg == 2) {
        markerIndex = arr.length - 1;
      } else if (isLeg == 3) {
        markerIndex = 0;
      } else {
        markerIndex = arr?.[indexCalculation]
          ? indexCalculation
          : arr.length - 1;
      }

      setMarkerArr(arr);
      setMarkerIndex(markerIndex);
    }
  };

  const makeConfirmation = (slegname) => {
    confirmAlert({
      title: "",
      message: "Congratulations you have completed " + slegname,
      buttons: [
        {
          label: "View Video now",
          onClick: () => {
            props.showLegVideoModal({ show: true });
          },
        },
        {
          label: "View Later",
          onClick: () => {
            setTimeout(() => makeAllData(homeScreenData.data, 3), 1000);
            setTimeout(() => makeAllData(homeScreenData.data, 1), 3000);
          },
        },
      ],
    });
  };

  const openVideoModal = () => {
    props.showLegVideoModal({ show: true });
  };

  const class_edit_your_avatar =
    "underline text-primary sm:text-sm cursor-pointer";
  const class_big_avatar = "flex justify-center mt-8 sm:mt-6 sm:mb-6";
  const class_my_avatar =
    "flex justify-center mt-7 sm:mt-6 sm:mb-6 h-home-canvas-height";

  // let arr = [
  //   { x: 349, y: 422 },
  //   { x: 320, y: 320 },
  //   { x: 298, y: 290 },
  //   { x: 270, y: 278 },
  //   { x: 251, y: 265 },
  //   { x: 255, y: 190 },
  //   { x: 324, y: 160 },
  //   { x: 353, y: 117 },
  //   { x: 358, y: 67 },
  // ];

  // --- marker calculations ---
  // let arr = [{ x: 0, y: 0 }];
  // let percentangeDiff = 1;
  // let markerIndex = 0;

  // if (homeScreenData?.data?.legmappoints) {
  //   let pointsarr = JSON.parse(homeScreenData?.data?.legmappoints);

  //   arr = pointsarr.filter(
  //     (item, index, self) =>
  //       index === self.findIndex((t) => t.x === item.x && t.y === item.y)
  //   );
  //   // console.log("arr: ", arr);

  //   percentangeDiff = 100 / arr.length;
  //   var indexCalculation = Math.floor(
  //     homeScreenData?.data?.percentagecomplete / percentangeDiff
  //   );
  //   // markerIndex = Math.min(arr.length - 1, indexCalculation);
  //   markerIndex = arr?.[indexCalculation] ? indexCalculation : arr.length - 1;
  // }
  // --- * ---

  const handleCourseVideo = () => {
    const path = "/course-video";
    history.push(path);
  };

  const handleCourseMap = () => {
    const path = "/course-map";
    history.push(path);
  };

  const handleAvatar = () => {
    setShowAvatarModal(true);
  };

  const moveElement = () => {
    var markerx, markery;
    if (idx < markerArr.length) {
      setIdx((i) => i + 1);
      markerx = markerArr[idx].x;
      markery = markerArr[idx].y;
      // if (markery > 520) window.scrollBy(0, 500);
      document.getElementById("marker").style.left = "" + markerx + "px";
      document.getElementById("marker").style.top = "" + markery + "px";
    } else {
      setIdx(1);
      markerx = markerArr[0].x;
      markery = markerArr[0].y;
      // window.scrollTo(0, 0);
      document.getElementById("marker").style.left = "" + markerx + "px";
      document.getElementById("marker").style.top = "" + markery + "px";
    }
  };

  const openMemberModal = (data) => {
    showMemberModal({ show: true, data: data });
  };

  const handleDataEntryModal = (type) => {
    if (type === "mydata") {
      showEnterDataModal({
        show: true,
        data: {
          callfrom: "home",
          name:
            homeScreenData?.data?.membername?.split(" ")[0] +
            " " +
            homeScreenData?.data?.membername?.split(" ")[1]?.charAt(0),
          type: type,
        },
      });
    } else {
      showEnterDataModal({
        show: true,
        data: {
          callfrom: "home",
          name:
            homeScreenData?.data?.membername?.split(" ")[0] +
            " " +
            homeScreenData?.data?.membername?.split(" ")[1]?.charAt(0),
          type: type,
        },
      });
    }
  };

  const redirectToDataEntry = () => {
    let starttime = homeScreenData.data?.challengecoursestartdate;
    let endtime = homeScreenData.data?.challengecourseclosedate;

    let currentDate = moment();
    let validflag = true;
    if (
      currentDate.isAfter(moment(starttime)) &&
      currentDate.isAfter(moment(endtime))
    ) {
      validflag = true;
    } else if (currentDate.isBetween(moment(starttime), moment(endtime))) {
      validflag = true;
    } else {
      validflag = false;
    }
    if (validflag) {
      props.setHomeData(homeScreenData.data);
      const path = "/data-entry";
      history.push(path);
    } else {
      confirmAlert({
        title: "",
        message:
          "Data Entry opens when the Challenge starts on " +
          moment(starttime).format("DD MMM YYYY"),
        buttons: [
          {
            label: "Close",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const redirectToClassEntry = () => {
    let starttime = homeScreenData.data?.challengecoursestartdate;
    let endtime = homeScreenData.data?.challengecourseclosedate;

    let currentDate = moment();
    let validflag = true;
    if (
      currentDate.isAfter(moment(starttime)) &&
      currentDate.isAfter(moment(endtime))
    ) {
      validflag = true;
    } else if (currentDate.isBetween(moment(starttime), moment(endtime))) {
      validflag = true;
    } else {
      validflag = false;
    }

    if (validflag) {
      props.setHomeData(homeScreenData.data);
      const path = "/class-entry";
      history.push(path);
    } else {
      confirmAlert({
        title: "",
        message:
          "Data Entry opens when the Challenge starts on " +
          moment(starttime).format("DD MMM YYYY"),
        buttons: [
          {
            label: "Close",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const generateNumberTick = (num) => {
    let output = [];
    let sNumber = num.toString();

    for (var i = 0, len = sNumber.length; i < len; i += 1) {
      output.push(
        <span className="numbers__window">
          <span
            className={
              "numbers__window__digit numbers__window__digit--" + (i + 1)
            }
            data-fake={
              i % 2 == 0 ? 8642519073 : sNumber.charAt(i) + "642519073"
            }
          >
            {sNumber.charAt(i)}
          </span>
        </span>
      );
    }
    return output;
  };

  const onScroll = (e) => {
    const currentScrollY = e.target.scrollTop;

    //console.log("currentScrollY", currentScrollY);
  };

  const formatValue = (value) => {
    return Math.floor(value);
  };

  let memberName =
    homeScreenData?.data?.membername.split(" ")[0] +
    " " +
    homeScreenData?.data?.membername.split(" ")[1].charAt(0);
  // console.log("arr :- ", arr);
  //console.log("markerIndex :- ", markerIndex);
  //console.log("markerArr :- ", markerArr);
  // console.log("homeData :- ", homeData);

  let starttime = homeScreenData?.data?.challengecoursestartdate;
  let endtime = homeScreenData?.data?.challengecourseclosedate;

  let currentDate = moment();
  let validflag = true;
  if (
    starttime &&
    currentDate.isAfter(moment(starttime)) &&
    endtime &&
    currentDate.isAfter(moment(endtime))
  ) {
    validflag = true;
  } else if (
    starttime &&
    endtime &&
    currentDate.isBetween(moment(starttime), moment(endtime))
  ) {
    validflag = true;
  } else {
    validflag = false;
  }

  return (
    <div className="p-0 sm:p-2">
      {!loading ? (
        <>
          <div
            className="bg-secondary grid grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-4 p-2 sm:p-4"
            style={
              deviceSize.width >= 640
                ? { borderRadius: 13 }
                : { borderRadius: 0 }
            }
          >
            <div className="">
              <div className="bg-refresh text-white rounded-t-lg p-4">
                <div className="text-base">
                  {homeScreenData?.data?.teamname}
                </div>
                <div className="text-sm">
                  {LocaleStrings.leg} {legno}: {legname?.capitalize()}
                </div>
              </div>

              <div className="grid grid-cols-3 bg-white">
                <div className="col-span-1 p-2 sm:p-4 border-secondary3 border-r border-b">
                  <div className="py-2 text-xl">{memberName}</div>
                  <div
                    className={
                      deviceSize.width <= 375
                        ? `${class_my_avatar}`
                        : `${class_my_avatar} mb-16`
                    }
                    style={deviceSize.width <= 375 ? { marginBottom: 91 } : {}}
                  >
                    <MyAvatar />
                  </div>
                  <div className="flex justify-center">
                    <a
                      className={
                        deviceSize.width <= 375
                          ? `${class_edit_your_avatar} text-xs`
                          : `${class_edit_your_avatar} text-sm`
                      }
                      onClick={() => handleAvatar()}
                    >
                      {LocaleStrings.edit_your_avatar}
                    </a>
                  </div>

                  {/* {homeScreenData?.data?.enteredcurrentdaydata ? (
                    <button
                      href='#/'
                      className='mt-4 btn bg-refresh text-white rounded-lg h-9 cursor-not-allowed'
                    >
                      <img src={running} className='hidden sm:block' />
                      <span className='ml-1 text-xs sm:text-sm'>
                        {LocaleStrings.enter_my_data}
                      </span>
                    </button>
                  ) : (
                    <button
                      href='#/'
                      className='mt-4 btn bg-refresh text-white rounded-lg h-9'
                      onClick={() => handleDataEntryModal("mydata")}
                    >
                      <img src={running} className='hidden sm:block' />
                      <span className='ml-1 text-xs sm:text-sm'>
                        {LocaleStrings.enter_my_data}
                      </span>
                    </button>
                  )} */}

                  <button
                    href="#/"
                    className="mt-4 btn bg-refresh text-white rounded-lg h-9"
                    // onClick={() => handleDataEntryModal("mydata")}
                    onClick={() => redirectToDataEntry()}
                  >
                    <img src={running} className="hidden sm:block" />
                    <span className="ml-1 text-xs sm:text-sm">
                      {LocaleStrings.enter_my_data}
                    </span>
                  </button>

                  {/* {session.ismanager &&
                  homeScreenData?.data?.isallowteammanager ? (
                    homeScreenData?.data?.enteredcurrentdayteamdata ? (
                      <button
                        href='#/'
                        className='mt-2 btn bg-teamdata text-white rounded-lg h-9 cursor-not-allowed'
                      >
                        <span className='ml-2 text-xs sm:text-sm'>
                          {session.eventtype === "school"
                            ? LocaleStrings.enter_class_data
                            : LocaleStrings.enter_team_data}
                        </span>
                      </button>
                    ) : (
                      <button
                        href='#/'
                        className='mt-2 btn bg-teamdata text-white rounded-lg h-9'
                        onClick={() => handleDataEntryModal("classdata")}
                      >
                        <span className='ml-2 text-xs sm:text-sm'>
                          {session.eventtype === "school"
                            ? LocaleStrings.enter_class_data
                            : LocaleStrings.enter_team_data}
                        </span>
                      </button>
                    )
                  ) : (
                    ""
                  )} */}

                  {session.ismanager &&
                  homeScreenData?.data?.isallowteammanager ? (
                    <button
                      href="#/"
                      className="mt-2 btn bg-teamdata text-white rounded-lg h-9"
                      //onClick={() => handleDataEntryModal("classdata")}
                      onClick={() => redirectToClassEntry()}
                    >
                      <span className="ml-2 text-xs sm:text-sm">
                        {session.eventtype === "school"
                          ? LocaleStrings.enter_class_data
                          : LocaleStrings.enter_team_data}
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-span-2 p-2 sm:p-4 border-secondary3 border-b">
                  <div className="flex justify-between">
                    <div>
                      <div className="text-sm">
                        {LocaleStrings.team_avg_daily}
                      </div>
                      <div className="text-lg text-point">
                        {Math.floor(teamavgdaily ?? 0)}
                        <span className="text-xs">{LocaleStrings.pt}</span>
                      </div>
                    </div>
                    <div>
                      <div className="text-sm">
                        {LocaleStrings.team_avg_total}
                      </div>
                      <div className="text-lg text-point">
                        {Math.floor(teamavgtotal ?? 0)}
                        <span className="text-xs">pt</span>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-lg mt-4 bg-shades_of_blue_1">
                    <div className="flex justify-between p-2">
                      <div className="">
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.name}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {memberName}
                        </div>
                      </div>
                      <div>
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.daily_avg}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {Math.floor(
                            homeScreenData?.data?.memberdailyavg ?? 0
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.total}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {Math.floor(
                            homeScreenData?.data?.membertotalpoints ?? 0
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="px-2 h-6" id="progress-bar">
                      <div className="relative pt-1">
                        <div className="progress-bar-container h-2 mb-4">
                          <div
                            style={{
                              width: `${homeScreenData?.data?.memberprogress}%`,
                            }}
                            className="progress-bar bg-progressbarprogress1"
                          ></div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div
                    className="mt-8"
                    style={{ height: deviceSize.width >= 640 ? 164 : 242 }}
                  >
                    <div
                      className="grid grid-cols-3 sm:grid-cols-4 gap-2 member-list-container"
                      style={{
                        maxHeight: deviceSize.width >= 640 ? 164 : 242,
                        overflowY: "scroll",
                      }}
                    >
                      {_.map(
                        homeScreenData?.data?.teammemberdata,
                        (member, idx) => {
                          return (
                            <div
                              key={idx}
                              className="cursor-pointer"
                              onClick={(e) => openMemberModal(member)}
                            >
                              <div className="flex justify-center h-home-face-h">
                                <AvatarFace
                                  memberAvatarObj={JSON.parse(member.avatar)}
                                />
                              </div>
                              <div className="pt-1 text-xs sm:text-sm flex justify-center">
                                {/* {member.firstname} */}
                                {member.firstname.split(" ").length > 1
                                  ? member.firstname.split(" ")[0] +
                                    " " +
                                    member?.lastname?.charAt(0)
                                  : member.firstname +
                                    " " +
                                    member?.lastname?.charAt(0)}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <div
                    className={deviceSize.width >= 640 ? "" : "mt-9"}
                    style={deviceSize.width >= 640 ? { marginTop: 60 } : {}}
                  >
                    <div className="text-xs sm:text-sm mb-1">
                      {LocaleStrings.percentage_of_course_completed}
                    </div>
                    <div>
                      <div className="relative pt-1">
                        <div className="progress-bar-container h-6 mb-1">
                          <div
                            className="progress-bar bg-progressbarprogress2"
                            style={{
                              width: `${Math.floor(
                                homeScreenData?.data?.target ?? 0
                              )}%`,
                            }}
                          ></div>
                          <span className="absolute left-2 top-2 text-white">
                            {validflag
                              ? Math.floor(homeScreenData?.data?.target ?? 0)
                              : 0}
                            %
                          </span>
                          <span className="absolute right-2 top-2 text-progressbarright">
                            {LocaleStrings.target}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="relative pt-1">
                        <div className="progress-bar-container h-6 mb-0">
                          <div
                            className="progress-bar bg-progressbarprogress3"
                            style={{
                              width: `${homeScreenData?.data?.actual}%`,
                            }}
                          ></div>
                          <span className="absolute left-2 top-2 text-white">
                            {Math.floor(homeScreenData?.data?.actual ?? 0)}%
                          </span>
                          <span className="absolute right-2 top-2 text-progressbarright">
                            {LocaleStrings.actual}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Timer data={homeScreenData?.data} />
            </div>
            <div>
              <div
                className="w-full mapmarker-container pb-4 rounded-lg"
                onScroll={onScroll}
              >
                <div
                  className="col-span-2 bg-cover rounded-lg relative"
                  style={{
                    height: 565,
                    backgroundImage: `url("${BASE_AZIONV2_URL}${legmappath}?api_key=${FILE_ACCESS_KEY}")`,
                    ...(deviceSize.isMobile ? { width: 537 } : {}),
                  }}
                >
                  <img
                    id="marker"
                    className="absolute transition-all duration-1000 ease-in-out"
                    // width='24px'
                    // height='24px'
                    src={markerRed}
                    style={{
                      top: markerArr[markerIndex].y,
                      left: markerArr[markerIndex].x,
                    }}
                  />
                  {!deviceSize.isMobile ? (
                    <div className="absolute w-full px-4 py-2 sm:py-4 top-0">
                      <div className="rounded-t h-10 bg-primary text-white text-sm py-2 px-3">
                        {legname?.capitalize()}
                      </div>
                      <div className="rounded-b h-12 bg-white">
                        <div className="grid grid-cols-2 px-4 sm:px-3 py-2 sm:py-3">
                          <div className="flex items-center col-span-2 sm:col-span-1 pb-2 sm:pb-0 border-secondary3 border-b sm:border-b-0 border-r-0 sm:border-r">
                            {/* <div className="kenya-to-egypt-container-icons">
                              <img src={shoe} />
                            </div> */}
                            <div className="ml-4">
                              <div className="text-xs">
                                {LocaleStrings.points_required_to_finish}
                              </div>
                            </div>
                            <div className="pl-3 text-base">
                              {/* {Math.floor(pointsreqtofinish ?? 0)} */}
                              {/* <AnimatedNumber
                                value={pointsreqtofinish}
                                formatValue={() =>
                                  formatValue(pointsreqtofinish)
                                }
                                duration={300}
                              /> */}
                              <div className="numbers">
                                {generateNumberTick(pointsreqtofinish)}
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center col-span-2 sm:col-span-1 pt-2 sm:pt-0 sm:pl-3">
                            {/* <div className="kenya-to-egypt-container-icons">
                              <img src={percentage} />
                            </div> */}
                            <div className="ml-4">
                              <div className="text-xs">
                                {LocaleStrings.percentage_complete}
                              </div>
                            </div>
                            <div className="pl-3 text-base flex items-center">
                              <div className="numbers">
                                {generateNumberTick(percentagecomplete)}
                              </div>
                              <div>%</div>
                              {/* {Math.floor(percentagecomplete ?? 0)}% */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {deviceSize.isMobile ? (
                <div className="w-full py-2 sm:py-4 ">
                  <div className="rounded-t h-10 bg-primary text-white text-sm py-2 px-3">
                    {legname?.capitalize()}
                  </div>
                  <div className="rounded-b bg-white">
                    <div className="grid grid-cols-2 px-4 sm:px-3 py-2 sm:py-3">
                      <div className="flex items-center col-span-2 sm:col-span-1 pb-2 sm:pb-0 border-secondary3 border-b sm:border-b-0 border-r-0 sm:border-r">
                        {/* <div className="kenya-to-egypt-container-icons">
                          <img src={shoe} />
                        </div> */}
                        <div className="ml-4">
                          <div className="text-xs">
                            {LocaleStrings.points_required_to_finish}
                          </div>
                          {/* <div className="text-base">
                            {Math.floor(
                              homeScreenData?.data?.pointsreqtofinish ?? 0
                            )}
                          </div> */}
                        </div>
                        <div className="pl-3 text-base">
                          {Math.floor(pointsreqtofinish ?? 0)}
                        </div>
                      </div>

                      <div className="flex items-center col-span-2 sm:col-span-1 pt-2 sm:pt-0 sm:pl-3">
                        {/* <div className="kenya-to-egypt-container-icons">
                          <img src={percentage} />
                        </div> */}
                        <div className="ml-4">
                          <div className="text-xs">
                            {LocaleStrings.percentage_complete}
                          </div>
                          {/* <div className="text-base">
                            {Math.floor(
                              homeScreenData?.data?.percentagecomplete ?? 0
                            )}
                            %
                          </div> */}
                        </div>
                        <div className="pl-3 text-base">
                          {Math.floor(percentagecomplete ?? 0)}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
                <div
                  className="col-span-2 sm:col-span-1 bg-primary course-video-map-container"
                  onClick={() => handleCourseVideo()}
                >
                  <div>
                    <img src={watch_video} />
                  </div>
                  <div className="ml-3">
                    <div className="text-sm">{LocaleStrings.course_video}</div>
                    <div className="text-xs">
                      {LocaleStrings.click_to_watch_your_videos}
                    </div>
                  </div>
                </div>
                <div
                  className="col-span-2 sm:col-span-1 bg-teamdata course-video-map-container"
                  onClick={() => handleCourseMap()}
                >
                  <div className="">
                    <img src={locationstar} />
                  </div>
                  <div className="ml-4">
                    <div className="text-sm">{LocaleStrings.course_map}</div>
                    <div className="text-xs">
                      {LocaleStrings.view_the_full_standings_map}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showMemberModalReducer && showMemberModalReducer.show ? (
            <Member />
          ) : (
            ""
          )}
          {/* {showEnterDataModalReducer && showEnterDataModalReducer.show ? <EnterData /> : ""} */}
          <EnterData />
          {showAvatarModal === true ? (
            <AvatarBuilder
              showAvatarModal={showAvatarModal}
              setShowAvatarModal={setShowAvatarModal}
            />
          ) : (
            ""
          )}
          {showLegVideo && showLegVideo.show ? (
            <LegVideo makeAllData={makeAllData} />
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log("deviceSize = ", state.deviceSize);
  // console.log("homeScreenDataReducer: ", state.homeScreenDataReducer);
  // console.log("state.FetchAvatarReducer: ", state.FetchAvatarReducer);
  // console.log("state.courseVideoReducer: ", state.courseVideoReducer);
  // console.log(
  //   "state.eventClientLegsListReducer: ",
  //   state.eventClientLegsListReducer
  // );

  return {
    session: state.session,
    deviceSize: state.deviceSize,
    loginUserDetails: state.loginUserDetails,
    homeScreenData: state.homeScreenDataReducer,
    showEnterDataModalReducer: state.showEnterDataModalReducer,
    FetchAvatarReducer: state.FetchAvatarReducer,
    showMemberModalReducer: state.showMemberModalReducer,
    courseVideoReducer: state.courseVideoReducer,
    eventClientLegsListReducer: state.eventClientLegsListReducer,
    homeData: state.homeData,
    showLegVideo: state.showLegVideo,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchHomeScreenData,
      showMemberModal,
      showEnterDataModal,
      fetchAvatarDBData,
      fetchCourseVideo,
      fetchEventClientLegs,
      setHomeData,
      showLegVideoModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
