import _ from "lodash";
import { postRequest } from "../../../components/Common/network-call";
import { BASE_URL, APP_API_KEY } from "../../../components/Common/constant";

export const POST_TEACHER_REQUEST = "POST_TEACHER_REQUEST";

var regFormSeries = {};

export function addTeacherInterest(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/registrationrequests`;
  var body = {
    resource: values,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log("response : - ", response);
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export const regFormAction = (resource = {}) => {
  console.log("resource: ", resource);
  regFormSeries = Object.assign(regFormSeries, resource);
  console.log("regFormSeries: ", regFormSeries);

  return (dispatch) => {};
};
