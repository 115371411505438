import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
// import Slider from "react-rangeslider";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import { useToasts } from "react-toast-notifications";
import DatePicker from "react-datepicker";
import moment from "moment";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import {
  showViewDataModal,
  fetchActivityList,
  fetchBonusPointsList,
  addMemberLiveData,
  addMemberLiveActivity,
  updateTeamData,
  fetchDataEntry,
  fetchMyDataList,
} from "../actions";
import { fetchHomeScreenData } from "../../home/actions";
import LocaleStrings from "../../../languages";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT_WITH_SECOND,
} from "../../../components/Common/constant";

import cross from "../../../assets/img/cross.png";
import info_white from "../../../assets/img/info-white.svg";
import minus from "../../../assets/img/minus-danger-rounded.svg";
import plus from "../../../assets/img/plus-activity-points.svg";
import glass_of_water from "../../../assets/img/glass-of-water.png";
import glass_of_water_slider_thumb from "../../../assets/img/glass-of-water-slider-thumb-with-bg.png";
import grapes from "../../../assets/img/fruits-and-vegetables-fruit-food-grapes.png";
import grapes_slider_thumb from "../../../assets/img/fruits-and-vegetables-fruit-food-grapes-slider-thumb.png";
import carrot from "../../../assets/img/fruits-and-vegetables-vegetable-food-carrot.png";
import carrot_slider_thumb from "../../../assets/img/fruits-and-vegetables-vegetable-food-carrot-slider-thumb.png";
import burger_cola from "../../../assets/img/fastfood-burger-cola.png";
import burger_cola_slider_thumb from "../../../assets/img/fastfood-burger-cola-slider-thumb.png";
import food_spoon_jar from "../../../assets/img/kids-baby-food-spoon-jar.png";
import food_spoon_jar_slider_thumb from "../../../assets/img/kids-baby-food-spoon-jar-slider-thumb.png";
import halloween from "../../../assets/img/halloween-moon-clouds-night.png";
import halloween_slider_thumb from "../../../assets/img/halloween-moon-clouds-night-slider-thumb.png";
import computers_phone from "../../../assets/img/computers-and-hardware-screen-pc-smart-phone.png";
import computers_phone_slider_thumb from "../../../assets/img/computers-and-hardware-screen-pc-smart-phone-slider-thumb.png";
import brain from "../../../assets/img/brain.png";
import brain_slider_thumb from "../../../assets/img/brain-slider-thumb.png";

const renderField = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  meta: { touched, error },
}) => (
  <div>
    <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
      {label}
    </label>
    <div className="mt-1">
      <input
        className="enter-data-steps-input cursor-not-allowed"
        {...input}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
      />
      {touched && error && (
        <span className="text-sm text-red-500">{error}</span>
      )}
    </div>
  </div>
);

const ViewData = (props) => {
  const {
    showModal,
    showViewDataModal,
    deviceSize,
    fetchActivityList,
    session,
    handleSubmit,
    pristine,
    submitting,
    invalid,
    fetchBonusPointsList,
    homeScreenData,
    addMemberLiveData,
    addMemberLiveActivity,
    reset,
    fetchHomeScreenData,
    updateTeamData,
    fetchDataEntry,
    fetchMyDataList,
  } = props;

  const { addToast } = useToasts();

  const [activityOpts, setActivityOpts] = useState([]);
  const [intensityOpts, setIntensityOpts] = useState([
    { value: 1100, name: "High" },
    { value: 1020, name: "Medium" },
    { value: 1001, name: "Low" },
  ]);
  const [durationOpts, setDurationOpts] = useState([
    { value: 10, name: "10 mins" },
    { value: 20, name: "20 mins" },
    { value: 30, name: "30 mins" },
    { value: 40, name: "40 mins" },
    { value: 50, name: "50 mins" },
    { value: 60, name: "60 mins" },
    { value: 70, name: "70 mins" },
    { value: 80, name: "80 mins" },
    { value: 90, name: "90 mins" },
  ]);

  const [water, setWater] = useState({});
  const [fruit, setFruit] = useState({});
  const [veges, setVeges] = useState({});
  const [foods, setFoods] = useState({});
  const [sugar, setSugar] = useState({});
  const [sleep, setSleep] = useState({});
  const [screentime, setScreentime] = useState({});
  const [mindfulness, setMindfulness] = useState({});

  const [loading, setLoading] = useState(false);

  const [sliderArr, setSliderArr] = useState([
    {
      key: "water",
      colorstopval: 0,
      colorstopsecondarycolor: "#7EE076",
      value: -1,
      unit: "Cups",
    },
    {
      key: "fruit",
      colorstopval: 0,
      colorstopsecondarycolor: "#FF8327",
      value: -1,
      unit: "",
    },
    {
      key: "veges",
      colorstopval: 0,
      colorstopsecondarycolor: "#FF4A4A",
      value: -1,
      unit: "",
    },
    {
      key: "foods",
      colorstopval: 0,
      colorstopsecondarycolor: "#7EE076",
      value: -1,
      unit: "",
    },
    {
      key: "sugar",
      colorstopval: 0,
      colorstopsecondarycolor: "#FD8326",
      value: -1,
      unit: "Tsp.",
    },
    {
      key: "sleep",
      colorstopval: 0,
      colorstopsecondarycolor: "#7EE076",
      value: 0,
      unit: "h",
    },
    {
      key: "screentime",
      colorstopval: 0,
      colorstopsecondarycolor: "#FD4A4A",
      value: 2,
      unit: "",
    },
    {
      key: "mindfulness",
      colorstopval: 0,
      colorstopsecondarycolor: "#FD8326",
      value: -1,
      unit: "",
    },
  ]);
  const [bonusPointsArr, setBonusPointsArr] = useState([]);

  useEffect(() => {
    // console.log("showModal useEffect: ", showModal);

    fetchActivityList(session, (res) => {
      if (res.success) {
        // console.log(res.data);
        let arr = [];
        _.map(res.data, (item) => {
          arr.push({ value: item.id, name: item.name });
        });

        setActivityOpts(arr);
      }
    });

    fetchBonusPointsList(session, (res) => {
      if (res.success) {
        // console.log(res.data);

        let uniqArr = _.uniqBy(res.data, "bonustype");
        // console.log("uniqArr: ", uniqArr);
        let uniqBonustypes = _.map(uniqArr, "bonustype");
        // console.log("uniqBonustypes: ", uniqBonustypes);
        // setBonusTypes(uniqBonustypes);

        let filter = [];
        let sorted = [];
        let length = 0;

        console.log("showModal fetchBonusPointsList: ", showModal);

        // --- for water ---
        let objWater = {};
        filter = _.filter(res.data, { bonustype: "water" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objWater.data = sorted;
        objWater.min = parseInt(showModal.data?.item?.waterstartrange);
        objWater.defaultvalue = parseInt(
          showModal.data?.item?.waterselectedvalue
        );
        objWater.max = parseInt(showModal.data?.item?.waterendrange);
        objWater.step = 1;
        objWater.value = parseInt(showModal.data?.item?.waterselectedvalue);
        // console.log("objWater: ", objWater);
        setWater(objWater);

        // --- for fruit ---
        let objFruit = {};
        filter = _.filter(res.data, { bonustype: "fruit" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objFruit.data = sorted;
        objFruit.min = parseInt(showModal.data?.item?.fruitstartrange);
        objFruit.defaultvalue = parseInt(
          showModal.data?.item?.fruitselectedvalue
        );
        objFruit.max = parseInt(showModal.data?.item?.fruitendrange);
        objFruit.step = 1;
        objFruit.value = parseInt(showModal.data?.item?.fruitselectedvalue);
        // console.log("objFruit: ", objFruit);
        setFruit(objFruit);

        // --- for veges ---
        let objVeges = {};
        filter = _.filter(res.data, { bonustype: "veges" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objVeges.data = sorted;
        objVeges.min = parseInt(showModal.data?.item?.vegesstartrange);
        objVeges.defaultvalue = parseInt(
          showModal.data?.item?.vegesselectedvalue
        );
        objVeges.max = parseInt(showModal.data?.item?.vegesendrange);
        objVeges.step = 1;
        objVeges.value = parseInt(showModal.data?.item?.vegesselectedvalue);
        // console.log("objVeges: ", objVeges);
        setVeges(objVeges);

        // --- for foods ---
        let objFoods = {};
        filter = _.filter(res.data, { bonustype: "foods" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objFoods.data = sorted;
        objFoods.min = parseInt(showModal.data?.item?.foodsstartrange);
        objFoods.defaultvalue = parseInt(
          showModal.data?.item?.foodsselectedvalue
        );
        objFoods.max = parseInt(showModal.data?.item?.foodsendrange);
        objFoods.step = 1;
        objFoods.value = parseInt(showModal.data?.item?.foodsselectedvalue);
        // console.log("objFoods: ", objFoods);
        setFoods(objFoods);

        // --- for sugar ---
        let objSugar = {};
        filter = _.filter(res.data, { bonustype: "sugar" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objSugar.data = sorted;
        objSugar.min = parseInt(showModal.data?.item?.sugarstartrange);
        objSugar.defaultvalue = parseInt(
          showModal.data?.item?.sugarselectedvalue
        );
        objSugar.max = parseInt(showModal.data?.item?.sugarendrange);
        objSugar.step = 1;
        objSugar.value = parseInt(showModal.data?.item?.sugarselectedvalue);
        // console.log("objSugar: ", objSugar);
        setSugar(objSugar);

        // --- for sleep ---
        let objSleep = {};
        filter = _.filter(res.data, { bonustype: "sleep" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["starttime"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objSleep.data = sorted;
        objSleep.min = parseInt(showModal.data?.item?.sleepstartrange);
        objSleep.defaultvalue = parseInt(
          showModal.data?.item?.sleepselectedvalue
        );
        objSleep.max = parseInt(showModal.data?.item?.sleependrange);
        objSleep.step = 1;
        objSleep.value = parseInt(showModal.data?.item?.sleepselectedvalue);
        // console.log("objSleep: ", objSleep);
        setSleep(objSleep);

        // --- for screentime ---
        let objScreentime = {};
        filter = _.filter(res.data, { bonustype: "screentime" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["starttime"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objScreentime.data = sorted;
        objScreentime.min = parseInt(
          showModal.data?.item?.screentimestartrange
        );
        objScreentime.defaultvalue = parseInt(
          showModal.data?.item?.screentimeselectedvalue
        );
        objScreentime.max = parseInt(showModal.data?.item?.screentimeendrange);
        objScreentime.step = 1;
        objScreentime.value = parseInt(
          showModal.data?.item?.screentimeselectedvalue
        );
        // console.log("objScreentime: ", objScreentime);
        setScreentime(objScreentime);

        // --- for mindfulness ---
        let objMindfulness = {};
        filter = _.filter(res.data, { bonustype: "mindfulness" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objMindfulness.data = sorted;
        objMindfulness.min = parseInt(
          showModal.data?.item?.mindfulnessstartrange
        );
        objMindfulness.defaultvalue = parseInt(
          showModal.data?.item?.mindfulnessselectedvalue
        );
        objMindfulness.max = parseInt(
          showModal.data?.item?.mindfulnessendrange
        );
        objMindfulness.step = 1;
        objMindfulness.value = parseInt(
          showModal.data?.item?.mindfulnessselectedvalue
        );
        // console.log("objMindfulness: ", objMindfulness);
        setMindfulness(objMindfulness);

        // setting the dynamic sliders
        let bonusArr = [];
        _.map(uniqBonustypes, (type) => {
          if (type == "water") {
            bonusArr.push({
              name: "water",
              img: glass_of_water,
              thumb: "glass-of-water-slider-thumb-with-bg.png",
              qty: "6 Cups",
              unit: "of Water",
              data: objWater,
            });
          } else if (type == "fruit") {
            bonusArr.push({
              name: "fruit",
              img: grapes,
              thumb: "fruits-and-vegetables-fruit-food-grapes-slider-thumb.png",
              qty: "6",
              unit: "Fruit",
              data: objFruit,
            });
          } else if (type == "veges") {
            bonusArr.push({
              name: "veges",
              img: carrot,
              thumb:
                "fruits-and-vegetables-vegetable-food-carrot-slider-thumb.png",
              qty: "1",
              unit: "Vegetables",
              data: objVeges,
            });
          } else if (type == "foods") {
            bonusArr.push({
              name: "foods",
              img: burger_cola,
              thumb: "fastfood-burger-cola.png",
              qty: "6",
              unit: "Snacks",
              data: objFoods,
            });
          } else if (type == "sugar") {
            bonusArr.push({
              name: "sugar",
              img: food_spoon_jar,
              thumb: "kids-baby-food-spoon-jar-slider-thumb.png",
              qty: "3 Tsp.",
              unit: "of Sugar",
              data: objSugar,
            });
          } else if (type == "sleep") {
            bonusArr.push({
              name: "sleep",
              img: halloween,
              thumb: "halloween-moon-clouds-night-slider-thumb.png",
              qty: "8h 53m",
              unit: "Sleep Time",
              data: objSleep,
            });
          } else if (type == "screentime") {
            bonusArr.push({
              name: "screentime",
              img: computers_phone,
              thumb:
                "computers-and-hardware-screen-pc-smart-phone-slider-thumb.png",
              qty: "2h 30m",
              unit: "Screen Time",
              data: objScreentime,
            });
          } else if (type == "mindfulness") {
            bonusArr.push({
              name: "mindfulness",
              img: brain,
              thumb: "brain-slider-thumb.png",
              qty: "1-10",
              unit: "Mindfulness",
              data: objMindfulness,
            });
          }
        });
        setBonusPointsArr(bonusArr);
      }
    });
  }, []);

  const closeModal = () => {
    showViewDataModal({ show: false, data: null });

    setSliderArr([
      {
        key: "water",
        colorstopval: 0,
        colorstopsecondarycolor: "#7EE076",
        value: -1,
        unit: "Cups",
      },
      {
        key: "fruit",
        colorstopval: 0,
        colorstopsecondarycolor: "#FF8327",
        value: -1,
        unit: "",
      },
      {
        key: "veges",
        colorstopval: 0,
        colorstopsecondarycolor: "#FF4A4A",
        value: -1,
        unit: "",
      },
      {
        key: "foods",
        colorstopval: 0,
        colorstopsecondarycolor: "#7EE076",
        value: -1,
        unit: "",
      },
      {
        key: "sugar",
        colorstopval: 0,
        colorstopsecondarycolor: "#FD8326",
        value: -1,
        unit: "Tsp.",
      },
      {
        key: "sleep",
        colorstopval: 0,
        colorstopsecondarycolor: "#7EE076",
        value: 0,
        unit: "h",
      },
      {
        key: "screentime",
        colorstopval: 0,
        colorstopsecondarycolor: "#FD4A4A",
        value: 2,
        unit: "",
      },
      {
        key: "mindfulness",
        colorstopval: 0,
        colorstopsecondarycolor: "#FD8326",
        value: -1,
        unit: "",
      },
    ]);

    setLoading(false);
    reset();
  };

  const renderSelect = (field) => {
    return (
      <div className={field.margin ? field.margin : ""}>
        {/* <label className={labelClasses}>
          {field.label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label> */}

        <div className="">
          <select
            className="enter-data-activity-select cursor-not-allowed"
            {...field.input}
            style={{ width: field.width }}
            disabled={field.disabled}
          >
            <option value="" key={field.label}>
              {field.placeholder}
            </option>
            {field.opts.map((object, index) => {
              return (
                <option key={`${object.value}-${index}`} value={object.value}>
                  {object.name}
                </option>
              );
            })}
          </select>
          <div className="text-sm text-red-500">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  };

  const renderActivity = ({
    fields,
    meta: { touched, error, submitFailed },
  }) => {
    return (
      <ul>
        {fields.map((activity, index) => (
          <li key={index} className={index == 0 ? "mt-4" : "mt-1"}>
            <label className="block text-sm text-secondary-4">
              Activity#{index + 1}
            </label>

            <div className="mt-1 flex items-center">
              <Field
                name={`${activity}.name`}
                label="Activity Name"
                placeholder="Activity"
                component={renderSelect}
                // mandatory={false}
                opts={activityOpts}
                width={142}
                disabled={true}
              />
              <Field
                name={`${activity}.intensity`}
                label="Activity Intensity"
                placeholder="Intensity"
                component={renderSelect}
                opts={intensityOpts}
                width={84}
                margin="ml-2"
                disabled={true}
              />
              <Field
                name={`${activity}.duration`}
                label="Activity Duration"
                placeholder="Duration"
                component={renderSelect}
                opts={durationOpts}
                width={81}
                margin="ml-2"
                disabled={true}
              />

              {/* {index ? (
                <img
                  className='ml-2 cursor-pointer'
                  src={minus}
                  title='Remove Activity'
                  onClick={() => fields.remove(index)}
                />
              ) : (
                ""
              )} */}
            </div>
          </li>
        ))}

        {/* <li className='w-full flex justify-end mt-4'>
          <div className='pr-8'>
            <div
              className='bg-primary rounded-md p-2 cursor-pointer'
              onClick={() => fields.push({})}
            >
              <img
                // ref={addNewActivityRef}
                src={plus}
              />
            </div>
          </div>
        </li> */}
        {submitFailed && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </ul>
    );
  };

  const renderDatePickerWithLabel = (field) => {
    // console.log("field :- ", field);
    var {
      input,
      selected,
      disabled,
      label,
      labelposition,
      meta,
      maxDate,
      minDate,
      placeholder,
    } = field;
    // var value = input.value ? moment(input.value) : null;
    // var selectedValue = value ? value : selected;

    var value = input.value
      ? moment(input.value).format(DATE_FORMAT)
      : selected
      ? moment(selected).format(DATE_FORMAT)
      : null;
    var selectedValue = value
      ? moment(value)
      : selected
      ? selected
      : moment(moment().format(DATE_FORMAT));

    // var selectedValue = value
    //   ? parse(value, DATE_FORMAT, new Date())
    //   : selected
    //   ? selected
    //   : new Date();

    // var value = input.value ? moment(input.value).format(DATE_FORMAT) : null;
    // var selectedValue = value ? moment(input.value) : selected;
    // console.log('value :- ', value);
    // console.log('selected :- ', selected);
    // console.log('selectedValue :- ', selectedValue);

    // var divClassName = `form-group row ${
    //   meta.touched && meta.error ? "has-danger" : ""
    // }`;
    // var labelClasses = "control-label-top col-sm-12";
    // var inputClasses = "col-sm-12 col-sm-offset-0";

    // if (labelposition === LABEL_POSITION_LEFT) {
    //   labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    //   inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
    // }

    return (
      <div>
        {label !== "" ? (
          <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
            {label}
            {/* <span className='label-mandatory'>
              {field.mandatory === "true" ? "*" : ""}
            </span> */}
          </label>
        ) : null}

        <div className="">
          <DatePicker
            className={
              disabled
                ? "cursor-not-allowed enter-data-steps-input"
                : "enter-data-steps-input"
            }
            name={input.name}
            placeholderText={placeholder}
            {...input}
            value={value}
            // selected={selectedValue}
            dateFormat={DATE_FORMAT}
            // dateFormat='yyyy-MM-dd'
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
          />
          <div className="text-sm text-red-500">
            {meta.touched ? meta.error : ""}
          </div>
        </div>
      </div>
    );
  };

  const handleChangeSlider = (e, item) => {
    // console.log("e: ", e);
    let value = e.target.value;
    // console.log("value: ", value);
    // console.log("value: ", item.data.min);
    // console.log("value: ", item.data.max);

    let arr = JSON.parse(JSON.stringify(sliderArr));
    // console.log("arr: ", arr);
    let index = _.findIndex(arr, function (o) {
      return o.key == item.name;
    });
    // console.log("index: ", index);
    let color_stop_val =
      (value - item.data.min) / (item.data.max - item.data.min);
    // console.log("arr[index]: ", arr[index]);
    arr[index].colorstopval = color_stop_val;
    arr[index].value = value;

    setSliderArr(arr);
  };

  const onSubmitForm = (values, dispatch) => {
    values.date = moment(values.date).format(DATE_FORMAT);
    console.log(values);

    // if (
    //   !_.isEmpty(values) &&
    //   values.steps &&
    //   values.date &&
    //   values.activity &&
    //   values.activity.length > 0 &&
    //   values.activity[0].name &&
    //   values.activity[0].intensity &&
    //   values.activity[0].duration
    // ) {
    //   setLoading(true);

    //   let activityArr = [];
    //   if (values.activity && values.activity.length > 0) {
    //     _.map(values.activity, (item) => {
    //       if (!_.isEmpty(item)) {
    //         activityArr.push({
    //           activityidfk: parseInt(item.name),
    //           intensity:
    //             parseInt(item.intensity) == 1100
    //               ? "high"
    //               : parseInt(item.intensity) == 1020
    //               ? "medium"
    //               : parseInt(item.intensity) == 1001
    //               ? "low"
    //               : "",
    //           activitytime: parseInt(item.duration),
    //           // activitypoint: Math.round(
    //           //   (parseInt(item.intensity) / 60) * parseInt(item.duration)
    //           // ),
    //           activitypoint: parseFloat(
    //             (
    //               (parseInt(item.intensity) / 60) *
    //               parseInt(item.duration)
    //             ).toFixed(6)
    //           ),
    //           entrydate: values.date,
    //         });
    //       }
    //     });
    //   }
    //   // console.log("activityArr: ", activityArr);

    //   let filterValue = _.filter(sliderArr, function (o) {
    //     return o.key == "water";
    //   });
    //   let filter = _.filter(water.data, function (item) {
    //     return filterValue[0].value == item.quantity;
    //   });
    //   let waterPoint = filter[0].points;

    //   filterValue = _.filter(sliderArr, function (o) {
    //     return o.key == "fruit";
    //   });
    //   filter = _.filter(fruit.data, function (item) {
    //     return filterValue[0].value == item.quantity;
    //   });
    //   let fruitPoint = filter[0].points;

    //   filterValue = _.filter(sliderArr, function (o) {
    //     return o.key == "veges";
    //   });
    //   filter = _.filter(veges.data, function (item) {
    //     return filterValue[0].value == item.quantity;
    //   });
    //   let vegesPoint = filter[0].points;

    //   filterValue = _.filter(sliderArr, function (o) {
    //     return o.key == "foods";
    //   });
    //   filter = _.filter(foods.data, function (item) {
    //     return filterValue[0].value == item.quantity;
    //   });
    //   let foodsPoint = filter[0].points;

    //   filterValue = _.filter(sliderArr, function (o) {
    //     return o.key == "sugar";
    //   });
    //   filter = _.filter(sugar.data, function (item) {
    //     return filterValue[0].value == item.quantity;
    //   });
    //   let sugarPoint = filter[0].points;

    //   filterValue = _.filter(sliderArr, function (o) {
    //     return o.key == "sleep";
    //   });
    //   filter = _.filter(sleep.data, function (item) {
    //     return (
    //       filterValue[0].value >= item.starttime &&
    //       filterValue[0].value <= item.endtime
    //     );
    //   });
    //   let sleepPoint = filter[0].points;

    //   filterValue = _.filter(sliderArr, function (o) {
    //     return o.key == "screentime";
    //   });
    //   filter = _.filter(screentime.data, function (item) {
    //     return (
    //       filterValue[0].value >= item.starttime &&
    //       filterValue[0].value <= item.endtime
    //     );
    //   });
    //   let screentimePoint = filter[0].points;

    //   filterValue = _.filter(sliderArr, function (o) {
    //     return o.key == "mindfulness";
    //   });
    //   filter = _.filter(mindfulness.data, function (item) {
    //     return filterValue[0].value == item.quantity;
    //   });
    //   let mindfulnessPoint = filter[0].points;

    //   let resourceLiveData = [
    //     {
    //       memberidfk: session.userid,
    //       teamidfk: parseInt(homeScreenData.data.teamid),
    //       steps: parseInt(values.steps),
    //       sleep: sleepPoint,
    //       screentime: screentimePoint,
    //       water: waterPoint,
    //       fruit: fruitPoint,
    //       veges: vegesPoint,
    //       foods: foodsPoint,
    //       sugar: sugarPoint,
    //       mindfulness: mindfulnessPoint,
    //       entrydate: values.date,
    //       enteredby: "self",
    //     },
    //   ];
    //   // console.log("resourceLiveData: ", resourceLiveData);

    //   let teamid = parseInt(homeScreenData.data.teamid);
    //   let teampoints = parseInt(homeScreenData.data.teampoints);
    //   let teamsteps = parseInt(homeScreenData.data.teamsteps);
    //   let totalactivitypoints = 0;
    //   _.map(activityArr, (item) => {
    //     totalactivitypoints += item.activitypoint;
    //   });
    //   let totalbonuspoints =
    //     sleepPoint +
    //     screentimePoint +
    //     waterPoint +
    //     fruitPoint +
    //     vegesPoint +
    //     foodsPoint +
    //     sugarPoint +
    //     mindfulnessPoint;

    //   // console.log("totalactivitypoints: ", totalactivitypoints);
    //   // console.log("totalbonuspoints: ", totalbonuspoints);
    //   // console.log("teampoints: ", teampoints);
    //   // console.log("teamsteps: ", teamsteps);

    //   if (showModal.data?.type == "mydata") {
    //     fetchDataEntry(
    //       session,
    //       homeScreenData.data.teamid,
    //       "mydata",
    //       values.date,
    //       (resDataEntry) => {
    //         console.log("resDataEntry: ", resDataEntry);

    //         if (resDataEntry.success) {
    //           if (resDataEntry.data.length > 0) {
    //             let content =
    //               "Data is already entered on this day for this user";
    //             addToast(content, {
    //               appearance: "error",
    //               autoDismiss: true,
    //             });
    //             setLoading(false);
    //             closeModal();
    //           }
    //           //
    //           else {
    //             addMemberLiveData(session, resourceLiveData, (res) => {
    //               console.log("res: ", res);

    //               if (res.success) {
    //                 let resourceLiveActivity = JSON.parse(
    //                   JSON.stringify(activityArr)
    //                 );
    //                 _.map(resourceLiveActivity, (item) => {
    //                   item.memberlivedataidfk = res.response[0].id;
    //                 });
    //                 // console.log("resourceLiveActivity: ", resourceLiveActivity);
    //                 addMemberLiveActivity(
    //                   session,
    //                   resourceLiveActivity,
    //                   (res1) => {
    //                     console.log("res1: ", res1);
    //                     if (res1.success) {
    //                       let teampoints_mydata =
    //                         teampoints +
    //                         parseInt(values.steps) +
    //                         totalactivitypoints +
    //                         totalbonuspoints;
    //                       let teamsteps_mydata =
    //                         teamsteps + parseInt(values.steps);
    //                       let teamResourceObj = {
    //                         teampoints: teampoints_mydata,
    //                         teamsteps: teamsteps_mydata,
    //                       };

    //                       updateTeamData(
    //                         session,
    //                         teamid,
    //                         teamResourceObj,
    //                         (res2) => {
    //                           console.log("res2: ", res2);
    //                           if (res2.success) {
    //                             fetchMyDataList(session, teamid, (res) => {});
    //                             fetchHomeScreenData(session, (res) => {});
    //                             setLoading(false);

    //                             let content = "Data added successfully.";
    //                             addToast(content, {
    //                               appearance: "success",
    //                               autoDismiss: true,
    //                             });

    //                             closeModal();
    //                           } else {
    //                             let content = "Something went wrong.";
    //                             addToast(content, {
    //                               appearance: "error",
    //                               autoDismiss: true,
    //                             });

    //                             closeModal();
    //                           }
    //                         }
    //                       );
    //                     } else {
    //                       let content = "Something went wrong.";
    //                       addToast(content, {
    //                         appearance: "error",
    //                         autoDismiss: true,
    //                       });

    //                       closeModal();
    //                     }
    //                   }
    //                 );
    //               }
    //               //
    //               else {
    //                 let content = "Something went wrong.";
    //                 addToast(content, {
    //                   appearance: "error",
    //                   autoDismiss: true,
    //                 });
    //               }
    //             });
    //           }
    //         }
    //         //
    //         else {
    //           let content = "Something went wrong.";
    //           addToast(content, {
    //             appearance: "error",
    //             autoDismiss: true,
    //           });
    //         }
    //       }
    //     );
    //   }
    //   //
    //   else {
    //     fetchDataEntry(
    //       session,
    //       homeScreenData.data.teamid,
    //       "classdata",
    //       values.date,
    //       (resDataEntry) => {
    //         console.log("resDataEntry: ", resDataEntry);

    //         if (resDataEntry.success) {
    //           if (
    //             resDataEntry.data.length ==
    //             homeScreenData.data.allteammemberdata.length
    //           ) {
    //             let content =
    //               "Data is already entered on this day for all team members";
    //             addToast(content, {
    //               appearance: "error",
    //               autoDismiss: true,
    //             });

    //             setLoading(false);
    //             closeModal();
    //           }
    //           //
    //           else {
    //             let teammembersforteamdataentry = [];

    //             if (resDataEntry.data.length == 0) {
    //               teammembersforteamdataentry = JSON.parse(
    //                 JSON.stringify(homeScreenData.data.allteammemberdata)
    //               );
    //             } else {
    //               _.map(homeScreenData.data.allteammemberdata, (item) => {
    //                 // let filter = _.filter(resDataEntry.data, {
    //                 //   memberidfk: parseInt(item.id),
    //                 // });
    //                 let filter = _.filter(resDataEntry.data, function (o) {
    //                   return o.memberidfk == item.id;
    //                 });
    //                 if (filter.length == 0) {
    //                   teammembersforteamdataentry.push(item);
    //                 }
    //               });
    //             }
    //             console.log(
    //               "teammembersforteamdataentry: ",
    //               teammembersforteamdataentry
    //             );

    //             let resourceLiveDataArr = [];
    //             _.map(teammembersforteamdataentry, (item) => {
    //               resourceLiveDataArr.push({
    //                 memberidfk: parseInt(item.id),
    //                 teamidfk: parseInt(homeScreenData.data.teamid),
    //                 steps: parseInt(values.steps),
    //                 sleep: sleepPoint,
    //                 screentime: screentimePoint,
    //                 water: waterPoint,
    //                 fruit: fruitPoint,
    //                 veges: vegesPoint,
    //                 foods: foodsPoint,
    //                 sugar: sugarPoint,
    //                 mindfulness: mindfulnessPoint,
    //                 entrydate: values.date,
    //                 enteredby: "manager",
    //               });
    //             });
    //             // console.log("resourceLiveDataArr: ", resourceLiveDataArr);

    //             let arrPromise = [];
    //             let counter = resourceLiveDataArr.length;
    //             let uploadCounter = 0;

    //             _.map(resourceLiveDataArr, (item) => {
    //               arrPromise.push(
    //                 new Promise((resolve, reject) => {
    //                   let resourceLiveDataTeam = [{ ...item }];
    //                   addMemberLiveData(
    //                     session,
    //                     resourceLiveDataTeam,
    //                     (res) => {
    //                       console.log("res: ", res);

    //                       if (res.success) {
    //                         let resourceLiveActivityTeam = JSON.parse(
    //                           JSON.stringify(activityArr)
    //                         );
    //                         _.map(resourceLiveActivityTeam, (item) => {
    //                           item.memberlivedataidfk = res.response[0].id;
    //                         });
    //                         // console.log("resourceLiveActivityTeam: ", resourceLiveActivityTeam);

    //                         addMemberLiveActivity(
    //                           session,
    //                           resourceLiveActivityTeam,
    //                           (res1) => {
    //                             console.log("res1: ", res1);
    //                             if (res1.success) {
    //                               uploadCounter++;
    //                               resolve();
    //                             } else {
    //                               resolve();
    //                             }
    //                           }
    //                         );
    //                       }
    //                       //
    //                       else {
    //                         resolve();
    //                       }
    //                     }
    //                   );
    //                 })
    //               );
    //             });

    //             Promise.all(arrPromise).then(() => {
    //               if (counter == uploadCounter) {
    //                 let teamsteps_classdata =
    //                   teamsteps +
    //                   parseInt(values.steps) * resourceLiveDataArr.length;
    //                 let teampoints_classdata =
    //                   teampoints +
    //                   parseFloat(
    //                     (
    //                       (parseInt(values.steps) +
    //                         totalactivitypoints +
    //                         totalbonuspoints) *
    //                       resourceLiveDataArr.length
    //                     ).toFixed(6)
    //                   );
    //                 let teamResourceObj = {
    //                   teampoints: teampoints_classdata,
    //                   teamsteps: teamsteps_classdata,
    //                 };

    //                 updateTeamData(session, teamid, teamResourceObj, (res2) => {
    //                   console.log("res2: ", res2);
    //                   if (res2.success) {
    //                     fetchHomeScreenData(session, (res) => {});
    //                     setLoading(false);

    //                     let content = "Data added successfully.";
    //                     addToast(content, {
    //                       appearance: "success",
    //                       autoDismiss: true,
    //                     });

    //                     closeModal();
    //                   } else {
    //                     let content = "Something went wrong.";
    //                     addToast(content, {
    //                       appearance: "error",
    //                       autoDismiss: true,
    //                     });
    //                     closeModal();
    //                   }
    //                 });
    //               }
    //               //
    //               else {
    //                 let content = "Something went wrong.";
    //                 addToast(content, {
    //                   appearance: "error",
    //                   autoDismiss: true,
    //                 });
    //                 closeModal();
    //               }
    //             });
    //           }
    //         }
    //         //
    //         else {
    //           let content = "Something went wrong.";
    //           addToast(content, {
    //             appearance: "error",
    //             autoDismiss: true,
    //           });
    //         }
    //       }
    //     );
    //   }
    // }
    // //
    // else {
    //   let content = "Please provide required fields.";
    //   addToast(content, {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    // }
  };

  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <div className="flex justify-start">
                    <h3 className="text-base font-medium">
                      {moment(showModal.data?.item?.date).format(
                        "DD MMM YYYY"
                      ) === moment().format("DD MMM YYYY")
                        ? `Today, ${showModal.data?.name}`
                        : `${moment(showModal.data?.item?.date).format(
                            "DD MMM YYYY"
                          )}, ${showModal.data?.name}`}
                    </h3>
                  </div>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="enter-data-modal-body relative flex-auto">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2">
                      <div className="sm:border-0 md:border-r">
                        <div className="points-entry-container">
                          <div>{LocaleStrings.activity_points_entry}</div>
                          <div>
                            {/* <img src={info_white} /> */}
                            <Popup
                              trigger={
                                <img
                                  className="cursor-pointer"
                                  src={info_white}
                                />
                              }
                              position="left center"
                              on="hover"
                            >
                              <div>{LocaleStrings.lorem_ipsum}</div>
                            </Popup>
                          </div>
                        </div>

                        <div className="p-4">
                          <Field
                            name="date"
                            label="Date"
                            placeholder="Enter Date"
                            component={renderDatePickerWithLabel}
                            disabled={true}
                          />

                          <Field
                            name="steps"
                            type="number"
                            component={renderField}
                            label="Steps"
                            placeholder="Enter Steps"
                            disabled={true}
                          />

                          <FieldArray
                            name="activity"
                            component={renderActivity}
                          />
                        </div>
                      </div>

                      <div>
                        <div className="points-entry-container">
                          <div>{LocaleStrings.bonus_points_entry}</div>
                          <div>
                            <Popup
                              trigger={
                                <img
                                  className="cursor-pointer"
                                  src={info_white}
                                />
                              }
                              position="left center"
                              on="hover"
                            >
                              <div>{LocaleStrings.lorem_ipsum}</div>
                            </Popup>
                          </div>
                        </div>

                        {_.map(bonusPointsArr, (item, i) => {
                          // console.log("item.name: ", item.name);
                          // console.log("item.data: ", item.data);
                          // console.log(
                          //   "item.data.defaultvalue: ",
                          //   item.data.defaultvalue
                          // );

                          let filter = _.filter(sliderArr, function (o) {
                            return o.key == item.name;
                          });
                          // console.log("filter: ", filter);

                          return (
                            <div className="p-4 border-b flex items-center">
                              <div className="" style={{ width: 21 }}>
                                <img src={item.img} />
                              </div>
                              <div className="ml-4">
                                <div>
                                  <input
                                    className={"slider-" + item.name}
                                    type="range"
                                    min={item.data.min}
                                    max={item.data.max}
                                    step={item.data.step}
                                    // defaultValue={item.data.defaultvalue}
                                    value={item.data.value}
                                    // onChange={(e) =>
                                    //   handleChangeSlider(e, item)
                                    // }
                                    style={
                                      filter.length > 0
                                        ? {
                                            backgroundImage: `-webkit-gradient(linear, left top, right top, color-stop(${
                                              (item.data.value -
                                                item.data.min) /
                                              (item.data.max - item.data.min)
                                            }, ${
                                              filter[0].colorstopsecondarycolor
                                            }), color-stop(${
                                              (item.data.value -
                                                item.data.min) /
                                              (item.data.max - item.data.min)
                                            }, #e6e8f2))`,
                                          }
                                        : {}
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="ml-4 flex justify-end"
                                style={{ width: 52 }}
                              >
                                <div>
                                  <div className="text-right text-sm font-medium">
                                    {filter.length > 0 ? (
                                      <div className="text-right text-sm font-medium">
                                        {filter[0].key == "screentime"
                                          ? Math.floor(item.data.value / 60) +
                                            "h " +
                                            (item.data.value % 60) +
                                            "m"
                                          : item.data.value == -1
                                          ? `0 ${filter[0].unit}`
                                          : `${item.data.value} ${filter[0].unit}`}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="text-right"
                                    style={{ fontSize: 8 }}
                                  >
                                    {item.unit}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {/* <div className='mt-5 p-4'>
                          <button
                            type='submit'
                            className='w-full bg-shades-of-green flex items-center justify-center text-sm text-white font-medium rounded-lg'
                            style={{ height: 37 }}
                          >
                            {loading ? (
                              <svg
                                className='animate-spin h-5 w-5 rounded-full border-b border-white mr-3'
                                viewBox='0 0 24 24'
                              ></svg>
                            ) : (
                              ""
                            )}
                            {LocaleStrings.submit_data}
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </form>

                {/*footer*/}
                {/* <div className='flex items-center justify-center sm:justify-end p-4 rounded-b border-t'>
                  <button
                    className='bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer'
                    style={{
                      height: 37,
                      width: deviceSize.width >= 414 ? 367 : "100%",
                    }}
                  >
                    <span className='text-base'>Confirm Course</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const validate = (values) => {
  var errors = {};

  //   if (!values.date || values.date == "") {
  //     errors.date = "Required";
  //   }

  //   if (!values.steps) {
  //     errors.steps = "Required";
  //   }

  //   if (!values.activity || !values.activity.length) {
  //     errors.activity = { _error: "At least one activity must be entered" };
  //     // errors.activity = "At least one activity must be entered";
  //   } else {
  //     const activityArrayErrors = [];
  //     // console.log("values.activity: ", values.activity);

  //     values.activity.forEach((act, actIndex) => {
  //       const activityErrors = {};
  //       // console.log("act: ", act);

  //       if (!act || !act.name) {
  //         activityErrors.name = "Required";
  //         activityArrayErrors[actIndex] = activityErrors;
  //       }
  //       if (!act || !act.intensity) {
  //         activityErrors.intensity = "Required";
  //         activityArrayErrors[actIndex] = activityErrors;
  //       }
  //       if (!act || !act.duration) {
  //         activityErrors.duration = "Required";
  //         activityArrayErrors[actIndex] = activityErrors;
  //       }
  //       return activityErrors;
  //     });
  //     if (activityArrayErrors.length) {
  //       errors.activity = activityArrayErrors;
  //     }
  //   }

  // console.log("errors: ", errors);
  return errors;
};

const mapStateToProps = (state) => {
  // console.log("activityListReducer: ", state.activityListReducer);

  // console.log(
  //   "state.showViewDataModalReducer.data: ",
  //   state.showViewDataModalReducer.data
  // );

  let activityArr = [];
  _.map(
    state.showViewDataModalReducer.data?.item?.memberliveactivity,
    (item) => {
      activityArr.push({
        name: parseInt(item.activityidfk),
        intensity:
          item.intensity == "high"
            ? 1100
            : item.intensity == "medium"
            ? 1020
            : item.intensity == "low"
            ? 1001
            : "",
        duration: parseInt(item.activitytime),
      });
    }
  );

  var initial = {
    date: moment(state.showViewDataModalReducer.data?.item?.date).format(
      DATE_FORMAT
    ),
    steps: state.showViewDataModalReducer.data?.item?.steps,
    activity: JSON.parse(JSON.stringify(activityArr)),
  };

  return {
    deviceSize: state.deviceSize,
    showModal: state.showViewDataModalReducer,
    session: state.session,
    activityList: state.activityListReducer,
    bonusPointsList: state.bonusPointsListReducer,
    homeScreenData: state.homeScreenDataReducer,
    initialValues: initial,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showViewDataModal,
      fetchActivityList,
      fetchBonusPointsList,
      addMemberLiveData,
      addMemberLiveActivity,
      fetchHomeScreenData,
      updateTeamData,
      fetchDataEntry,
      fetchMyDataList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ViewDataForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ViewData)
);
