import { combineReducers } from "redux";
import _ from "lodash";
import { reducer as formReducer } from "redux-form";

import { loadState, reset } from "./localStorage";
import { APP_API_KEY } from "../components/Common/constant";

import { DEVICE_SIZE } from "../actions";

import {
  LOGIN,
  LOGOUT,
  SESSION_TOKEN,
  EVENT,
  EVENT_DETAILS,
  LOGIN_USER_DETAILS,
} from "../actions";

import {
  homeScreenDataReducer,
  showMemberModalReducer,
  homeData,
  showLegVideo,
} from "../pages/home/reducers";

import {
  showPhotoViewModalReducer,
  courseVideoReducer,
} from "../pages/coursevideo/reducers";

import {
  showCourseDetailsModalReducer,
  showEditCourseModalReducer,
  courseDetailsReducer,
  showEditCourseReducer,
  eventClientReducer,
} from "../pages/coursemap/reducers";

import {
  showEnterDataModalReducer,
  activityListReducer,
  bonusPointsListReducer,
  enterdDataListReducer,
  myDataListReducer,
  showViewDataModalReducer,
  myChartDataReducer,
  eventClientLegsListReducer,
  classListData,
} from "../pages/mydata/reducers";

import { teamDetailsListReducer } from "../pages/viewallteam/reducers";

import {
  AvatarReducer,
  FetchAvatarReducer,
} from "../pages/avatarBuilder/reducers";

import { faqListReducer } from "../pages/support/reducers";
import {
  teamMembers,
  activeTeamMembers,
  teamDetails,
  teamClientEntrantCount,
  teamMemberAddModal,
  teamAddModal,
} from "../pages/manageteam/reducers";

var deviceSize = (state = {}, action) => {
  if (action.type === DEVICE_SIZE) {
    return action.payload;
  }
  return state;
};

var LoginReducer = (state = true, action) => {
  // need to change it to false
  state = loadState();

  if (state === undefined) {
    state = {
      isLoggedIn: false,
    };
  }

  if (action.type === LOGIN) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  return state.isLoggedIn;
};

// stores Data for session also on localstorage
var TokensReducer = (state = {}, action) => {
  state = loadState();

  if (state === undefined || state.session === undefined) {
    state = {
      session: {
        apiKey: APP_API_KEY,
        sessionToken: "",
        emailid: "",
        userid: "",
        usertype: "",
      },
    };
  }

  if (action.type === SESSION_TOKEN) {
    if (action.payload.session_token) {
      state.session.sessionToken = action.payload.session_token;
    }
    state.session.emailid = action.payload.email;
    state.session.userid = action.payload.id;
    state.session.usertype = action.payload.membertype;
    state.session.username = action.payload.username;
    state.session.ismanager = action.payload.ismanager;
    state.session.teamid = action.payload.teamidfk;
    state.session.eventclientid = action.payload.eventclientidfk;
  }

  if (action.type === EVENT) {
    state.session.eventid = action.payload.eventid
      ? action.payload.eventid
      : "";
    state.session.eventname = action.payload.name ? action.payload.name : "";
    state.session.eventtype = action.payload.type ? action.payload.type : "";
    state.session.subdomain = action.payload.subdomain
      ? action.payload.subdomain
      : "";
  }

  if (action.type === LOGOUT) {
    state.session.sessionToken = "";
    state.session.emailid = "";
    state.session.userid = "";
    state.session.usertype = "";
    state.session.ismanager = "";
    state.session.teamid = "";
    state.session.eventclientid = "";
    reset();
  }

  return state.session;
};

var eventDetails = (state = {}, action) => {
  if (action.type === EVENT_DETAILS) {
    return action.payload;
  }
  return state;
};

var loginUserDetails = (state = {}, action) => {
  if (action.type === LOGIN_USER_DETAILS) {
    return action.payload;
  }
  return state;
};

const rootReducer = combineReducers({
  form: formReducer,

  // isLoggedIn: true,
  deviceSize: deviceSize,

  // Common
  eventDetails,
  loginUserDetails,

  // Login
  isLoggedIn: LoginReducer,
  session: TokensReducer,

  // Home
  homeScreenDataReducer,
  showMemberModalReducer,
  homeData,
  showLegVideo,

  // Course Video
  showPhotoViewModalReducer,
  courseVideoReducer,

  // Course Details
  showCourseDetailsModalReducer,
  showEditCourseModalReducer,
  courseDetailsReducer,
  showEditCourseReducer,
  eventClientReducer,

  // Data Entry
  showEnterDataModalReducer,
  activityListReducer,
  bonusPointsListReducer,
  enterdDataListReducer,
  myDataListReducer,
  showViewDataModalReducer,
  classListData,

  // My Data
  myChartDataReducer,
  eventClientLegsListReducer,

  // View all team
  teamDetailsListReducer,

  // Avatar Opt Data
  AvatarReducer,
  FetchAvatarReducer,

  // FAQ
  faqListReducer,

  // Team Members
  teamMembers,
  activeTeamMembers,
  teamDetails,
  teamClientEntrantCount,
  teamMemberAddModal,
  teamAddModal,
});

export default rootReducer;
