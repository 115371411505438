/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showMemberModal } from "../actions";
import LocaleStrings from "../../../languages";

// image imports
import cross from "../../../assets/img/cross.png";
import bigAvatar from "../../../assets/img/Group-2.png";
import MemberAvatar from "../../avatarBuilder/MemberAvatar";

const Member = (props) => {
  const { showModal, showMemberModal } = props;

  const closeModal = () => {
    showMemberModal({ show: false, data: null });
  };

  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <h3 className="text-base font-medium">
                    {`${showModal.data.firstname} ${
                      showModal.data.lastname
                        ? showModal.data.lastname.charAt(0)
                        : ""
                    }`}
                  </h3>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="relative flex-auto">
                  <div className="flex justify-center items-center mt-4">
                    {/* <img src={bigAvatar} /> */}
                    <MemberAvatar />
                  </div>

                  <div
                    className="rounded-lg m-4 bg-shades_of_blue_1"
                    style={{ width: 300 }}
                  >
                    <div className="flex justify-between p-2">
                      <div className="">
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.name}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {/* {showModal.data.firstname} */}
                          {showModal.data.firstname.split(" ").length > 1
                            ? showModal.data.lastname
                              ? showModal.data.firstname.split(" ")[0] +
                                " " +
                                showModal.data.lastname.charAt(0)
                              : showModal.data.firstname.split(" ")[0]
                            : showModal.data.lastname
                            ? showModal.data.firstname +
                              " " +
                              showModal.data.lastname.charAt(0)
                            : showModal.data.firstname}
                        </div>
                      </div>
                      <div>
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.daily_avg}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {Math.floor(showModal.data.dailyavg ?? 0)}
                        </div>
                      </div>
                      <div>
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.total}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {Math.floor(showModal.data.totalpoints ?? 0)}
                        </div>
                      </div>
                    </div>

                    {/* <div className="px-2 h-6" id="progress-bar">
                      <div className="relative pt-1">
                        <div className="progress-bar-container h-2 mb-4">
                          <div
                            style={{ width: `${showModal.data.progress}%` }}
                            className="progress-bar bg-progressbarprogress1"
                          ></div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-4 rounded-b border-t">
                  <button
                    className="bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer p-2"
                    onClick={() => closeModal()}
                  >
                    <span className="text-sm">Close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    showModal: state.showMemberModalReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMemberModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Member);
