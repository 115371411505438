/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showLegVideoModal } from "../actions";
import LocaleStrings from "../../../languages";
import {
  BASE_FILES_URL,
  FILE_ACCESS_KEY,
  BASE_AZIONV2_URL,
} from "../../../components/Common/constant";
// image imports
import cross from "../../../assets/img/cross.png";

const LegVideo = (props) => {
  const { showLegVideo, showLegVideoModal, makeAllData, homeScreenData } =
    props;

  const closeModal = () => {
    showLegVideoModal({ show: false });
    setTimeout(() => makeAllData(homeScreenData.data, 3), 1000);
    setTimeout(() => makeAllData(homeScreenData.data, 1), 3000);
  };

  return (
    <>
      {showLegVideo && showLegVideo.show ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <h3 className="text-base font-medium"></h3>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="relative flex-auto">
                  <div className="flex justify-center items-center mt-4"></div>

                  <div className="rounded-lg m-4" style={{ width: 400 }}>
                    <div className="flex items-center justify-center p-2">
                      <video controls>
                        <source
                          src={
                            `${BASE_AZIONV2_URL}video/video_4567.mp4` +
                            "?api_key=" +
                            FILE_ACCESS_KEY
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-4 rounded-b border-t">
                  <button
                    className="bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer p-2"
                    onClick={() => closeModal()}
                  >
                    <span className="text-sm">Close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    showLegVideo: state.showLegVideo,
    homeScreenData: state.homeScreenDataReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showLegVideoModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LegVideo);
