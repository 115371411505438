import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
// import Slider from "react-slick";
import Carousel, {
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import LocaleStrings from "../../languages";
import Loader from "../../components/Common/loader";
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../components/Common/constant";
import markerFlag from "../../assets/img/flag.png";
import trackFlag from "../../assets/img/track.png";
import CourseDetails from "./components/course-details";
import EditCourse from "./components/edit-course";

import {
  showCourseDetailsModal,
  showEditCourseModal,
  fetchCourseDetails,
  showEditCourse,
  fetchEventClient,
} from "./actions";

import backArrow from "../../assets/img/backarrow.png";
//import bigMap from "../../assets/img/Big Map.png";

import bigMap from "../../assets/img/map-1000.png";
import edit from "../../assets/img/edit.png";
import leftArrowWhite from "../../assets/img/leftarrowwhite.png";
import rightArrowWhite from "../../assets/img/rightarrowwhite.png";
import australia from "../../assets/img/australia.png";
import newzeland from "../../assets/img/newzeland.png";
import peru from "../../assets/img/peru.png";
import egypt from "../../assets/img/egypt.png";
import china from "../../assets/img/china.png";
import canada from "../../assets/img/canada.png";
import peru_large from "../../assets/img/peru_large.png";
import _ from "lodash";
import { Tooltip } from "reactstrap";

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "red" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const CourseMap = (props) => {
  const {
    deviceSize,
    showCourseDetailsModal,
    showEditCourseModal,
    session,
    fetchCourseDetails,
    courseDetailsReducer,
    showCourseDetailsModalReducer,
    showEditCourse,
    showEditCourseReducer,
    showEditCourseModalReducer,
    fetchEventClient,
  } = props;

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [teamPosArr, setTeamPosArr] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(-1);

  const toggle = (index) => {
    if (index != tooltipOpen) setTooltipOpen(index);
    else setTooltipOpen(-1);
  };

  useEffect(() => {
    fetchCourseDetails(session, (res) => {
      //console.log("res: ", res);
      setLoading(false);
      if (res?.data?.eventclientlegs) {
        let teamPosArr = [];
        _.forEach(res.data.eventclientlegs, (item) => {
          //console.log("item: ", item);
          let obj = {};

          let teamArr = [];
          if (
            item.team_by_currentlegidfk &&
            item.team_by_currentlegidfk.length > 0
          ) {
            _.forEach(item.team_by_currentlegidfk, (team) => {
              if (_.includes(res.data.trackteam, team.id)) {
                teamArr.push(team.teamname);
              } else if (session.teamid == team.id) {
                teamArr.push(team.teamname);
              }
            });
          }

          obj.team = teamArr;

          let locationtitle = item.eventleg_by_eventlegidfk.locationtitle;
          let position =
            item.masterleg_by_legidfk && item.masterleg_by_legidfk.position
              ? JSON.parse(item.masterleg_by_legidfk.position)
              : "";
          obj.locationtitle = locationtitle;
          obj.position = position;
          obj.id = item.id;
          teamPosArr.push(obj);
        });
        setTeamPosArr(teamPosArr);
      }
    });

    showEditCourse(session, (res) => {
      // console.log("res: ", res);
    });

    fetchEventClient(session);
  }, []);

  const handleBack = () => {
    const path = "/";
    history.push(path);
  };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  // };

  const handleCourseDetails = (item) => {
    //console.log("item: ", item);
    showCourseDetailsModal({ show: true, item: item });

    // let img = "";
    // if (img == "") {
    //   showCourseDetailsModal({ show: true, img: peru_large });
    // } else {
    //   showCourseDetailsModal({ show: true, img: img });
    // }
  };

  const handleEditCourse = () => {
    showEditCourseModal({ show: true });
  };

  //console.log("teamPosArr: ", teamPosArr);

  return (
    <>
      {!loading ? (
        <div className="p-0 sm:p-2">
          <div
            className="bg-secondary p-2 sm:p-4"
            style={
              deviceSize.width > 640
                ? { borderRadius: 13 }
                : { borderRadius: 0 }
            }
          >
            <div>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <div
                    className="bg-primary p-3 rounded cursor-pointer"
                    onClick={() => handleBack()}
                  >
                    <img src={backArrow} />
                  </div>
                  <div className="text-base ml-3">
                    {LocaleStrings.course_map}
                  </div>
                </div>
                <div>
                  {showEditCourseReducer?.data?.edit ? (
                    <button
                      className="btn-edit-course"
                      style={{ height: 40, width: 153 }}
                      onClick={() => handleEditCourse()}
                    >
                      <img src={edit} className="" />
                      <span className="ml-2 text-base">
                        {LocaleStrings.edit_course}
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="mt-3 bg-white rounded-xl">
                {deviceSize.width > 1000 ? (
                  <div className="px-0 py-4 border-b flex items-center justify-center relative">
                    <img src={bigMap} className="object-cover" />

                    {_.map(teamPosArr, (item, idx) => {
                      return (
                        <>
                          <img
                            id="marker"
                            className=""
                            className={classnames(
                              item.team.length > 0 ? "z-10 " : "",
                              "absolute cursor-pointer"
                            )}
                            style={{
                              top: item.position.y,
                              left: item.position.x,
                            }}
                            src={markerFlag}
                            id={"tooltip-" + item.id}
                          />

                          <Tooltip
                            placement="right"
                            isOpen={
                              tooltipOpen === item.id && item.team.length > 0
                                ? true
                                : false
                            }
                            target={"tooltip-" + item.id}
                            toggle={() => toggle(item.id)}
                          >
                            <div className="rounded p-2 bg-white border border-gray-500 flex items-center justify-center">
                              {item.team.join(",")}
                            </div>
                          </Tooltip>

                          {/* {flag == item.id && item.team.length > 0 ? (
                            <div
                              className="z-50 absolute rounded p-2 bg-white border border-gray-500 flex items-center justify-center"
                              style={{
                                top: item.position.y,
                                left: item.position.x,
                              }}
                            >
                              {item.team.join(",")}
                            </div>
                          ) : (
                            ""
                          )} */}
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className="px-0 py-4 border-b coursemap-wrapper">
                    <div
                      className="relative"
                      style={{
                        height: 624,
                        backgroundImage: `url("${bigMap}")`,
                        width: 1000,
                      }}
                    >
                      {_.map(teamPosArr, (item, idx) => {
                        return (
                          <>
                            <img
                              id="marker"
                              className="absolute transition-all duration-1000 ease-in-out"
                              style={{
                                top: item.position.y,
                                left: item.position.x,
                              }}
                              src={markerFlag}
                            />

                            {item.team.length > 0 ? (
                              <div
                                className="z-50 absolute rounded p-2 bg-white border border-gray-500 flex items-center justify-center"
                                style={{
                                  top: item.position.y,
                                  left: item.position.x,
                                }}
                              >
                                {item.team.join(",")}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div
                  className="px-5 py-4 course-map-carousel-container"
                  style={{ maxWidth: 1180 }}
                >
                  <Carousel
                    plugins={[
                      // "infinite",
                      "arrows",
                      {
                        resolve: slidesToShowPlugin,
                        options: {
                          numberOfSlides:
                            deviceSize.width >= 1400
                              ? 6
                              : deviceSize.width < 1400 &&
                                deviceSize.width >= 1280
                              ? 5
                              : deviceSize.width < 1280 &&
                                deviceSize.width >= 1140
                              ? 6
                              : deviceSize.width < 1140 &&
                                deviceSize.width >= 980
                              ? 5
                              : deviceSize.width < 980 &&
                                deviceSize.width >= 840
                              ? 4
                              : deviceSize.width < 840 &&
                                deviceSize.width >= 640
                              ? 3
                              : deviceSize.width < 640 &&
                                deviceSize.width >= 450
                              ? 2
                              : 1,
                        },
                      },
                      {
                        resolve: arrowsPlugin,
                        options: {
                          arrowLeft: (
                            <div className="carousel-arrow-container-course-map">
                              <img src={leftArrowWhite} />
                            </div>
                          ),
                          arrowLeftDisabled: (
                            <div className="carousel-arrow-container-course-map">
                              <img src={leftArrowWhite} />
                            </div>
                          ),
                          arrowRight: (
                            <div className="carousel-arrow-container-course-map">
                              <img src={rightArrowWhite} />
                            </div>
                          ),
                          arrowRightDisabled: (
                            <div className="carousel-arrow-container-course-map">
                              <img src={rightArrowWhite} />
                            </div>
                          ),
                          addArrowClickHandler: true,
                        },
                      },
                    ]}
                  >
                    {_.map(
                      courseDetailsReducer.data?.eventclientlegs,
                      (item) => {
                        let src = `${BASE_AZIONV2_URL}${item.leggallerymain?.masterleggallery_by_leggalleryidfk?.path}?api_key=${FILE_ACCESS_KEY}`;

                        return item?.leggallerymain ? (
                          <div
                            className="relative cursor-pointer"
                            onClick={() => handleCourseDetails(item)}
                          >
                            <img
                              className="rounded"
                              src={src}
                              style={{ height: 80, width: 140 }}
                            />
                            <div
                              className="course-name-inside-carousel"
                              style={{ bottom: 6 }}
                            >
                              {/* Canada */}
                              {item.masterleg_by_legidfk?.name.capitalize()}
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      }
                    )}
                  </Carousel>
                </div>
              </div>
            </div>

            {showCourseDetailsModalReducer.show ? <CourseDetails /> : ""}
            {showEditCourseModalReducer.show ? <EditCourse /> : ""}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  console.log("state.courseDetailsReducer: ", state.courseDetailsReducer);
  // console.log("state.showEditCourseReducer: ", state.showEditCourseReducer);

  return {
    deviceSize: state.deviceSize,
    session: state.session,
    courseDetailsReducer: state.courseDetailsReducer,
    showCourseDetailsModalReducer: state.showCourseDetailsModalReducer,
    showEditCourseReducer: state.showEditCourseReducer,
    showEditCourseModalReducer: state.showEditCourseModalReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showCourseDetailsModal,
      showEditCourseModal,
      fetchCourseDetails,
      showEditCourse,
      fetchEventClient,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CourseMap);
