import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { useToasts } from "react-toast-notifications";
import {
  fetchTeamMembers,
  fetchActiveTeamMembers,
  fetchTeamData,
  fetchTeamClientEntrantCount,
  handleAddTeamMemberModal,
  handleAddTeamModal,
  updateTeam,
  withdrawAllTeamMember,
  deleteTeamMemberSession,
} from "./actions";
import { logout } from "../../actions";
import {
  CUSTOM_ITEM_COUNT,
  LOCAL_TO_GMT_TIME,
  COMMON_FAIL_MESSAGE,
} from "../../components/Common/constant";
import Pagination from "../../components/Common/pagination";
import ItemList from "./components/item-list";
import AddTeamMember from "./components/teammember-add";
import AddTeam from "./components/team-add";
import Loader from "../../components/Common/loader";
import LocaleStrings from "../../languages";
import editicon from "../../assets/img/edit.png";

const ManageTeam = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const {
    session,
    deviceSize,
    teamMembers,
    teamDetails,
    teamClientEntrantCount,
    teamMemberAddModal,
    teamAddModal,
    activeTeamMembers,
  } = props;
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    props.fetchTeamMembers(session);
    props.fetchActiveTeamMembers(session);
    props.fetchTeamClientEntrantCount(session, (res) => {
      props.fetchTeamData(session);
    });
  }, []);

  const openAddTeam = () => {
    props.handleAddTeamMemberModal({ showModal: true });
  };

  const openTeamName = () => {
    props.handleAddTeamModal({ showModal: true, value: teamDetails?.data });
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
  };

  const additionTeamCallback = () => {
    props.fetchTeamData(session);
  };

  const paginationCallback = (pageNum) => {
    props.fetchTeamMembers(session, pageNum, (response) => {});

    setPageNumber(pageNum);
  };

  const handleWithdraw = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = {
      id: teamDetails?.data?.id,
      withdrawndate: LOCAL_TO_GMT_TIME,
    };

    var dfids = _.map(activeTeamMembers?.data, "dfid");

    confirmAlert({
      title: LocaleStrings.team_withdraw_confirm_title,
      message: `${LocaleStrings.team_withdraw_confirm_subtitle} ${teamDetails?.data?.teamname}?`,
      buttons: [
        {
          label: LocaleStrings.button_cancel,
          onClick: () => {},
        },
        {
          label: LocaleStrings.button_withdraw,
          onClick: () => {
            props.updateTeam(session, values, (response) => {
              if (response.success === 0) {
                addToast(COMMON_FAIL_MESSAGE, {
                  appearance: "error",
                  autoDismiss: true,
                });
              } else {
                props.withdrawAllTeamMember(
                  session,
                  teamDetails?.data?.id,
                  LOCAL_TO_GMT_TIME,
                  (res) => {
                    addToast(LocaleStrings.team_withdraw_alert_success, {
                      appearance: "success",
                      autoDismiss: true,
                    });
                    if (dfids?.length > 0) {
                      props.deleteTeamMemberSession(session, dfids.join(","));
                    }
                    props.logout(session);
                    history.push("/login");
                  }
                );
              }
            });
          },
        },
      ],
      childrenElement: () => (
        <div className="mt-5 mb-5 font-bold">
          {LocaleStrings.team_withdraw_confirm_warning}
        </div>
      ),
    });
  };

  const _renderList = () => {
    let pageDataLength = teamMembers?.data ? teamMembers?.data?.length : 0;

    return _.map(teamMembers?.data, (item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          printList={item}
          currentPage={pageNumber}
          pageDataLength={pageDataLength}
          pagination={paginationCallback}
        />
      );
    });
  };

  return (
    <div className="p-0 sm:p-2">
      <div
        className="bg-secondary p-2 sm:p-4"
        style={
          deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
        }
      >
        <div className="rounded-lg">
          <div className="px-5 py-4 rounded-t-lg bg-white">
            <h3 className="text-base font-medium">
              {LocaleStrings.teammember_text_manage_class}
            </h3>
          </div>

          <div className="flex items-center justify-between bg-secondary-2">
            <div className="px-5 py-2 text-left">
              {teamDetails?.data?.teamname}
            </div>

            <div className="px-5 py-2 text-right flex items-center h-16">
              {teamDetails?.data ? (
                <>
                  {/* Edit Team */}
                  <button
                    className="btn-primary-md mr-2 min-height2_5"
                    onClick={openTeamName}
                  >
                    <img src={editicon} alt="Edit" />
                  </button>
                  {/* Withdraw Team */}
                  <button
                    className="btn-primary-md mr-2"
                    onClick={handleWithdraw}
                  >
                    <span className="">{LocaleStrings.button_withdraw}</span>
                  </button>
                  {/* Add Entrant */}
                  {teamDetails?.data?.eventclient_by_eventclientidfk
                    ?.maxteammember >
                    teamClientEntrantCount?.data?.teamentrantcount &&
                  teamDetails?.data?.eventclient_by_eventclientidfk
                    ?.totalmembers >
                    teamClientEntrantCount?.data?.cliententrantcount ? (
                    <button className="btn-primary-md" onClick={openAddTeam}>
                      <span className="">
                        {LocaleStrings.button_add_entrant}
                      </span>
                    </button>
                  ) : (
                    <button className="btn-primary-disabled-md">
                      <span className="">
                        {LocaleStrings.teammember_text_team_size_full}
                      </span>
                    </button>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {teamMembers?.data ? (
            <div className="bg-white rounded-b-none sm:rounded-b-lg">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg view-all-team-table-container">
                      <table className="bg-white min-w-full divide-y divide-gray-200 overflow-y-auto">
                        <thead>
                          <tr>
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_fname}
                            </th>
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_lname}
                            </th>
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_uname}
                            </th>
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_password}
                            </th>
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_action}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {_renderList()}
                        </tbody>
                      </table>

                      {teamMembers?.count > CUSTOM_ITEM_COUNT ? (
                        <Pagination
                          activePage={pageNumber}
                          itemsCountPerPage={CUSTOM_ITEM_COUNT}
                          totalItemsCount={teamMembers.count}
                          pageRangeDisplayed={3}
                          callbackCall={paginationCallback}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-screen">
              <Loader />
            </div>
          )}
        </div>
      </div>

      {teamMemberAddModal?.showModal ? (
        <AddTeamMember finishOperationsCallback={additionCallback} />
      ) : (
        ""
      )}
      {teamAddModal?.showModal ? (
        <AddTeam finishOperationsCallback={additionTeamCallback} />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    teamMembers: state.teamMembers,
    teamDetails: state.teamDetails,
    teamClientEntrantCount: state.teamClientEntrantCount,
    teamMemberAddModal: state.teamMemberAddModal,
    teamAddModal: state.teamAddModal,
    activeTeamMembers: state.activeTeamMembers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTeamMembers,
      fetchActiveTeamMembers,
      fetchTeamData,
      fetchTeamClientEntrantCount,
      handleAddTeamMemberModal,
      handleAddTeamModal,
      updateTeam,
      withdrawAllTeamMember,
      deleteTeamMemberSession,
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageTeam);
