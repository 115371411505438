/* eslint-disable no-unused-vars */
import React from "react";
import { Bar } from "react-chartjs-2";

const FoodDrink = (props) => {
  const { componentObject } = props;

  const chartData = componentObject.chartDataObject;

  var padding;
  componentObject.isMobile ? (padding = 3) : (padding = 15);

  const totalPoints = componentObject.totalPoints; // upon raw data, convert to string JSON.stringify()

  // custom chart-tooltip function starts
  const externalTooltipHandler = (context) => {
    if (!context) {
      //console.log("exiting");
      return;
    }

    // destructure
    const { chart, tooltip } = context;

    // tooltip element
    var tooltipEl = chart?.canvas?.parentNode.querySelector("div"); // create
    if (!tooltipEl) {
      tooltipEl = document.createElement("div"); // create tooltip
      tooltipEl.classList.add("tooltipMainDiv", "rounded-t-md");
    }

    // hide the tooltip when mouseout
    if (
      (tooltip?.body[0]?.lines[0] == "Water: 0" &&
        tooltip?.body[1]?.lines[0] == "Fruits: 0" &&
        tooltip?.body[2]?.lines[0] == "Vegetables: 0" &&
        tooltip?.body[3]?.lines[0] == "Snacks: 0" &&
        tooltip?.body[4]?.lines[0] == "Sugar: 0") ||
      tooltip?.opacity === 0
    ) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // maindiv sub ul-li
    let tooltipUL = document.createElement("ul");
    let tooltipLI = document.createElement("li");

    // set custom tooltip, inner html
    if (tooltip.body) {
      var titleLines = tooltip.title || [];
      // var bodyLines = tooltip.body.map((ele) => ele.lines) || [];
    }

    // set inner html to tooltip
    // title
    let titleDiv = document.createElement("div"); // title div
    titleDiv.classList.add("titleDivClass", "rounded-md");
    let titleHeading = document.createElement("h4"); // text as heading
    titleHeading.classList.add("titleHeadingClass");
    let daySpan, dateSpan;
    titleLines.forEach((title) => {
      daySpan = document.createElement("span"); // span for day
      dateSpan = document.createElement("span"); // span for date

      var tooltipTitleDay = document.createTextNode(title); // day node
      var tooltipTitleDate = document.createTextNode(""); // date node
      // var tooltipTitleDate = document.createTextNode(", 12th January"); // date node

      daySpan.appendChild(tooltipTitleDay);
      dateSpan.appendChild(tooltipTitleDate);
    });
    titleHeading.appendChild(daySpan); // day to heading
    titleHeading.appendChild(dateSpan); // date to heading
    titleDiv.appendChild(titleHeading); // heading top title div

    // body
    let bodyDiv = document.createElement("div"); // body
    bodyDiv.classList.add(
      "bodyDivClass",
      "rounded-b-md",
      "p-4",
      "text-sm",
      "font-medium",
      "border-r",
      "border-b"
    );
    let table = document.createElement("table"); // table to lad the data
    // table.classList.add() // // add styles to table
    // let tableRow = document.createElement("tr"); // individual table row
    // tableRow.classList.add() // add styles to rows

    let tableRow,
      tableRowDataColor,
      tableRowDataLabel,
      tableRowDataStatic,
      tableRowDataValue,
      tableRowDataDynamic;
    tooltip.dataPoints.forEach((e, i) => {
      // create row everytime
      tableRow = document.createElement("tr"); // individual table row
      // tableRow.classList.add() // add styles to rows

      // color
      tableRowDataColor = document.createElement("td"); // cell for color
      // tableRowDataColor.classList.add() // color styles
      let color = e?.dataset?.backgroundColor; // color data var
      let colorCircle = document.createElement("span"); // color loading span
      colorCircle.classList.add("colorCircle"); // add styles
      colorCircle.style.backgroundColor = color;
      tableRowDataColor.appendChild(colorCircle);
      colorCircle.style.pointStyle = e?.dataset?.pointStyle;

      // label
      tableRowDataLabel = document.createElement("td"); // cell to load label "water"
      tableRowDataLabel.classList.add("td-lable-style", "pl-2");
      let labelNode = document.createTextNode(e?.dataset?.label);
      tableRowDataLabel.appendChild(labelNode);

      // static :
      tableRowDataStatic = document.createElement("td"); // cell to load static ":"
      let staticNode = document.createTextNode(":");
      tableRowDataStatic.appendChild(staticNode);

      // value
      tableRowDataValue = document.createElement("td"); // cell to load value
      tableRowDataValue.classList.add("pl-2");
      let value = document.createTextNode(e?.raw); // if use formattedValue, append directly
      tableRowDataValue.appendChild(value);

      // units
      tableRowDataDynamic = document.createElement("td");
      tableRowDataDynamic.classList.add("pl-1");
      let unit;
      switch (e?.dataset?.label) {
        case "Water":
          unit = document.createTextNode("glasses");
          break;
        case "Fruits":
          unit = document.createTextNode("pieces");
          break;
        case "Vegetables":
          unit = document.createTextNode("portions");
          break;
        case "Snacks":
          unit = document.createTextNode("portions");
          break;
        case "Sugar":
          unit = document.createTextNode("teaspoons");
          break;

        default:
          break;
      }
      tableRowDataDynamic.appendChild(unit);

      // appends to each row
      tableRow.appendChild(tableRowDataColor);
      tableRow.appendChild(tableRowDataLabel);
      tableRow.appendChild(tableRowDataStatic);
      tableRow.appendChild(tableRowDataValue);
      tableRow.appendChild(tableRowDataDynamic);

      table.appendChild(tableRow); // each row to table
    });

    // footer total point
    let bodyFooter = document.createElement("footer"); // footer div
    bodyFooter.classList.add("p-3", "mt-3", "sm:p-2", "font-solid", "bold");
    // static text
    let footerStatic = document.createElement("span"); // span to load static text
    let textNode = document.createTextNode("Total Points: "); // static text
    footerStatic.appendChild(textNode); // text to textSpan
    // value
    let footerValue = document.createElement("span"); // span to load value
    let totalPointNode = document.createTextNode(totalPoints);
    footerValue.appendChild(totalPointNode);
    // append to footer
    bodyFooter.appendChild(footerStatic);
    bodyFooter.appendChild(footerValue);

    bodyDiv.appendChild(table); // table to bodyDiv
    // bodyDiv.appendChild(bodyFooter); // footer to bodyDiv

    tooltipLI.appendChild(titleDiv); // title div to tooltip element
    tooltipLI.appendChild(bodyDiv); // body div to tooltip element

    tooltipUL.appendChild(tooltipLI);
    tooltipEl.appendChild(tooltipUL);

    // remove old children
    var ULnode = tooltipEl.querySelector("ul");
    while (ULnode.firstChild) {
      ULnode.firstChild.remove();
    }
    // add new children
    ULnode.appendChild(tooltipLI);
    tooltipLI.appendChild(titleDiv); // redo title div to tooltip element
    tooltipLI.appendChild(bodyDiv); // redo body div to tooltip element
    tooltipEl.style.opacity = 1; // resetting opacity

    // render the tooltip
    chart?.canvas?.parentNode.appendChild(tooltipEl); // tooltip appended to chart

    // calculate position
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;
    if (!componentObject.isMobile) {
      tooltipEl.style.left = positionX + tooltip.caretX + 95 + "px";
      tooltipEl.style.top = positionY + tooltip.caretY - 200 + "px";
    } else {
      tooltipEl.style.left = positionX + tooltip.caretX - 15 + "px";
      tooltipEl.style.top = positionY + tooltip.caretY - 250 + "px";
    }
  };

  return (
    <>
      {componentObject.showComponent === "foodDrink" ? (
        <Bar
          className="rounded-b-lg"
          data={chartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: {
                grid: {
                  display: false,
                },
                stacked: true,
                ticks: {
                  display: true,
                  padding: 15,
                },
              },
              yAxes: {
                grid: {
                  display: false,
                },
                stacked: true,
                // max: 30,
                ticks: {
                  display: true,
                  stepSize: 10,
                  padding: padding,
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                external: externalTooltipHandler,
              },
            },
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default FoodDrink;
