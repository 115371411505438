/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  restoreAvatarData,
  updateAvatarData,
  fetchAvatarDBData,
  saveAvatarOptData,
} from "./actions";
import cross from "../../assets/img/cross.png";
import Options from "./optionPannel";
import DrawAvatar from "./drawAvatar";
import checkmark from "./../../assets/img/icons8-checkmark-100.png";

const AvatarBuilder = (props) => {
  const {
    showAvatarModal,
    setShowAvatarModal,
    AvatarReducer,
    FetchAvatarReducer,
    session,
    saveAvatarOptData,
    restoreAvatarData,
    updateAvatarData,
    fetchAvatarDBData,
  } = props;

  useEffect(() => {
    restoreAvatarData(FetchAvatarReducer);
  }, []);

  const [navOpt, setNavOpt] = useState("top");
  const [optSkinColor, setOptSkinColor] = useState(
    FetchAvatarReducer?.skincolor
  );
  const [optHairColor, setOptHairColor] = useState(
    FetchAvatarReducer?.haircolor
  );
  const [optBeardColor, setOptBeardColor] = useState(
    FetchAvatarReducer?.beardcolor
  );
  const [optEyebrowColor, setOptEyebrowColor] = useState(
    FetchAvatarReducer?.eyebrowcolor
  );

  const closeModal = () => {
    fetchAvatarDBData(session, session.userid, (res) => {});
    setShowAvatarModal(false);
  };

  const handleColorChange = (color) => {
    switch (navOpt) {
      case "hair":
        setOptHairColor(color);
        break;
      case "head":
        setOptSkinColor(color);
        break;
      case "top":
        setOptSkinColor(color);
        break;
      case "beard":
        setOptBeardColor(color);
        break;
      case "eye_brow":
        setOptEyebrowColor(color);
        break;

      default:
        break;
    }
  };

  const saveAvatarData = () => {
    var avRed = AvatarReducer;

    avRed.skincolor = optSkinColor;
    avRed.haircolor = optHairColor;
    avRed.beardcolor = optBeardColor;
    avRed.eyebrowcolor = optEyebrowColor;

    var avatar = JSON.stringify(avRed);

    let resource = {
      avatar: avatar,
    };
    updateAvatarData(session, session.userid, resource, (callback) => {});
    closeModal();
  };

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={showAvatarModal} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="modal relative w-avatar-body-width mx-auto max-w-avatar-body-width">
                {/*content*/}
                <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-2 border-b  rounded-t w-avatar-header-width h-avatar-header-height">
                    <p className="p-2 font-bold">Avatar Builder</p>
                    <button
                      className="p-3 ml-auto bg-transparent border-0 text-black opacity-100 float-right"
                      onClick={closeModal}
                    >
                      <img src={cross} className="cursor-pointer" />
                    </button>
                  </div>

                  {/*body*/}
                  <div
                    className={
                      "relative flex-auto flex-grow-1  w-avatar-body-width "
                    }
                  >
                    <div className="flex bg-white border-b border-solid border-blueGray-200  ">
                      {/* loads the drawn avatar */}
                      <div
                        className={
                          "w-avatar-content-left p-2 sm:p-4  border-secondary-400 border-r"
                        }
                      >
                        <DrawAvatar
                          optSkinColor={optSkinColor}
                          optHairColor={optHairColor}
                          optBeardColor={optBeardColor}
                          optEyebrowColor={optEyebrowColor}
                        />

                        {/* hair-color & skin tones goes here */}
                        {/* skin */}
                        {navOpt == "top" || navOpt == "head" ? (
                          <div
                            className="flex shadow-xl"
                            style={{
                              height: 40,
                              width: 160,
                              marginTop: 475,
                              marginLeft: 35,
                            }}
                          >
                            <div
                              className={
                                optSkinColor == "s1" ? "border-primary" : ""
                              }
                              style={{
                                backgroundColor: "#CC9966",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("s1")}
                            >
                              {optSkinColor == "s1" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#996614",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("s2")}
                            >
                              {optSkinColor == "s2" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#FEE4BE",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("s3")}
                            >
                              {optSkinColor == "s3" ? (
                                <span className="shadow-md">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#FD9966",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("s4")}
                            >
                              {optSkinColor == "s4" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* hair */}
                        {navOpt == "hair" ? (
                          <div
                            className="flex border"
                            style={{ height: 40, width: 230, marginTop: 475 }}
                          >
                            <div
                              className=""
                              style={{
                                backgroundColor: "#D5D5D5",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h1")}
                            >
                              {optHairColor == "h1" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#B53A3A",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h2")}
                            >
                              {optHairColor == "h2" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#E6BA29",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h3")}
                            >
                              {optHairColor == "h3" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#D08F37",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h4")}
                            >
                              {optHairColor == "h4" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#663308",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h5")}
                            >
                              {optHairColor == "h5" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#000000",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h6")}
                            >
                              {optHairColor == "h6" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* beard */}
                        {navOpt == "beard" ? (
                          <div
                            className="flex border"
                            style={{ height: 40, width: 230, marginTop: 475 }}
                          >
                            <div
                              className=""
                              style={{
                                backgroundColor: "#D5D5D5",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h1")}
                            >
                              {optBeardColor == "h1" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#B53A3A",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h2")}
                            >
                              {optBeardColor == "h2" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#E6BA29",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h3")}
                            >
                              {optBeardColor == "h3" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#D08F37",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h4")}
                            >
                              {optBeardColor == "h4" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#663308",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h5")}
                            >
                              {optBeardColor == "h5" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#000000",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h6")}
                            >
                              {optBeardColor == "h6" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* eye brow */}
                        {navOpt == "eye_brow" ? (
                          <div
                            className="flex border"
                            style={{ height: 40, width: 230, marginTop: 475 }}
                          >
                            <div
                              className=""
                              style={{
                                backgroundColor: "#D5D5D5",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h1")}
                            >
                              {optEyebrowColor == "h1" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#B53A3A",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h2")}
                            >
                              {optEyebrowColor == "h2" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#E6BA29",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h3")}
                            >
                              {optEyebrowColor == "h3" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#D08F37",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h4")}
                            >
                              {optEyebrowColor == "h4" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#663308",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h5")}
                            >
                              {optEyebrowColor == "h5" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className=""
                              style={{
                                backgroundColor: "#000000",
                                height: 40,
                                width: 40,
                              }}
                              onClick={() => handleColorChange("h6")}
                            >
                              {optEyebrowColor == "h6" ? (
                                <span className="">
                                  <img src={checkmark} className="p-3" alt="" />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* render the options for the avatar */}
                      <Options
                        navOpt={navOpt}
                        optSkinColor={optSkinColor}
                        optHairColor={optHairColor}
                        optBeardColor={optBeardColor}
                        optEyebrowColor={optEyebrowColor}
                      />
                    </div>

                    {/* option nav bar */}
                    <nav className="flex flex-nowrap whitespace-nowrap gap-x-1 h-avatar-opt-height w-avatar-opt-width bg-white overflow-x-auto">
                      <div
                        className={
                          navOpt === "top"
                            ? "is-active border-t-2 border-primary ml-1 p-3 font-bold cursor-pointer"
                            : "ml-1 p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("top")}
                      >
                        Top
                      </div>
                      <div
                        className={
                          navOpt === "bottom"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("bottom")}
                      >
                        Bottoms
                      </div>
                      <div
                        className={
                          navOpt === "shoes"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("shoes")}
                      >
                        Shoes
                      </div>
                      <div
                        className={
                          navOpt === "head"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("head")}
                      >
                        Heads
                      </div>
                      <div
                        className={
                          navOpt === "hair"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("hair")}
                      >
                        Hair
                      </div>
                      <div
                        className={
                          navOpt === "eyes"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("eyes")}
                      >
                        Eyes
                      </div>
                      <div
                        className={
                          navOpt === "eye_brow"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("eye_brow")}
                      >
                        Eye Brows
                      </div>
                      <div
                        className={
                          navOpt === "nose"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("nose")}
                      >
                        Noses
                      </div>
                      <div
                        className={
                          navOpt === "mouth"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("mouth")}
                      >
                        Mouths
                      </div>
                      <div
                        className={
                          navOpt === "beard"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("beard")}
                      >
                        Beard
                      </div>
                      <div
                        className={
                          navOpt === "glasses"
                            ? "is-active border-t-2 border-primary p-3 font-bold cursor-pointer"
                            : "p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                        }
                        onClick={() => setNavOpt("glasses")}
                      >
                        Glasses
                      </div>
                    </nav>

                    {/*footer*/}
                    <div className="flex items-center bg-white justify-end p-2 border-t border-solid border-blueGray-200 rounded-b h-avatar-footer-height">
                      <button
                        className="text-secondary font-bold background-transparent px-6 py-2 text-sm mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-refresh text-white rounded px-3 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={saveAvatarData}
                      >
                        Save Avatar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    AvatarReducer: state.AvatarReducer,
    session: state.session,
    FetchAvatarReducer: state.FetchAvatarReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      restoreAvatarData,
      updateAvatarData,
      fetchAvatarDBData,
      saveAvatarOptData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AvatarBuilder);
