/* eslint-disable no-unused-vars */
import _ from "lodash";
import axios from "axios";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  postMultipartRequest,
  postCustomRequest,
  uploadEncodedFile,
  uploadEncodedFileLarge,
} from "../../../components/Common/network-call";
import {
  BASE_URL,
  APP_API_KEY,
  LOCAL_TIMEZONE,
} from "../../../components/Common/constant";

export const FETCH_HOME_SCREEN_DATA = "FETCH_HOME_SCREEN_DATA";
export const SHOW_MEMBER_MODAL = "SHOW_MEMBER_MODAL";
export const SET_HOME_DATA = "SET_HOME_DATA";
export const SHOW_LEG_VIDEO_MODAL = "SHOW_LEG_VIDEO_MODAL";

export function fetchHomeScreenData(session, callback) {
  // console.log("session: ", session);
  var url = `${BASE_URL}api/v2/custom_script?type=getHomeScreenData&eventid=${session.eventid}&userid=${session.userid}&usertype=${session.usertype}&timezone=${LOCAL_TIMEZONE}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("fetchHomeScreenData res: ", response);

        dispatch({ type: FETCH_HOME_SCREEN_DATA, payload: { data: response } });
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: [] });
      }
    );
  };
}

export function showMemberModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_MEMBER_MODAL, payload: obj });
  };
}

export function setHomeData(obj = {}) {
  return (dispatch) => {
    dispatch({ type: SET_HOME_DATA, payload: obj });
  };
}

export function showLegVideoModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_LEG_VIDEO_MODAL, payload: obj });
  };
}
