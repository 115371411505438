import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { Field, FieldArray, reduxForm, reset } from "redux-form";

const RegFormClassTwo = (props) => {
  const {
    deviceSize,
    session,
    eventDetails,
    handleSubmit,
    pristine,
    reset,
    submitting,
  } = props;

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center mb-3">
              <h2 className="text-5xl pb-3 pt-3 mb-3">
                Teachers Register Your Class
              </h2>
              <span className="">Please enter our students details below</span>
              <br />
              <span className="">
                NOTE: For privacy purposes, please enter their first name and
                last initial, gender and click Add. When you have entered your
                whole class, please click Next
              </span>
            </div>
            <div
              className="bg-secondary-2 py-2 rounded-lg"
              style={deviceSize.width > 768 ? { height: 500 } : { height: 900 }}
            >
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 justify-items-center items-end px-6 md:px-12">
                  <div className="col-span-1">
                    <div className="">
                      <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                        First Name
                      </label>
                      <input
                        name="firstname"
                        type="text"
                        placeholder="J"
                        className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="">
                      <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                        Last Initial
                      </label>
                      <input
                        name="lastinitial"
                        type="text"
                        placeholder="J"
                        className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="">
                      <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                        Gender
                      </label>
                      <select
                        name="gender"
                        className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 text-gray-700 rounded-md"
                      >
                        <option>-- select --</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <button
                      type="submit"
                      className="mt-2 btn bg-refresh text-white rounded-lg h-9"
                      style={{ width: 100 }}
                    >
                      Add
                    </button>
                  </div>
                </div>

                {/* --- boy girl display table starts --- */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-1 px-6 md:px-12 mt-4 text-gray-700 font-bold">
                  <div className="col-span-1 border-b-4 border-secondary-2">
                    Boys
                  </div>

                  <div className="col-span-1 border-b-4 border-secondary-2">
                    Girls
                  </div>
                </div>
                {/* ---  boy girl display table ends  --- */}

                {/* form submit */}
                <div className="flex justify-center m-6">
                  <button
                    type="submit"
                    disabled={pristine || submitting}
                    className="mt-10 btn bg-refresh text-white rounded-lg h-9"
                    style={{ width: 200 }}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegFormClassTwo);
