import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import LocaleStrings from "../../../languages";
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../../components/Common/constant";

import { showCourseDetailsModal } from "../actions";

import cross from "../../../assets/img/cross.png";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const CourseDetails = (props) => {
  const { showModal, showCourseDetailsModal, deviceSize } = props;

  let courseDetailsImages = [];
  if (showModal.item?.masterlegdetails?.image1) {
    courseDetailsImages.push(
      <div>
        <img
          src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image1}?api_key=${FILE_ACCESS_KEY}`}
          className='object-cover rounded-0 sm:rounded-bl-lg w-full'
          style={{ height: deviceSize.width > 640 ? 425 : 235 }}
        />
      </div>
    );
  }
  if (showModal.item?.masterlegdetails?.image2) {
    courseDetailsImages.push(
      <div>
        <img
          src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image2}?api_key=${FILE_ACCESS_KEY}`}
          className='object-cover rounded-0 sm:rounded-bl-lg w-full'
          style={{ height: deviceSize.width > 640 ? 425 : 235 }}
        />
      </div>
    );
  }
  if (showModal.item?.masterlegdetails?.image3) {
    courseDetailsImages.push(
      <div>
        <img
          src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image3}?api_key=${FILE_ACCESS_KEY}`}
          className='object-cover rounded-0 sm:rounded-bl-lg w-full'
          style={{ height: deviceSize.width > 640 ? 425 : 235 }}
        />
      </div>
    );
  }
  if (showModal.item?.masterlegdetails?.image4) {
    courseDetailsImages.push(
      <div>
        <img
          src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image4}?api_key=${FILE_ACCESS_KEY}`}
          className='object-cover rounded-0 sm:rounded-bl-lg w-full'
          style={{ height: deviceSize.width > 640 ? 425 : 235 }}
        />
      </div>
    );
  }
  if (showModal.item?.masterlegdetails?.image5) {
    courseDetailsImages.push(
      <div>
        <img
          src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image5}?api_key=${FILE_ACCESS_KEY}`}
          className='object-cover rounded-0 sm:rounded-bl-lg w-full'
          style={{ height: deviceSize.width > 640 ? 425 : 235 }}
        />
      </div>
    );
  }

  const [value, setValue] = useState(0);
  // const [slides, setSlides] = useState([
  //   <div>
  //     <img
  //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image1}?api_key=${FILE_ACCESS_KEY}`}
  //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
  //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
  //     />
  //   </div>,
  //   <div>
  //     <img
  //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image2}?api_key=${FILE_ACCESS_KEY}`}
  //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
  //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
  //     />
  //   </div>,
  //   <div>
  //     <img
  //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image3}?api_key=${FILE_ACCESS_KEY}`}
  //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
  //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
  //     />
  //   </div>,
  //   <div>
  //     <img
  //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image4}?api_key=${FILE_ACCESS_KEY}`}
  //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
  //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
  //     />
  //   </div>,
  //   <div>
  //     <img
  //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image5}?api_key=${FILE_ACCESS_KEY}`}
  //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
  //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
  //     />
  //   </div>,
  // ]);
  const [slides, setSlides] = useState(courseDetailsImages);

  useEffect(() => {
    // setSlides([
    //   <div>
    //     <img
    //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image1}?api_key=${FILE_ACCESS_KEY}`}
    //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //     />
    //   </div>,
    //   <div>
    //     <img
    //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image2}?api_key=${FILE_ACCESS_KEY}`}
    //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //     />
    //   </div>,
    //   <div>
    //     <img
    //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image3}?api_key=${FILE_ACCESS_KEY}`}
    //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //     />
    //   </div>,
    //   <div>
    //     <img
    //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image4}?api_key=${FILE_ACCESS_KEY}`}
    //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //     />
    //   </div>,
    //   <div>
    //     <img
    //       src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image5}?api_key=${FILE_ACCESS_KEY}`}
    //       className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //       style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //     />
    //   </div>,
    // ]);
    //
    //
    // let arr = [];
    // if (showModal.item?.masterlegdetails?.image1) {
    //   arr.push(
    //     <div>
    //       <img
    //         src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image1}?api_key=${FILE_ACCESS_KEY}`}
    //         className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //         style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //       />
    //     </div>
    //   );
    // }
    // if (showModal.item?.masterlegdetails?.image2) {
    //   arr.push(
    //     <div>
    //       <img
    //         src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image2}?api_key=${FILE_ACCESS_KEY}`}
    //         className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //         style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //       />
    //     </div>
    //   );
    // }
    // if (showModal.item?.masterlegdetails?.image3) {
    //   arr.push(
    //     <div>
    //       <img
    //         src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image3}?api_key=${FILE_ACCESS_KEY}`}
    //         className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //         style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //       />
    //     </div>
    //   );
    // }
    // if (showModal.item?.masterlegdetails?.image4) {
    //   arr.push(
    //     <div>
    //       <img
    //         src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image4}?api_key=${FILE_ACCESS_KEY}`}
    //         className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //         style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //       />
    //     </div>
    //   );
    // }
    // if (showModal.item?.masterlegdetails?.image5) {
    //   arr.push(
    //     <div>
    //       <img
    //         src={`${BASE_AZIONV2_URL}${showModal.item?.masterlegdetails?.image5}?api_key=${FILE_ACCESS_KEY}`}
    //         className='object-cover rounded-0 sm:rounded-bl-lg w-full'
    //         style={{ height: deviceSize.width > 640 ? 425 : 235 }}
    //       />
    //     </div>
    //   );
    // }
    // setSlides(arr);
  }, []);

  const closeModal = () => {
    showCourseDetailsModal({ show: false, item: null });
  };

  const onchange = (value) => {
    setValue(value);
  };

  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className='modal'>
            <div className='modal-container' style={{ maxWidth: 1088 }}>
              {/*content*/}
              <div className='modal-content'>
                {/*header*/}
                <div className='modal-header'>
                  <h3 className='text-base font-medium'>
                    {LocaleStrings.view_course_details}
                  </h3>
                  <button
                    className='modal-close-btn'
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className='relative flex-auto rounded-b'>
                  <div className='grid grid-cols-1 sm:grid-cols-2'>
                    <div className='course-details-carousel-container flex items-center justify-center relative'>
                      <Carousel
                        value={value}
                        slides={slides}
                        onChange={onchange}
                      />
                      <Dots
                        value={value}
                        onChange={onchange}
                        number={slides.length}
                      />

                      {/* --------------- Custom Dot Carousel --------------- */}
                      {/* <ul className='slides'>
                        <input
                          type='radio'
                          name='radio-btn'
                          id='img-1'
                          checked
                        />
                        <li className='slide-container'>
                          <div className='slide'>
                            <img
                              src={showModal.img}
                              className='object-cover rounded-0 sm:rounded-bl-lg w-full'
                            />
                          </div>
                        </li>

                        <input type='radio' name='radio-btn' id='img-2' />
                        <li className='slide-container'>
                          <div className='slide'>
                            <img
                              src={showModal.img}
                              className='object-cover rounded-0 sm:rounded-bl-lg w-full'
                            />
                          </div>
                        </li>

                        <input type='radio' name='radio-btn' id='img-3' />
                        <li className='slide-container'>
                          <div className='slide'>
                            <img
                              src={showModal.img}
                              className='object-cover rounded-0 sm:rounded-bl-lg w-full'
                            />
                          </div>
                        </li>

                        <li className='nav-dots'>
                          <label
                            for='img-1'
                            className='nav-dot'
                            id='img-dot-1'
                          ></label>
                          <label
                            for='img-2'
                            className='nav-dot'
                            id='img-dot-2'
                          ></label>
                          <label
                            for='img-3'
                            className='nav-dot'
                            id='img-dot-3'
                          ></label>
                        </li>
                      </ul> */}
                      {/* --------------- END * Custom Dot Carousel --------------- */}
                    </div>

                    <div className='p-5'>
                      <div className='text-xl text-primary-black font-medium'>
                        {showModal.item?.masterleg_by_legidfk?.name.capitalize()}
                      </div>
                      <div className='mt-7.5'>
                        <div className='uppercase text-sm text-secondary-4 font-bold'>
                          {LocaleStrings.overview}
                        </div>
                        <div
                          className='mt-2 text-sm text-primary-black overflow-auto course-details-overview'
                          style={{ maxHeight: 295 }}
                        >
                          {/* Peru is a country in South America that’s home to a
                          section of Amazon rainforest and Machu Picchu, an
                          ancient Incan city high in the Andes mountains. The
                          region around Machu Picchu, including the Sacred
                          Valley, Inca Trail and colonial city of Cusco, is rich
                          in archaeological sites. On Peru’s arid Pacific coast
                          is Lima, the capital, with a preserved colonial center
                          and important collections of pre-Columbian art. */}

                          {showModal.item?.masterlegdetails?.details}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-background'></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    showModal: state.showCourseDetailsModalReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showCourseDetailsModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
