/* eslint-disable no-unused-vars */
import React from "react";
import { Bar } from "react-chartjs-2";

const TotalPoints = (props) => {
  const { componentObject } = props;

  const chartData = componentObject.chartDataObject;

  var padding;
  componentObject.isMobile ? (padding = 3) : (padding = 15);

  // custom chart-tooltip function starts
  const externalTooltipHandler = (context) => {
    if (!context) {
      console.log("exiting");
      return;
    }

    const { chart, tooltip } = context;
    // tooltip element
    var tooltipEl = chart?.canvas?.parentNode.querySelector("div"); // create
    if (!tooltipEl) {
      tooltipEl = document.createElement("div"); // create tooltip
      tooltipEl.classList.add("tooltipMainDiv", "rounded-t-md");
    }

    // hide the tooltip when mouseout
    if (tooltip?.body[0]?.lines[0] == 0 || tooltip?.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // maindiv sub ul-li
    let tooltipUL = document.createElement("ul");
    let tooltipLI = document.createElement("li");

    // set custom tooltip, inner html
    if (tooltip?.body) {
      var titleLines = tooltip.title || [];
      var bodyLines = tooltip?.body.map((ele) => ele.lines) || [];
    }

    // set inner html to tooltip
    // title
    let titleDiv = document.createElement("div"); // title div
    titleDiv.classList.add("titleDivClass", "rounded-md");
    let titleHeading = document.createElement("h4"); // text as heading
    titleHeading.classList.add("titleHeadingClass");
    let daySpan, dateSpan;
    titleLines.forEach((title) => {
      daySpan = document.createElement("span"); // span for day
      dateSpan = document.createElement("span"); // span for date

      var tooltipTitleDay = document.createTextNode(title); // day node
      var tooltipTitleDate = document.createTextNode(""); // date node

      daySpan.appendChild(tooltipTitleDay);
      dateSpan.appendChild(tooltipTitleDate);
    });
    titleHeading.appendChild(daySpan); // day to heading
    titleHeading.appendChild(dateSpan); // date to heading
    titleDiv.appendChild(titleHeading); // heading top title div

    // body
    let bodyDiv = document.createElement("div"); // body
    bodyDiv.classList.add(
      "bodyDivClass",
      "rounded-b-md",
      "p-4",
      "text-sm",
      "font-medium",
      "border-r",
      "border-b"
    );
    let bodyPara = document.createElement("p"); // body para
    // bodyPara.classList.add(); // if needed to add style classes
    let bodyStaticText = document.createElement("span"); // body para static text
    let staticText = document.createTextNode("Total Points");
    bodyStaticText.classList.add("text-sm");
    bodyStaticText.appendChild(staticText);
    let pointText;
    bodyLines.forEach((element) => {
      pointText = document.createElement("span");
      pointText.classList.add("text-lg", "bold");
      var point = document.createTextNode(element);
      pointText.appendChild(point);
    });
    var brake = document.createElement("br");
    bodyPara.appendChild(bodyStaticText);
    bodyPara.appendChild(brake);
    bodyPara.appendChild(pointText);
    bodyDiv.appendChild(bodyPara);

    tooltipLI.appendChild(titleDiv); // title div to tooltip element
    tooltipLI.appendChild(bodyDiv); // body div to tooltip element

    tooltipUL.appendChild(tooltipLI);
    tooltipEl.appendChild(tooltipUL);

    // remove old children
    var ULnode = tooltipEl.querySelector("ul");
    while (ULnode.firstChild) {
      ULnode.firstChild.remove();
    }
    // add new children
    ULnode.appendChild(tooltipLI);
    tooltipLI.appendChild(titleDiv); // redo title div to tooltip element
    tooltipLI.appendChild(bodyDiv); // redo body div to tooltip element
    tooltipEl.style.opacity = 1; // resetting opacity

    // render the tooltip
    chart?.canvas?.parentNode.appendChild(tooltipEl); // tooltip appended to chart

    // calculate position
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;
    // position
    if (!componentObject.isMobile) {
      tooltipEl.style.left = positionX + tooltip.caretX + 80 + "px";
      tooltipEl.style.top = positionY + tooltip.caretY - 25 + "px";
    } else if (componentObject.calBarThickness === 15) {
      tooltipEl.style.left = tooltip.caretX + 0 + "px";
      tooltipEl.style.top = tooltip.caretY + 150 + "px";
    } else {
      tooltipEl.style.left = positionX + tooltip.caretX + 0 + "px";
      tooltipEl.style.top = positionY + tooltip.caretY - 150 + "px";
    }
  };

  return (
    <>
      {componentObject.showComponent === "totalPoints" ? (
        <Bar
          className="rounded-b-lg"
          data={chartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: {
                grid: {
                  display: false,
                },
                ticks: {
                  display: true,
                  padding: 15,
                },
              },
              yAxes: {
                grid: {
                  display: false,
                },
                ticks: {
                  display: true,
                  padding: padding,
                  stepSize: 15000,
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
              responsive: true,
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                external: externalTooltipHandler,
              },
            },
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default TotalPoints;
