import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const regFormThanks = (props) => {
  const { deviceSize, session, eventDetails } = props;

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center"></div>
            <div
              className="bg-secondary-2 p-12 rounded-lg text-gray-600"
              style={{ height: 300 }}
            >
              <span>
                Thanks for registering your Class Team, please check your inbox
                for an email with more details about the Virtual Adventure.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(regFormThanks);
