import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";
import {
  BASE_URL,
  CUSTOM_ITEM_COUNT,
} from "../../../components/Common/constant";
import { saveState } from "../../../reducers/localStorage";

export const TEAM_MEMBERS = "TEAM_MEMBERS";
export const ACTIVE_TEAM_MEMBERS = "ACTIVE_TEAM_MEMBERS";
export const TEAM_DETAILS = "TEAM_DETAILS";
export const TEAM_CLIENT_ENTRANT_COUNT = "TEAM_CLIENT_ENTRANT_COUNT";
export const ADD_TEAM_MEMBER_MODAL = "ADD_TEAM_MEMBER_MODAL";
export const ADD_TEAM_MODAL = "ADD_TEAM_MODAL";

export function fetchTeamMembers(session, pageCount = 0, callback) {
  var perPage = CUSTOM_ITEM_COUNT;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var filter = encodeURI(
    `(eventidfk=${session.eventid})AND(teamidfk=${session.teamid})AND(isremoved=0)`
  );
  var orderby = `ismanager%20DESC%2C%20createdon%20DESC`;
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})&include_count=true&related=eventclient_by_eventclientidfk&order=${orderby}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: TEAM_MEMBERS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function fetchActiveTeamMembers(session, callback) {
  var filter = encodeURI(
    `(eventidfk=${session.eventid})AND(teamidfk=${session.teamid})AND(isremoved=0)AND(withdrawndate IS NULL)`
  );
  var orderby = `ismanager%20DESC%2C%20createdon%20DESC`;
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})&include_count=true&order=${orderby}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: ACTIVE_TEAM_MEMBERS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function fetchTeamData(session, callback) {
  var filter = encodeURI(`id=${session.teamid}`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team?filter=(${filter})&related=eventclient_by_eventclientidfk`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: TEAM_DETAILS,
          payload: { data: response?.resource?.[0] },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function fetchTeamClientEntrantCount(session, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = {
    type: "eventcliententrantcount",
    teamid: session.teamid,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: TEAM_CLIENT_ENTRANT_COUNT,
          payload: { data: response },
        });
        callback({ success: 1, data: response });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function createTeamMember(session, values, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = { ...values, type: "createmember" };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.is_error) {
          callback({ success: 0 });
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function handleAddTeamMemberModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_TEAM_MEMBER_MODAL,
      payload: obj,
    });
  };
}

export function updateTeamMember(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers`;
  var body = { resource: [{ ...values }] };
  // console.log("body :- ", body);

  return (dispatch) => {
    if (values?.setpassword) {
      var urlPass = `${BASE_URL}api/v2/system/user/${values.dfid}`;
      var bodypass = { password: values.setpassword };
      if (values.ismanager) {
        urlPass = `${BASE_URL}api/v2/user/password?reset=true&login=true`;
        bodypass = {
          old_password: values.old_password,
          new_password: values.setpassword,
        };
      }

      patchRequest(
        urlPass,
        bodypass,
        session,
        dispatch,
        (response) => {
          if (values.ismanager) {
            session.sessionToken = response.session_token;

            var stateToSave = {
              isLoggedIn: true,
              session: session,
            };
            saveState(stateToSave);

            patchRequest(
              url,
              body,
              session,
              dispatch,
              (response) => {
                callback({ success: 1 });
              },
              (error) => {
                callback({ success: 0 });
              }
            );
          } else {
            patchRequest(
              url,
              body,
              session,
              dispatch,
              (response) => {
                callback({ success: 1 });
              },
              (error) => {
                callback({ success: 0 });
              }
            );
          }
        },
        (error) => {
          callback({ success: 0, message: error });
        }
      );
    } else {
      patchRequest(
        url,
        body,
        session,
        dispatch,
        (response) => {
          callback({ success: 1 });
        },
        (error) => {
          callback({ success: 0 });
        }
      );
    }
  };
}

export function handleAddTeamModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_TEAM_MODAL,
      payload: obj,
    });
  };
}

export function validateTeamName(session, values, callback) {
  var filter = encodeURI(
    `(eventidfk=${values.eventidfk})AND(eventclientidfk=${values.eventclientidfk})AND(teamname=${values.teamname})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        if (response.resource.length > 0) {
          callback({ success: 2 });
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function updateTeam(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function withdrawAllTeamMember(session, teamid = null, date, callback) {
  var filter = encodeURI(`(teamidfk=${teamid})AND(withdrawndate IS NULL)`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})`;
  var body = { resource: [{ withdrawndate: date }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function deleteTeamMemberSession(session, dfid, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = { dfid: dfid, type: "deleteusersession" };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}
