import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";

import LocaleStrings from "../../languages";
import Loader from "../../components/Common/loader";

import { fetchTeamDetails, addTrack, deleteTrack } from "./actions";

const ViewAllTeams = (props) => {
  const {
    session,
    deviceSize,
    fetchTeamDetails,
    teamDetailsList,
    addTrack,
    deleteTrack,
  } = props;

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(true);
  const [teamType, setTeamType] = useState("all");
  const [trackChangeLoading, setTrackChangeLoading] = useState(false);

  useEffect(() => {
    fetchTeamDetails(session, teamType, (res) => {
      // console.log(res);
      setLoading(false);
    });
  }, []);

  const selectOpts = [
    { name: "All Teams", value: "all" },
    { name: "Tracked Teams", value: "tracked" },
    { name: "My Team", value: "my" },
  ];

  const handleTeamChange = (e) => {
    let type = e.target.value;
    // console.log("type: ", type);

    setTeamType(type);
    setLoading(true);
    fetchTeamDetails(session, type, (res) => {
      // console.log(res);
      setLoading(false);
    });
  };

  const handleTrackChange = (item) => {
    console.log("item: ", item);
    setTrackChangeLoading(true);

    if (teamType == "tracked") {
      setLoading(true);
    }

    if (item.istracked) {
      deleteTrack(session, parseInt(item.id), (res) => {
        // console.log("res deleteTrack: ", res);

        if (res.success) {
          fetchTeamDetails(session, teamType, (res) => {
            if (teamType == "tracked") {
              setLoading(false);
            }
          });
          setTrackChangeLoading(false);
          let content = "Team tracking removed successfully.";
          addToast(content, {
            appearance: "success",
            autoDismiss: true,
          });
        }
        //
        else {
          if (teamType == "tracked") {
            setLoading(false);
          }
          setTrackChangeLoading(false);
          let content = "Something went wrong.";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }
    //
    else {
      let resource = [
        {
          eventidfk: session.eventid,
          memberidfk: session.userid,
          teamidfk: parseInt(item.id),
        },
      ];

      addTrack(session, resource, (res) => {
        // console.log("res addTrack: ", res);

        if (res.success) {
          fetchTeamDetails(session, teamType);
          setTrackChangeLoading(false);
          let content = "Team is tracked successfully.";
          addToast(content, {
            appearance: "success",
            autoDismiss: true,
          });
        }
        //
        else {
          setTrackChangeLoading(false);
          let content = "Something went wrong.";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }
  };

  return (
    <div className='p-0 sm:p-2'>
      <div
        className='bg-secondary p-2 sm:p-4'
        style={
          deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
        }
      >
        <div className='rounded-lg'>
          {/* --- table header --- */}
          <div className='px-5 py-4 rounded-t-lg bg-white'>
            <h3 className='text-base font-medium'>
              {LocaleStrings.view_all_teams}
            </h3>
          </div>

          <div className='bg-secondary-2 px-5 py-2 flex justify-start items-center'>
            <label htmlFor='select-team' className='text-sm text-secondary-4'>
              {LocaleStrings.select_team}
            </label>
            <select
              id='select-team'
              name='select-team'
              className='ml-4 select'
              defaultValue='All Teams'
              style={{ width: 193 }}
              onChange={(e) => handleTeamChange(e)}
            >
              {/* <option>All Teams</option>
              <option>Tracked Teams</option>
              <option>My Team</option> */}
              {_.map(selectOpts, (item) => {
                return <option value={item.value}>{item.name}</option>;
              })}
            </select>
          </div>

          {!loading ? (
            <>
              {/* --- Table --- */}
              <div className='bg-white rounded-b-none sm:rounded-b-lg'>
                {deviceSize.width > 640 ? (
                  <div className='flex flex-col'>
                    <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                      <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                        <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg view-all-team-table-container'>
                          <table className='min-w-full divide-y divide-gray-200'>
                            <thead className='bg-white'>
                              <tr>
                                <th
                                  scope='col'
                                  className='table-col-th'
                                  style={{ width: "7%" }}
                                >
                                  {LocaleStrings.leg}
                                </th>
                                <th
                                  scope='col'
                                  className='table-col-th'
                                  style={{ width: "30%" }}
                                >
                                  {LocaleStrings.school_company}
                                </th>
                                <th scope='col' className='table-col-th'>
                                  {LocaleStrings.team_name}
                                </th>
                                <th scope='col' className='table-col-th'>
                                  {LocaleStrings.track_name}
                                </th>
                                <th scope='col' className='table-col-th'>
                                  {LocaleStrings.in_team}
                                </th>
                                <th scope='col' className='table-col-th'>
                                  {LocaleStrings.avg_total_points}
                                </th>
                                {/* <th scope='col' className='relative px-6 py-3'>
                            <span className='sr-only'>Edit</span>
                          </th> */}
                              </tr>
                            </thead>
                            <tbody className='bg-white divide-y divide-gray-200'>
                              {teamDetailsList.data.map((team, i) => (
                                <tr key={team.email}>
                                  <td
                                    className='table-col-td'
                                    style={{ width: "7%" }}
                                  >
                                    {team.currentleg}
                                  </td>
                                  <td
                                    className='table-col-td'
                                    style={{ width: "30%" }}
                                  >
                                    {team.schoolname}
                                  </td>
                                  <td className='table-col-td'>
                                    {team.teamname}
                                  </td>
                                  <td className='table-col-td flex items-center'>
                                    {/* {team.istracked ? (
                                      <input
                                        id='track'
                                        aria-describedby='track'
                                        name='track'
                                        type='checkbox'
                                        className='checkbox'
                                        style={{ borderRadius: 3 }}
                                        // checked
                                        defaultChecked={team.istracked}
                                      />
                                    ) : (
                                      <input
                                        id='track'
                                        aria-describedby='track'
                                        name='track'
                                        type='checkbox'
                                        className='checkbox'
                                        style={{ borderRadius: 3 }}
                                      />
                                    )} */}

                                    {/* {console.log(
                                      "team.istracked: ",
                                      team.istracked
                                    )} */}
                                    {session.teamid !== parseInt(team.id) ? (
                                      <>
                                        <input
                                          id='track'
                                          aria-describedby='track'
                                          name='track'
                                          type='checkbox'
                                          className='checkbox'
                                          style={{ borderRadius: 3 }}
                                          // checked={team.istracked}
                                          defaultChecked={team.istracked}
                                          onChange={() =>
                                            handleTrackChange(team)
                                          }
                                          disabled={trackChangeLoading}
                                        />
                                        {/* <span className='ml-2'>
                                          {team.trackorder
                                            ? team.trackorder
                                            : ""}
                                        </span> */}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className='table-col-td'>
                                    {team.totalmembers}
                                  </td>
                                  <td className='table-col-td'>
                                    {team.teamavgtotal}
                                  </td>
                                  {/* <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                              <a
                                href='#'
                                className='text-indigo-600 hover:text-indigo-900'
                              >
                                Edit
                              </a>
                            </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className='shadow overflow-hidden border-b border-gray-200 rounded-b-lg overflow-y-auto'
                    // style={{ maxHeight: 540 }}
                    style={{ height: 540 }}
                  >
                    {teamDetailsList.data.map((team, i) => {
                      let className = "flex justify-between p-4 border-b";
                      if (i == teamDetailsList.data.length - 1) {
                        className = "flex justify-between p-4 rounded-b-lg";
                      }
                      return (
                        <div className={className}>
                          <div className='flex items-start'>
                            {/* {team.istracked ? (
                              <input
                                id='track'
                                aria-describedby='track'
                                name='track'
                                type='checkbox'
                                className='checkbox'
                                style={{ borderRadius: 3 }}
                                checked
                              />
                            ) : (
                              <input
                                id='track'
                                aria-describedby='track'
                                name='track'
                                type='checkbox'
                                className='checkbox'
                                style={{ borderRadius: 3 }}
                              />
                            )} */}

                            {session.teamid !== parseInt(team.id) ? (
                              <input
                                id='track'
                                aria-describedby='track'
                                name='track'
                                type='checkbox'
                                className='checkbox'
                                style={{ borderRadius: 3 }}
                                defaultChecked={team.istracked}
                                onChange={() => handleTrackChange(team)}
                                disabled={trackChangeLoading}
                              />
                            ) : (
                              <div style={{ width: 20 }}></div>
                            )}

                            <div className='ml-4'>
                              <div className='text-secondary-4 text-sm font-medium leading-none'>
                                {team.schoolname}
                              </div>
                              <div className='mt-2 text-primary-black text-sm font-medium'>
                                {team.teamname}
                              </div>
                              <div className='mt-2 flex items-center'>
                                <div className='rounded-full px-3 py-1 bg-primary-black text-xs text-white'>
                                  {`${LocaleStrings.leg} ${team.currentleg}`}
                                </div>
                                <div className='text-secondary-4 text-xs ml-2'>
                                  {`${team.totalmembers} ${LocaleStrings.in_team_2}`}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='text-primary-black flex items-center text-sm font-medium'>
                            {team.teamavgtotal}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className='flex justify-center items-center h-screen'>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log("state.teamDetailsList: ", state.teamDetailsListReducer);
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    teamDetailsList: state.teamDetailsListReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchTeamDetails, addTrack, deleteTrack }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllTeams);
