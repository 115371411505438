/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
// redux
import { bindActionCreators } from "redux";
import { Provider, connect } from "react-redux";
import store from "../../store";
import _ from "lodash";

// image import
import bigAvatar from "../../assets/img/Group-2.png";

const MyAvatar = (props) => {
  const { FetchAvatarReducer } = props;

  var pathObj = {
    avHairPath: "",
    avEyesPath: "",
    avBeardPath: "",
    avMouthPath: "",
    avShoePath: "",
    avBottomPath: "",
    avTopPath: "",
    avHeadPath: "",
    avEyeBrowPath: "",
    avNosePath: "",
    avGlassPath: "",
    avHandRPath: "",
    avHandLPath: "",
  };

  if (!_.isEmpty(FetchAvatarReducer)) {
    switch (FetchAvatarReducer?.skincolor) {
      case "s1":
        pathObj.avHandRPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hand/s1/hand_right.png`;
        pathObj.avHandLPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hand/s1/hand_left.png`;
        break;
      case "s2":
        pathObj.avHandRPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hand/s2/hand_right.png`;
        pathObj.avHandLPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hand/s2/hand_left.png`;
        break;
      case "s3":
        pathObj.avHandRPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hand/s3/hand_right.png`;
        pathObj.avHandLPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hand/s3/hand_left.png`;
        break;
      case "s4":
        pathObj.avHandRPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hand/s4/hand_right.png`;
        pathObj.avHandLPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hand/s4/hand_left.png`;
        break;

      default:
        break;
    }
  }

  if (!_.isEmpty(FetchAvatarReducer)) {
    FetchAvatarReducer.avatar.map((obj, index) => {
      switch (obj.partName) {
        case "hair":
          var avHairNum = obj.partNumber;
          var avHairName = "hair_" + avHairNum + ".png";
          switch (FetchAvatarReducer?.haircolor) {
            case "h1":
              pathObj.avHairPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hair/h1/${avHairName}`;
              break;
            case "h2":
              pathObj.avHairPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hair/h2/${avHairName}`;
              break;
            case "h3":
              pathObj.avHairPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hair/h3/${avHairName}`;
              break;
            case "h4":
              pathObj.avHairPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hair/h4/${avHairName}`;
              break;
            case "h5":
              pathObj.avHairPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hair/h5/${avHairName}`;
              break;
            case "h6":
              pathObj.avHairPath = `${process.env.PUBLIC_URL}/avatar_parts_new/hair/h6/${avHairName}`;
              break;

            default:
              break;
          }
          break;

        case "eyes":
          var avEyesNum = obj.partNumber;
          var avEyesName = "eyes_" + avEyesNum + ".png";
          pathObj.avEyesPath = `${process.env.PUBLIC_URL}/avatar_parts_new/eyes/${avEyesName}`;
          break;

        case "beard":
          var avBeardNum = obj.partNumber;
          var avBeardName = "beard_" + avBeardNum + ".png";
          switch (FetchAvatarReducer?.beardcolor) {
            case "h1":
              pathObj.avBeardPath = `${process.env.PUBLIC_URL}/avatar_parts_new/beard/h1/${avBeardName}`;
              break;
            case "h2":
              pathObj.avBeardPath = `${process.env.PUBLIC_URL}/avatar_parts_new/beard/h2/${avBeardName}`;
              break;
            case "h3":
              pathObj.avBeardPath = `${process.env.PUBLIC_URL}/avatar_parts_new/beard/h3/${avBeardName}`;
              break;
            case "h4":
              pathObj.avBeardPath = `${process.env.PUBLIC_URL}/avatar_parts_new/beard/h4/${avBeardName}`;
              break;
            case "h5":
              pathObj.avBeardPath = `${process.env.PUBLIC_URL}/avatar_parts_new/beard/h5/${avBeardName}`;
              break;
            case "h6":
              pathObj.avBeardPath = `${process.env.PUBLIC_URL}/avatar_parts_new/beard/h6/${avBeardName}`;
              break;

            default:
              break;
          }
          break;

        case "mouth":
          var avMouthNum = obj.partNumber;
          var avMouthName = "mouth_" + avMouthNum + ".png";
          pathObj.avMouthPath = `${process.env.PUBLIC_URL}/avatar_parts_new/mouth/${avMouthName}`;
          break;

        case "bottom":
          var avBottomNum = obj.partNumber;
          var avBottomName = "bottom_" + avBottomNum + ".png";
          pathObj.avBottomPath = `${process.env.PUBLIC_URL}/avatar_parts_new/bottom/${avBottomName}`;
          break;

        case "eye_brow":
          var avEyeBrowNum = obj.partNumber;
          var avEyeBrowName = "eye_brow_" + avEyeBrowNum + ".png";
          switch (FetchAvatarReducer?.eyebrowcolor) {
            case "h1":
              pathObj.avEyeBrowPath = `${process.env.PUBLIC_URL}/avatar_parts_new/eye_brow/h1/${avEyeBrowName}`;
              break;
            case "h2":
              pathObj.avEyeBrowPath = `${process.env.PUBLIC_URL}/avatar_parts_new/eye_brow/h2/${avEyeBrowName}`;
              break;
            case "h3":
              pathObj.avEyeBrowPath = `${process.env.PUBLIC_URL}/avatar_parts_new/eye_brow/h3/${avEyeBrowName}`;
              break;
            case "h4":
              pathObj.avEyeBrowPath = `${process.env.PUBLIC_URL}/avatar_parts_new/eye_brow/h4/${avEyeBrowName}`;
              break;
            case "h5":
              pathObj.avEyeBrowPath = `${process.env.PUBLIC_URL}/avatar_parts_new/eye_brow/h5/${avEyeBrowName}`;
              break;
            case "h6":
              pathObj.avEyeBrowPath = `${process.env.PUBLIC_URL}/avatar_parts_new/eye_brow/h6/${avEyeBrowName}`;
              break;

            default:
              break;
          }
          break;

        case "head":
          var avHeadNum = obj.partNumber;
          var avHeadName = "head_" + avHeadNum + ".png";
          switch (FetchAvatarReducer?.skincolor) {
            case "s1":
              pathObj.avHeadPath = `${process.env.PUBLIC_URL}/avatar_parts_new/head/s1/${avHeadName}`;
              break;
            case "s2":
              pathObj.avHeadPath = `${process.env.PUBLIC_URL}/avatar_parts_new/head/s2/${avHeadName}`;
              break;
            case "s3":
              pathObj.avHeadPath = `${process.env.PUBLIC_URL}/avatar_parts_new/head/s3/${avHeadName}`;
              break;
            case "s4":
              pathObj.avHeadPath = `${process.env.PUBLIC_URL}/avatar_parts_new/head/s4/${avHeadName}`;
              break;

            default:
              break;
          }
          break;

        case "nose":
          var avNoseNum = obj.partNumber;
          var avNoseName = "nose_" + avNoseNum + ".png";
          pathObj.avNosePath = `${process.env.PUBLIC_URL}/avatar_parts_new/nose/${avNoseName}`;
          break;

        case "shoes":
          var avShowNum = obj.partNumber;
          var avShowName = "shoes_" + avShowNum + ".png";
          pathObj.avShoePath = `${process.env.PUBLIC_URL}/avatar_parts_new/shoes/${avShowName}`;
          break;

        case "top":
          var avTopNum = obj.partNumber;
          var avTopName = "top_" + avTopNum + ".png";
          switch (FetchAvatarReducer?.skincolor) {
            case "s1":
              pathObj.avTopPath = `${process.env.PUBLIC_URL}/avatar_parts_new/top/s1/${avTopName}`;
              break;
            case "s2":
              pathObj.avTopPath = `${process.env.PUBLIC_URL}/avatar_parts_new/top/s2/${avTopName}`;
              break;
            case "s3":
              pathObj.avTopPath = `${process.env.PUBLIC_URL}/avatar_parts_new/top/s3/${avTopName}`;
              break;
            case "s4":
              pathObj.avTopPath = `${process.env.PUBLIC_URL}/avatar_parts_new/top/s4/${avTopName}`;
              break;

            default:
              break;
          }
          break;

        case "glasses":
          var avGlassNum = obj.partNumber;
          var avGlassName = "glasses_" + avGlassNum + ".png";
          pathObj.avGlassPath = `${process.env.PUBLIC_URL}/avatar_parts_new/glasses/${avGlassName}`;
          break;

        default:
          console.log("Error While drawing the avatar part, Wrong Data chosen");
          break;
      }
    });
  }

  const class_my_avatar = "h-home-canvas-height";

  return (
    <div className="avatar_container h-home-canvas-height">
      <img
        className={`${class_my_avatar} bottom2`}
        src={pathObj.avBottomPath}
      />
      <img className={`${class_my_avatar} shoes2`} src={pathObj.avShoePath} />
      <img className={`${class_my_avatar} top2`} src={pathObj.avTopPath} />
      <img className={`${class_my_avatar} head2`} src={pathObj.avHeadPath} />
      <img className={`${class_my_avatar} hands2`} src={pathObj.avHandRPath} />
      <img className={`${class_my_avatar} hands2`} src={pathObj.avHandLPath} />
      <img className={`${class_my_avatar} eyes2`} src={pathObj.avEyesPath} />
      <img
        className={`${class_my_avatar} eyeBrow2`}
        src={pathObj.avEyeBrowPath}
      />
      <img className={`${class_my_avatar} beard2`} src={pathObj.avBeardPath} />
      <img className={`${class_my_avatar} nose2`} src={pathObj.avNosePath} />
      <img className={`${class_my_avatar} mouth2`} src={pathObj.avMouthPath} />
      <img className={`${class_my_avatar} hair2`} src={pathObj.avHairPath} />
      <img
        className={`${class_my_avatar} glasses2`}
        src={pathObj.avGlassPath}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    FetchAvatarReducer: state.FetchAvatarReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MyAvatar);
