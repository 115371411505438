import axios from "axios";
import _ from "lodash";

import {
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";

import { BASE_URL, APP_API_KEY } from "../../../components/Common/constant";

export const TEAM_DETAILS_LIST = "TEAM_DETAILS_LIST";

export function fetchTeamDetails(session, type, callback) {
  var url = `${BASE_URL}api/v2/custom_script?type=fetchTeamDetails&eventid=${session.eventid}&userid=${session.userid}&teamtype=${type}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("fetchMyData res: ", response);

        dispatch({ type: TEAM_DETAILS_LIST, payload: { data: response.data } });
        callback({ success: 1, data: response });
      },
      (error) => {
        // callback({ success: 0, data: [] });
      }
    );
  };
}

export const addTrack = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/trackteam`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function deleteTrack(session, teamid, callback) {
  var filter = encodeURI(
    `(eventidfk=${session.eventid}) and (memberidfk=${session.userid}) and (teamidfk=${teamid})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/trackteam?filter=(${filter})`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}
