/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { BASE_URL, APP_API_KEY } from "../../../components/Common/constant";
import axios from "axios";
import _ from "lodash";
import {
  patchRequest,
  getRequest,
} from "../../../components/Common/network-call";

export const SAVE_AVATAR_OPTION_DATA = "SAVE_AVATAR_OPTION_DATA";
export const FETCH_AVATAR_OPTION_DATA = "FETCH_AVATAR_OPTION_DATA";

export const saveAvatarOptData = (avatarOpt) => {
  var skincolor = avatarOpt?.skincolor;
  var haircolor = avatarOpt?.haircolor;
  var beardcolor = avatarOpt?.beardcolor;
  var eyebrowcolor = avatarOpt?.eyebrowcolor;
  var avatar = avatarOpt?.avatar;

  var avatarObject = {
    skincolor: skincolor,
    haircolor: haircolor,
    beardcolor: beardcolor,
    eyebrowcolor: eyebrowcolor,
    avatar: [],
    face: [],
  };

  avatar.map((obj, index) => {
    if (index >= 3) {
      avatarObject.face.push(obj);
    }
    avatarObject.avatar.push(obj);
  });

  return (dispatch) => {
    dispatch({ type: SAVE_AVATAR_OPTION_DATA, payload: avatarObject });
  };
};

export const restoreAvatarData = (avatarObject) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_AVATAR_OPTION_DATA,
      payload: avatarObject,
    });
  };
};

export const fetchAvatarDBData = (session, userid, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers/${userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        if (data.avatar) {
          dispatch({
            type: FETCH_AVATAR_OPTION_DATA,
            payload: JSON.parse(data.avatar),
          });
          // callback({ success: 1, response: data });
        } else {
          console.log("Error while fetching the Avatar Data from DB");
          callback({ success: 0, response: [] });
        }
      },
      (error) => {
        console.log("Error while fetching DB data: ", error);
      }
    );
  };
};

export const updateAvatarData = (session, id, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers/${id}`;
  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        dispatch(fetchAvatarDBData(session, id));
        // callback({ success: 1, response: response });
      },
      (error) => {
        console.log("Error in Avatar Data Update", error);
      }
    );
  };
};
