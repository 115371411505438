import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../../components/Common/constant";

import { showPhotoViewModal } from "../actions";

// import fynn_schmidt_large from "../../../assets/img/fynn-schmidt-large.png";
import cross from "../../../assets/img/cross.png";
import iconFacebook from "../../../assets/img/icons-facebook.png";
import iconTwitter from "../../../assets/img/icons-twitter.png";
import iconInstagram from "../../../assets/img/icons-instagram.png";
import iconWhatsApp from "../../../assets/img/icons-whatsapp.png";
import iconMail from "../../../assets/img/icons-mail.png";

const PhotoView = (props) => {
  const { showModal, showPhotoViewModal } = props;

  const closeModal = () => {
    showPhotoViewModal({ show: false, item: null });
  };

  console.log("showModal: ", showModal);

  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="flex items-start justify-between px-5 py-4 rounded-t">
                  <h3 className="text-base font-medium">
                    {/* Pyramid of Khafre */}
                    {/* {showModal.item?.title} */}
                  </h3>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="relative flex-auto">
                  <img
                    className="object-cover"
                    src={`${BASE_AZIONV2_URL}${showModal.item?.path}?api_key=${FILE_ACCESS_KEY}`}
                    style={{ height: 493, width: 904 }}
                  />
                </div>

                {/*footer*/}
                <div className="flex items-center justify-center p-5 rounded-b">
                  <div className="text-base">Share on</div>
                  <a
                    href="https://www.facebook.com/"
                    className="ml-2.5 cursor-pointer"
                  >
                    <img src={iconFacebook} />
                  </a>
                  <a
                    href="https://twitter.com/"
                    className="ml-2.5 cursor-pointer"
                  >
                    <img src={iconTwitter} />
                  </a>
                  <a
                    href="https://www.instagram.com/"
                    className="ml-2.5 cursor-pointer"
                  >
                    <img src={iconInstagram} />
                  </a>
                  <a
                    href="https://web.whatsapp.com/"
                    className="ml-2.5 cursor-pointer"
                  >
                    <img className="ml-2.5 cursor-pointer" src={iconWhatsApp} />
                  </a>
                  <a
                    href="https://mailto:someone@example.com/"
                    className="ml-2.5 cursor-pointer"
                  >
                    <img className="ml-2.5 cursor-pointer" src={iconMail} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    showModal: state.showPhotoViewModalReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showPhotoViewModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PhotoView);
