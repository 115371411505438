import axios from "axios";
import _ from "lodash";

import {
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";
import { BASE_URL, APP_API_KEY } from "../../../components/Common/constant";

export const SHOW_COURSE_DETAILS_MODAL = "SHOW_COURSE_DETAILS_MODAL";
export const SHOW_EDIT_COURSE_MODAL = "SHOW_EDIT_COURSE_MODAL";
export const FETCH_COURSE_DETAILS_DATA = "FETCH_COURSE_DETAILS_DATA";
export const SHOW_EDIT_COURSE = "SHOW_EDIT_COURSE";
export const EVENT_CLIENT_DATA = "EVENT_CLIENT_DATA";

export function showCourseDetailsModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_COURSE_DETAILS_MODAL, payload: obj });
  };
}

export function showEditCourseModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_EDIT_COURSE_MODAL, payload: obj });
  };
}

export function fetchCourseDetails(session, callback) {
  var url = `${BASE_URL}api/v2/custom_script?type=fetchCourseDetails&eventid=${session.eventid}&userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("fetchCourseDetails res: ", response);

        dispatch({
          type: FETCH_COURSE_DETAILS_DATA,
          payload: { data: response.data },
        });
        callback({ success: 1, data: response.data });
      },
      (error) => {
        // callback({ success: 0, data: [] });
      }
    );
  };
}

export function showEditCourse(session, callback) {
  var url = `${BASE_URL}api/v2/custom_script?type=editCourse&eventid=${session.eventid}&userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("showEditCourse res: ", response);

        dispatch({
          type: SHOW_EDIT_COURSE,
          payload: { data: response },
        });
        callback({ success: 1, data: response });
      },
      (error) => {
        // callback({ success: 0, data: [] });
      }
    );
  };
}

export function fetchEventClient(session, callback) {
  var filter = encodeURI(`(id=${session.eventclientid})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclient?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: EVENT_CLIENT_DATA,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export const addEventClientLegs = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclientlegs`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response.resource });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function removeEventClientLegs(session, item, callback) {
  var filter = encodeURI(
    `(eventidfk=${item.eventidfk}) and (eventclientidfk=${item.eventclientidfk}) and (eventlegidfk=${item.eventlegidfk})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclientlegs?filter=(${filter})`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function updateEventClientLegs(session, item, callback) {
  var filter = encodeURI(
    `(eventidfk=${item.eventidfk}) and (eventclientidfk=${item.eventclientidfk}) and (eventlegidfk=${item.eventlegidfk})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclientlegs?filter=(${filter})`;

  let resource = {
    resource: [{ order: item.order }],
  };

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}
