import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import _ from "lodash";
import classnames from "classnames";
import LocaleStrings from "../../../languages";

export default function Pagination(props) {
  var {
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    pageRangeDisplayed,
  } = props;

  const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);

  // console.log("totalItemsCount :- ", totalItemsCount);
  // console.log("itemsCountPerPage :- ", itemsCountPerPage);
  // console.log("totalPages :- ", totalPages);

  const handlePage = (action) => {
    var index = action === "next" ? activePage + 1 : activePage - 1;

    props.callbackCall(index);
  };

  // function doPaging(current, {range, pages, start = 1}) {
  //   const paging = [];
  //   var i = Math.min(pages + start - range, Math.max(start, current - (range / 2 | 0)));
  //   const end = i + range;
  //   while (i < end) { paging.push(i === current ? [${i++}] : ${i++}) }
  //   return paging;
  // }
  // // test
  // var i;
  // const paging = {range : 5, pages : 20};
  // for (i = 1; i <= 20; i ++) { console.log(doPaging(i, paging).join(",")) }

  const _renderPageNumber = () => {
    var loopPage = _.times(totalPages, Number); // 1-7 - 4 ->  3 4 5 6 -> 4
    var from =
      activePage == 1
        ? 0
        : activePage + pageRangeDisplayed > totalPages
        ? totalPages - (pageRangeDisplayed + 1)
        : activePage - 1;
    var position =
      activePage == 1
        ? pageRangeDisplayed
        : activePage + pageRangeDisplayed > totalPages
        ? totalPages
        : pageRangeDisplayed - 1 + activePage;
    var tPages = loopPage.slice(from, position);

    // console.log("loopPage :- ", loopPage);
    // console.log(
    //   "activePage + pageRangeDisplayed :- ",
    //   activePage + pageRangeDisplayed
    // );
    // console.log("totalPages :- ", totalPages);
    // console.log("tPages :- ", tPages);
    // console.log("activePage :- ", activePage);
    // console.log("from :- ", from);
    // console.log("position :- ", position);
    // console.log("pageRangeDisplayed :- ", pageRangeDisplayed);

    return _.map(tPages, (i) => {
      var index = i + 1;
      var isactive = index == activePage;

      return (
        <a
          key={`pagi_${index}`}
          aria-current="page"
          className={`${
            isactive
              ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
              : " bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
          } relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer`}
          onClick={() => (isactive ? null : props.callbackCall(index))}
        >
          {index}
        </a>
      );
    });
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          className={classnames(
            activePage == 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer",
            "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          )}
          onClick={() => (activePage == 1 ? null : handlePage("previous"))}
        >
          {LocaleStrings.button_previous}
        </a>
        <a
          className={classnames(
            activePage == totalPages
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer",
            "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          )}
          onClick={() => (activePage == totalPages ? null : handlePage("next"))}
        >
          {LocaleStrings.button_next}
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {activePage == 1 ? 1 : itemsCountPerPage * (activePage - 1) + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {totalItemsCount > itemsCountPerPage * activePage
                ? itemsCountPerPage * activePage
                : totalItemsCount}
            </span>{" "}
            of <span className="font-medium">{totalItemsCount}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <a
              className={classnames(
                activePage == 1
                  ? "opacity-50 cursor-not-allowed"
                  : "cursor-pointer",
                "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              )}
              onClick={() => (activePage == 1 ? null : handlePage("previous"))}
            >
              <span className="sr-only">{LocaleStrings.button_previous}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>

            {_renderPageNumber()}
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {/* <a
              href="#"
              aria-current="page"
              className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              1
            </a>
            <a
              href="#"
              className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              2
            </a>*/}
            <a
              className={classnames(
                activePage == totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : "cursor-pointer",
                "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              )}
              onClick={() =>
                activePage == totalPages ? null : handlePage("next")
              }
            >
              <span className="sr-only">{LocaleStrings.button_next}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
