import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import { regFormAction } from "../action";

const RegFormClassOne = (props) => {
  const {
    deviceSize,
    session,
    eventDetails,
    handleSubmit,
    pristine,
    reset,
    submitting,
    regFormAction,
  } = props;

  var termsAndConditions = JSON.stringify();

  const renderField = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error },
  }) => {
    return (
      <div>
        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
          {label}
        </label>
        <div className="mt-1">
          <input
            className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            {...input}
            type={type}
            placeholder={placeholder}
          />
          {touched && error && (
            <span className="text-sm text-red-500">{error}</span>
          )}
        </div>
      </div>
    );
  };

  const renderFieldCheckbox = ({
    input,
    span,
    type,
    meta: { touched, error },
  }) => {
    return (
      <div className="py-3">
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
        <br />
        <input {...input} type={type} />
        <span className="pl-2">{span}</span>
      </div>
    );
  };

  const onSubmitForm = (values, dispatch) => {
    console.log("submit Values: ", values);
    regFormAction(values);
  };

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center mb-3">
              <h2 className="text-5xl pb-3 pt-3 mb-3">
                Teachers Register Your Class
              </h2>
              <span className="">
                Please fill the details below, then click submit.
              </span>
              <br />
              <div className="px-2">
                <span className="text-red-600">
                  NOTE: Your email address is your Username for logging in later
                  so please remember that along with the password your create
                  below.
                </span>
              </div>
            </div>
            <div
              className="bg-secondary-2 py-2 rounded-lg"
              style={
                deviceSize.width >= 768
                  ? { minHeight: 630, overflow: "hidden" }
                  : { minHeight: 1350, overflow: "hidden" }
              }
            >
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="flex px-6 md:px-6 lg:px-12 text-xl justify-center md:justify-start md:text-3xl">
                  <span className="mb-2">Class Details</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2 lg:gap-4 xl:gap-6 px-6 md:px-6 lg:px-12">
                  <div className="">
                    <Field
                      name="classname"
                      type="text"
                      component={renderField}
                      label="Class name or room number"
                      placeholder="Enter name/room no."
                    />
                  </div>
                  <div className="">
                    <Field
                      name="teamname"
                      type="text"
                      component={renderField}
                      label="Team Name"
                      placeholder="Enter team name"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 md:gap-2 lg:gap-4 xl:gap-6 px-6 md:px-6 lg:px-12 my-5">
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="schoolname"
                      type="text"
                      component={renderField}
                      label="School Name"
                      placeholder="Enter school name"
                    />
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="schooladdress"
                      type="text"
                      component={renderField}
                      label="School Address"
                      placeholder="Enter school address"
                    />
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="schoolsuburb"
                      type="text"
                      component={renderField}
                      label="School Suburb"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="schoolcity"
                      type="text"
                      component={renderField}
                      label="School City"
                      placeholder="Enter school city"
                    />
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="students"
                      type="number"
                      component={renderField}
                      label="No. of Students"
                      placeholder="Select a no."
                    />
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="email"
                      type="email"
                      component={renderField}
                      label="Email Address"
                      placeholder="Enter your email"
                    />
                  </div>
                </div>

                <div className="flex px-6 md:px-6 lg:px-12 text-xl justify-center md:justify-start md:text-3xl pt-3">
                  <span className="mb-2">Teacher Details</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2 lg:gap-4 xl:gap-6 px-6 md:px-6 lg:px-12">
                  <div className="">
                    <Field
                      name="firstname"
                      type="text"
                      component={renderField}
                      label="First Name"
                      placeholder="Enter your first name"
                    />
                  </div>
                  <div className="">
                    <Field
                      name="lastname"
                      type="text"
                      component={renderField}
                      label="Last Name"
                      placeholder="Enter your last name"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 md:gap-2 lg:gap-4 xl:gap-6 px-6 md:px-6 lg:px-12 my-5">
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="mobile"
                      type="text"
                      component={renderField}
                      label="Mobile Number"
                      placeholder="Enter mob. no."
                    />
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                      Email (cannot change)
                    </label>
                    <div className="mt-1">
                      <input
                        name="email"
                        className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                        type="email"
                        placeholder="David.Jones@mail.com"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="password"
                      type="password"
                      component={renderField}
                      label="Password"
                      placeholder="Enter password"
                    />
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <Field
                      name="confirmpassword"
                      type="text"
                      component={renderField}
                      label="Confirm Password"
                      placeholder="Reenter password"
                    />
                  </div>
                </div>

                {/* terms and conditions */}
                <div className="px-6" id="add_after_me">
                  {/* <script>
                    {document
                      .getElementById("add_after_me")
                      .insertAdjacentHTML(
                        "afterend",
                        JSON.parse(termsAndConditions)
                      )}
                  </script> */}
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Alias magnam temporibus consectetur tempora corrupti dicta
                  ullam, delectus inventore earum eius, dolorum, esse voluptates
                  quisquam nisi odit quod neque architecto officia! Dicta libero
                  rem vel qui sint, expedita soluta, nulla quasi vitae fugiat,
                  quod sequi alias. Earum cumque enim voluptatibus, doloribus
                  ullam veritatis ab asperiores ipsam. Lorem, ipsum dolor sit
                  amet consectetur adipisicing elit. Repellat voluptates unde
                  eius deleniti eveniet, et accusantium mollitia! Dolorem
                  laudantium fuga perferendis voluptate id.
                  <Field
                    name="terms"
                    type="checkbox"
                    component={renderFieldCheckbox}
                    span="Yes, I agree to the Terms and Conditions"
                  />
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Alias magnam temporibus consectetur tempora corrupti dicta
                  ullam, delectus inventore earum eius, dolorum, esse voluptates
                  quisquam nisi odit quod neque architecto officia!
                  <Field
                    name="consent"
                    type="checkbox"
                    component={renderFieldCheckbox}
                    span="I give consent to receive communcation from the Young and Helthy 2019 Trust"
                  />
                </div>

                {/* form submit */}
                <div className="flex justify-center m-6">
                  <button
                    type="submit"
                    disabled={pristine || submitting}
                    className="mt-2 btn bg-refresh text-white rounded-lg h-9"
                    style={{ width: 200 }}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const validate = (values) => {
  var errors = {};

  if (!values.classname) {
    errors.classname = "Required*";
  }
  if (!values.teamname) {
    errors.teamname = "Required*";
  }

  if (!values.schoolname) {
    errors.schoolname = "Required*";
  }
  if (!values.schooladdress) {
    errors.schooladdress = "Required*";
  }
  if (!values.schoolsuburb) {
    errors.schoolsuburb = "Required*";
  }
  if (!values.schoolcity) {
    errors.schoolcity = "Required*";
  }

  if (!values.students) {
    errors.students = "Required*";
  } else if (isNaN(Number(values.students))) {
    errors.students = "Must be a number";
  }
  if (!values.email) {
    errors.email = "Required*";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.firstname) {
    errors.firstname = "Required*";
  } else if (values.firstname.length > 15) {
    errors.firstname = "Must be 15 characters or less";
  }
  if (!values.lastname) {
    errors.lastname = "Required*";
  } else if (values.lastname.length > 15) {
    errors.lastname = "Must be 15 characters or less";
  }

  if (!values.mobile) {
    errors.mobile = "Required*";
  } else if (isNaN(Number(values.mobile))) {
    errors.mobile = "Must be a number";
  }
  if (!values.password) {
    errors.password = "Required*";
  }
  if (!values.confirmpassword) {
    errors.confirmpassword = "Required*";
  } else if (values.password != values.confirmpassword) {
    errors.confirmpassword = "Passwords do not match";
  }

  if (!values.terms) {
    errors.terms = "Required*";
  }
  if (!values.consent) {
    errors.consent = "Required*";
  }

  // if (!values.confirmemail) {
  //   errors.confirmemail = "Required*";
  // } else if (
  //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.confirmemail)
  // ) {
  //   errors.confirmemail = "Invalid email address";
  // } else if (values.email != values.confirmemail) {
  //   errors.confirmemail = "Emails do not match";
  // }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ regFormAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RegistrationFormOne",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RegFormClassOne)
);
