import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// import Slider from "react-rangeslider";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import { useToasts } from "react-toast-notifications";
import DatePicker from "react-datepicker";
import moment from "moment";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import {
  showEnterDataModal,
  fetchActivityList,
  fetchBonusPointsList,
  addMemberLiveData,
  addMemberLiveActivity,
  updateTeamData,
  fetchDataEntry,
  fetchMyDataList,
  fetchEventClientLegs,
  fetchClassDataList,
} from "../actions";
import { fetchHomeScreenData } from "../../home/actions";
import LocaleStrings from "../../../languages";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT_WITH_SECOND,
} from "../../../components/Common/constant";
import { renderHiddenFieldText } from "../../../components/Common/base-components";
import cross from "../../../assets/img/cross.png";
import info_white from "../../../assets/img/info-white.svg";
import minus from "../../../assets/img/minus-danger-rounded.svg";
import plus from "../../../assets/img/plus-activity-points.svg";
import glass_of_water from "../../../assets/img/glass-of-water.png";
import glass_of_water_slider_thumb from "../../../assets/img/glass-of-water-slider-thumb-with-bg.png";
import grapes from "../../../assets/img/fruits-and-vegetables-fruit-food-grapes.png";
import grapes_slider_thumb from "../../../assets/img/fruits-and-vegetables-fruit-food-grapes-slider-thumb.png";
import carrot from "../../../assets/img/fruits-and-vegetables-vegetable-food-carrot.png";
import carrot_slider_thumb from "../../../assets/img/fruits-and-vegetables-vegetable-food-carrot-slider-thumb.png";
import burger_cola from "../../../assets/img/fastfood-burger-cola.png";
import burger_cola_slider_thumb from "../../../assets/img/fastfood-burger-cola-slider-thumb.png";
import food_spoon_jar from "../../../assets/img/kids-baby-food-spoon-jar.png";
import food_spoon_jar_slider_thumb from "../../../assets/img/kids-baby-food-spoon-jar-slider-thumb.png";
import halloween from "../../../assets/img/halloween-moon-clouds-night.png";
import halloween_slider_thumb from "../../../assets/img/halloween-moon-clouds-night-slider-thumb.png";
import computers_phone from "../../../assets/img/computers-and-hardware-screen-pc-smart-phone.png";
import computers_phone_slider_thumb from "../../../assets/img/computers-and-hardware-screen-pc-smart-phone-slider-thumb.png";
import brain from "../../../assets/img/brain.png";
import brain_slider_thumb from "../../../assets/img/brain-slider-thumb.png";

const renderField = ({
  input,
  label,
  placeholder,
  type,
  max,
  min,
  meta: { touched, error },
}) => {
  return (
    <div>
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
      </label>
      <div className="mt-1">
        <input
          className="enter-data-steps-input"
          {...input}
          type={type}
          placeholder={placeholder}
          max={max}
          min={min}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const EnterData = (props) => {
  const {
    showModal,
    showEnterDataModal,
    deviceSize,
    fetchActivityList,
    session,
    handleSubmit,
    pristine,
    submitting,
    invalid,
    fetchBonusPointsList,
    homeScreenData,
    addMemberLiveData,
    addMemberLiveActivity,
    reset,
    fetchHomeScreenData,
    updateTeamData,
    fetchDataEntry,
    fetchMyDataList,
    eventClientLegsList,
    fetchEventClientLegs,
    eventDetails,
    fetchClassDataList,
  } = props;
  const history = useHistory();
  const { addToast } = useToasts();

  // const [activityArr, setActivityArr] = useState([]);
  // const [sliderValue, setSliderValue] = useState(10);

  const [activityOpts, setActivityOpts] = useState([]);
  const [intensityOpts, setIntensityOpts] = useState([
    { value: 1100, name: "High" },
    { value: 1020, name: "Medium" },
    { value: 1001, name: "Low" },
  ]);
  const [durationOpts, setDurationOpts] = useState([
    { value: 10, name: "10 mins" },
    { value: 20, name: "20 mins" },
    { value: 30, name: "30 mins" },
    { value: 40, name: "40 mins" },
    { value: 50, name: "50 mins" },
    { value: 60, name: "60 mins" },
    { value: 70, name: "70 mins" },
    { value: 80, name: "80 mins" },
    { value: 90, name: "90 mins" },
  ]);

  const [water, setWater] = useState({});
  const [fruit, setFruit] = useState({});
  const [veges, setVeges] = useState({});
  const [foods, setFoods] = useState({});
  const [sugar, setSugar] = useState({});
  const [sleep, setSleep] = useState({});
  const [screentime, setScreentime] = useState({});
  const [mindfulness, setMindfulness] = useState({});

  const [loading, setLoading] = useState(false);

  const [sliderArr, setSliderArr] = useState([
    {
      key: "water",
      colorstopval: 0,
      colorstopsecondarycolor: "#7EE076",
      value: -1,
      unit: "Cups",
    },
    {
      key: "fruit",
      colorstopval: 0,
      colorstopsecondarycolor: "#FF8327",
      value: -1,
      unit: "",
    },
    {
      key: "veges",
      colorstopval: 0,
      colorstopsecondarycolor: "#FF4A4A",
      value: -1,
      unit: "",
    },
    {
      key: "foods",
      colorstopval: 0,
      colorstopsecondarycolor: "#7EE076",
      value: -1,
      unit: "",
    },
    {
      key: "sugar",
      colorstopval: 0,
      colorstopsecondarycolor: "#FD8326",
      value: -1,
      unit: "Tsp.",
    },
    {
      key: "sleep",
      colorstopval: 0,
      colorstopsecondarycolor: "#7EE076",
      value: 0,
      unit: "h",
    },
    {
      key: "screentime",
      colorstopval: 0,
      colorstopsecondarycolor: "#FD4A4A",
      value: -1,
      unit: "",
    },
    {
      key: "mindfulness",
      colorstopval: 0,
      colorstopsecondarycolor: "#FD8326",
      value: -1,
      unit: "",
    },
  ]);
  const [bonusPointsArr, setBonusPointsArr] = useState([]);

  const [tooltipOpen, setTooltipOpen] = useState(-1);

  const toggle = (index) => {
    if (index != tooltipOpen) setTooltipOpen(index);
    else setTooltipOpen(-1);
  };
  // const [bonusTypes, setBonusTypes] = useState([]);

  // const addNewActivityRef = useRef(null);

  useEffect(() => {
    fetchActivityList(session, (res) => {
      if (res.success) {
        // console.log(res.data);
        let arr = [];
        _.map(res.data, (item) => {
          arr.push({ value: item.id, name: item.name });
        });

        setActivityOpts(arr);
      }
    });

    fetchBonusPointsList(session, (res) => {
      if (res.success) {
        // console.log(res.data);

        let uniqArr = _.uniqBy(res.data, "bonustype");
        // console.log("uniqArr: ", uniqArr);
        let uniqBonustypes = _.map(uniqArr, "bonustype");
        // console.log("uniqBonustypes: ", uniqBonustypes);
        // setBonusTypes(uniqBonustypes);

        let filter = [];
        let sorted = [];
        let length = 0;

        // --- for water ---
        let objWater = {};
        filter = _.filter(res.data, { bonustype: "water" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objWater.data = sorted;
        objWater.min = sorted[0].quantity;
        objWater.defaultvalue = sorted[0].quantity;
        objWater.max = sorted[length - 1].quantity;
        objWater.step = 1;
        // console.log("objWater: ", objWater);
        setWater(objWater);

        // --- for fruit ---
        let objFruit = {};
        filter = _.filter(res.data, { bonustype: "fruit" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objFruit.data = sorted;
        objFruit.min = sorted[0].quantity;
        objFruit.defaultvalue = sorted[0].quantity;
        objFruit.max = sorted[length - 1].quantity;
        objFruit.step = 1;
        // console.log("objFruit: ", objFruit);
        setFruit(objFruit);

        // --- for veges ---
        let objVeges = {};
        filter = _.filter(res.data, { bonustype: "veges" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objVeges.data = sorted;
        objVeges.min = sorted[0].quantity;
        objVeges.defaultvalue = sorted[0].quantity;
        objVeges.max = sorted[length - 1].quantity;
        objVeges.step = 1;
        // console.log("objVeges: ", objVeges);
        setVeges(objVeges);

        // --- for foods ---
        let objFoods = {};
        filter = _.filter(res.data, { bonustype: "foods" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objFoods.data = sorted;
        objFoods.min = sorted[0].quantity;
        objFoods.defaultvalue = sorted[0].quantity;
        objFoods.max = sorted[length - 1].quantity;
        objFoods.step = 1;
        // console.log("objFoods: ", objFoods);
        setFoods(objFoods);

        // --- for sugar ---
        let objSugar = {};
        filter = _.filter(res.data, { bonustype: "sugar" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objSugar.data = sorted;
        objSugar.min = sorted[0].quantity;
        objSugar.defaultvalue = sorted[0].quantity;
        objSugar.max = sorted[length - 1].quantity;
        objSugar.step = 1;
        // console.log("objSugar: ", objSugar);
        setSugar(objSugar);

        // --- for sleep ---
        let objSleep = {};
        filter = _.filter(res.data, { bonustype: "sleep" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["starttime"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objSleep.data = sorted;
        objSleep.min = sorted[0].starttime;
        objSleep.defaultvalue = sorted[0].starttime;
        objSleep.max = sorted[length - 1].endtime;
        objSleep.step = 0.5;
        // console.log("objSleep: ", objSleep);
        setSleep(objSleep);

        // --- for screentime ---
        let objScreentime = {};
        filter = _.filter(res.data, { bonustype: "screentime" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["starttime"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objScreentime.data = sorted;
        objScreentime.min = sorted[0].starttime;
        objScreentime.defaultvalue = sorted[0].starttime;
        objScreentime.max = sorted[length - 1].endtime;
        objScreentime.step = 1;
        //console.log("objScreentime: ", objScreentime);
        setScreentime(objScreentime);

        // --- for mindfulness ---
        let objMindfulness = {};
        filter = _.filter(res.data, { bonustype: "mindfulness" });
        // console.log("filter: ", filter);
        sorted = _.sortBy(filter, ["quantity"]);
        // console.log("sorted: ", sorted);
        length = sorted.length;
        objMindfulness.data = sorted;
        objMindfulness.min = sorted[0].quantity;
        objMindfulness.defaultvalue = sorted[0].quantity;
        objMindfulness.max = sorted[length - 1].quantity;
        objMindfulness.step = 1;
        // console.log("objMindfulness: ", objMindfulness);
        setMindfulness(objMindfulness);

        // setting the dynamic sliders
        let bonusArr = [];
        _.map(uniqBonustypes, (type) => {
          if (type == "water") {
            bonusArr.push({
              name: "water",
              img: glass_of_water,
              thumb: "glass-of-water-slider-thumb-with-bg.png",
              qty: "6 Cups",
              unit: "of Water",
              data: objWater,
            });
          } else if (type == "fruit") {
            bonusArr.push({
              name: "fruit",
              img: grapes,
              thumb: "fruits-and-vegetables-fruit-food-grapes-slider-thumb.png",
              qty: "6",
              unit: "Fruit",
              data: objFruit,
            });
          } else if (type == "veges") {
            bonusArr.push({
              name: "veges",
              img: carrot,
              thumb:
                "fruits-and-vegetables-vegetable-food-carrot-slider-thumb.png",
              qty: "1",
              unit: "Vegetables",
              data: objVeges,
            });
          } else if (type == "foods") {
            bonusArr.push({
              name: "foods",
              img: burger_cola,
              thumb: "fastfood-burger-cola.png",
              qty: "6",
              unit: "Snacks",
              data: objFoods,
            });
          } else if (type == "sugar") {
            bonusArr.push({
              name: "sugar",
              img: food_spoon_jar,
              thumb: "kids-baby-food-spoon-jar-slider-thumb.png",
              qty: "3 Tsp.",
              unit: "of Sugar",
              data: objSugar,
            });
          } else if (type == "sleep") {
            bonusArr.push({
              name: "sleep",
              img: halloween,
              thumb: "halloween-moon-clouds-night-slider-thumb.png",
              qty: "8h 53m",
              unit: "Sleep Time",
              data: objSleep,
            });
          } else if (type == "screentime") {
            bonusArr.push({
              name: "screentime",
              img: computers_phone,
              thumb:
                "computers-and-hardware-screen-pc-smart-phone-slider-thumb.png",
              qty: "2h 30m",
              unit: "Screen Time",
              data: objScreentime,
            });
          } else if (type == "mindfulness") {
            bonusArr.push({
              name: "mindfulness",
              img: brain,
              thumb: "brain-slider-thumb.png",
              qty: "1-10",
              unit: "Mindfulness",
              data: objMindfulness,
            });
          }
        });
        // console.log("bonusArr: ", bonusArr);
        setBonusPointsArr(bonusArr);
      }
    });

    // for finding next clientlegid
    fetchEventClientLegs(session, (res) => {});
  }, []);

  // const bonusPointsArr = [
  //   {
  //     name: "water",
  //     img: glass_of_water,
  //     // thumb: glass_of_water_slider_thumb,
  //     thumb: "glass-of-water-slider-thumb-with-bg.png",
  //     qty: "6 Cups",
  //     unit: "of Water",
  //     data: water,
  //   },
  //   {
  //     name: "fruit",
  //     img: grapes,
  //     // thumb: grapes_slider_thumb,
  //     thumb: "fruits-and-vegetables-fruit-food-grapes-slider-thumb.png",
  //     qty: "6",
  //     unit: "Fruit",
  //     data: fruit,
  //   },
  //   {
  //     name: "veges",
  //     img: carrot,
  //     // thumb: carrot_slider_thumb,
  //     thumb: "fruits-and-vegetables-vegetable-food-carrot-slider-thumb.png",
  //     qty: "1",
  //     unit: "Vegetables",
  //     data: veges,
  //   },
  //   {
  //     name: "foods",
  //     img: burger_cola,
  //     // thumb: burger_cola_slider_thumb,
  //     thumb: "fastfood-burger-cola.png",
  //     qty: "6",
  //     unit: "Snacks",
  //     data: foods,
  //   },
  //   {
  //     name: "sugar",
  //     img: food_spoon_jar,
  //     // thumb: food_spoon_jar_slider_thumb,
  //     thumb: "kids-baby-food-spoon-jar-slider-thumb.png",
  //     qty: "3 Tsp.",
  //     unit: "of Sugar",
  //     data: sugar,
  //   },
  //   {
  //     name: "sleep",
  //     img: halloween,
  //     // thumb: halloween_slider_thumb,
  //     thumb: "halloween-moon-clouds-night-slider-thumb.png",
  //     qty: "8h 53m",
  //     unit: "Sleep Time",
  //     data: sleep,
  //   },
  //   {
  //     name: "screentime",
  //     img: computers_phone,
  //     // thumb: computers_phone_slider_thumb,
  //     thumb: "computers-and-hardware-screen-pc-smart-phone-slider-thumb.png",
  //     qty: "2h 30m",
  //     unit: "Screen Time",
  //     data: screentime,
  //   },
  //   {
  //     name: "mindfulness",
  //     img: brain,
  //     // thumb: brain_slider_thumb,
  //     thumb: "brain-slider-thumb.png",
  //     qty: "1-10",
  //     unit: "Mindfulness",
  //     data: mindfulness,
  //   },
  // ];

  const closeModal = () => {
    //showEnterDataModal({ show: false, data: null });

    showEnterDataModal({
      show: false,
      data: {
        callfrom: null,
        name: null,
        type: null,
        item: null,
      },
    });

    setSliderArr([
      {
        key: "water",
        colorstopval: 0,
        colorstopsecondarycolor: "#7EE076",
        value: -1,
        unit: "Cups",
      },
      {
        key: "fruit",
        colorstopval: 0,
        colorstopsecondarycolor: "#FF8327",
        value: -1,
        unit: "",
      },
      {
        key: "veges",
        colorstopval: 0,
        colorstopsecondarycolor: "#FF4A4A",
        value: -1,
        unit: "",
      },
      {
        key: "foods",
        colorstopval: 0,
        colorstopsecondarycolor: "#7EE076",
        value: -1,
        unit: "",
      },
      {
        key: "sugar",
        colorstopval: 0,
        colorstopsecondarycolor: "#FD8326",
        value: -1,
        unit: "Tsp.",
      },
      {
        key: "sleep",
        colorstopval: 0,
        colorstopsecondarycolor: "#7EE076",
        value: 0,
        unit: "h",
      },
      {
        key: "screentime",
        colorstopval: 0,
        colorstopsecondarycolor: "#FD4A4A",
        value: -1,
        unit: "",
      },
      {
        key: "mindfulness",
        colorstopval: 0,
        colorstopsecondarycolor: "#FD8326",
        value: -1,
        unit: "",
      },
    ]);

    setLoading(false);
    reset();
  };

  // const handleAdd = () => {
  //   const arr = activityArr.slice();
  //   arr.push({ dropdown1: "Football", dropdown2: "High", input: "2 Hours" });
  //   setActivityArr(arr);
  // };

  // const handleRemove = (index) => {
  //   const arr = activityArr.slice();
  //   arr.splice(index, 1);
  //   setActivityArr(arr);
  // };

  // const handleChangeStart = () => {
  //   console.log("Change event started");
  // };
  // const handleChange = (value) => {
  //   setSliderValue(value);
  // };
  // const handleChangeComplete = () => {
  //   console.log("Change event completed");
  // };

  // const handleSubmit = (event) => {
  //   const formData = new FormData(event.currentTarget);
  //   event.preventDefault();

  //   let fieldsValue = [];

  //   for (let [key, value] of formData.entries()) {
  //     // console.log(key, value);
  //     fieldsValue.push({ key: key, value: value });
  //   }
  //   console.log(fieldsValue);
  // };

  const renderSelect = (field) => {
    return (
      <div className={field.margin ? field.margin : ""}>
        {/* <label className={labelClasses}>
          {field.label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label> */}

        <div className="">
          <select
            className="enter-data-activity-select"
            {...field.input}
            style={{ width: field.width }}
          >
            <option value="" key={field.label}>
              {field.placeholder}
            </option>
            {field.opts.map((object, index) => {
              return (
                <option key={`${object.value}-${index}`} value={object.value}>
                  {object.name}
                </option>
              );
            })}
          </select>
          <div className="text-sm text-red-500">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  };

  const renderActivity = ({
    fields,
    meta: { touched, error, submitFailed },
  }) => {
    // console.log("fields: ", fields);

    return (
      <ul>
        {fields.map((activity, index) => (
          <li key={index} className={index == 0 ? "mt-4" : "mt-1"}>
            <label className="block text-sm text-secondary-4">
              Activity#{index + 1}
            </label>

            <div className="mt-1 flex">
              <Field
                name={`${activity}.name`}
                label="Activity Name"
                placeholder="Activity"
                component={renderSelect}
                // mandatory={false}
                opts={activityOpts}
                width={108}
              />
              <Field
                name={`${activity}.intensity`}
                label="Activity Intensity"
                placeholder="Intensity"
                component={renderSelect}
                opts={intensityOpts}
                width={112}
                margin="ml-2"
              />
              <Field
                name={`${activity}.duration`}
                label="Activity Duration"
                placeholder="Time"
                component={renderSelect}
                opts={durationOpts}
                width={87}
                margin="ml-2"
              />

              {index ? (
                <img
                  className="ml-2 cursor-pointer"
                  src={minus}
                  title="Remove Activity"
                  onClick={() => fields.remove(index)}
                />
              ) : (
                ""
              )}
            </div>
          </li>
        ))}

        <li className="w-full flex justify-end mt-4 items-center">
          {fields.length > 7 ? (
            <div className="text-red-600 text-sm pr-2">
              You can add maximum 8 activities
            </div>
          ) : (
            ""
          )}
          <div className="pr-8">
            <div
              className={
                fields.length <= 7
                  ? "bg-primary rounded-md p-2 cursor-pointer"
                  : "bg-primary rounded-md p-2 opacity-50 cursor-not-allowed"
              }
              onClick={() => fields.length <= 7 && fields.push({})}
            >
              <img
                // ref={addNewActivityRef}
                src={plus}
              />
            </div>
          </div>
        </li>
        {submitFailed && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </ul>
    );
  };

  const renderDatePickerWithLabel = (field) => {
    // console.log("field :- ", field);
    var {
      input,
      selected,
      disabled,
      label,
      labelposition,
      meta,
      maxDate,
      minDate,
      placeholder,
    } = field;
    // var value = input.value ? moment(input.value) : null;
    // var selectedValue = value ? value : selected;

    var value = input.value
      ? moment(input.value).format(DATE_FORMAT)
      : selected
      ? moment(selected).format(DATE_FORMAT)
      : null;
    var selectedValue = value
      ? moment(value)
      : selected
      ? selected
      : moment(moment().format(DATE_FORMAT));

    // var selectedValue = value
    //   ? parse(value, DATE_FORMAT, new Date())
    //   : selected
    //   ? selected
    //   : new Date();

    // var value = input.value ? moment(input.value).format(DATE_FORMAT) : null;
    // var selectedValue = value ? moment(input.value) : selected;
    // console.log('value :- ', value);
    // console.log('selected :- ', selected);
    // console.log('selectedValue :- ', selectedValue);

    // var divClassName = `form-group row ${
    //   meta.touched && meta.error ? "has-danger" : ""
    // }`;
    // var labelClasses = "control-label-top col-sm-12";
    // var inputClasses = "col-sm-12 col-sm-offset-0";

    // if (labelposition === LABEL_POSITION_LEFT) {
    //   labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    //   inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
    // }

    return (
      <div>
        {label !== "" ? (
          <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
            {label}
            {/* <span className='label-mandatory'>
              {field.mandatory === "true" ? "*" : ""}
            </span> */}
          </label>
        ) : null}

        <div className="">
          <DatePicker
            className={
              disabled
                ? "cursor-not-allowed enter-data-steps-input"
                : "enter-data-steps-input"
            }
            name={input.name}
            placeholderText={placeholder}
            {...input}
            value={value}
            // selected={selectedValue}
            dateFormat={DATE_FORMAT}
            // dateFormat='yyyy-MM-dd'
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
          />
          <div className="text-sm text-red-500">
            {meta.touched ? meta.error : ""}
          </div>
        </div>
      </div>
    );
  };

  const handleChangeSlider = (e, item) => {
    //console.log("e: ", e);
    let value = e.target.value;
    // console.log("value: ", value);
    // console.log("value: ", item.data.min);
    // console.log("value: ", item.data.max);

    let arr = JSON.parse(JSON.stringify(sliderArr));
    // console.log("arr: ", arr);
    let index = _.findIndex(arr, function (o) {
      return o.key == item.name;
    });
    // console.log("index: ", index);
    let color_stop_val =
      (value - item.data.min) / (item.data.max - item.data.min);
    // console.log("arr[index]: ", arr[index]);
    arr[index].colorstopval = color_stop_val;
    arr[index].value = value;

    setSliderArr(arr);
  };

  const onSubmitForm = (values, dispatch) => {
    // console.log("values: ", values);
    //debugger;
    if (!values.date) {
      values.date = moment().format(DATE_FORMAT);
    } else {
      values.date = moment(values.date).format(DATE_FORMAT);
    }

    // console.log(values);
    // debugger;

    if (
      !_.isEmpty(values) &&
      values.steps &&
      values.date
      // values.activity &&
      // values.activity.length > 0 &&
      // values.activity[0].name &&
      // values.activity[0].intensity &&
      // values.activity[0].duration
    ) {
      setLoading(true);

      let activityArr = [];
      if (
        values.activity &&
        values.activity.length > 0 &&
        values.activity[0].name != ""
      ) {
        _.map(values.activity, (item) => {
          if (!_.isEmpty(item)) {
            activityArr.push({
              activityidfk: parseInt(item.name),
              intensity:
                parseInt(item.intensity) == 1100
                  ? "high"
                  : parseInt(item.intensity) == 1020
                  ? "medium"
                  : parseInt(item.intensity) == 1001
                  ? "low"
                  : "",
              activitytime: parseInt(item.duration),
              // activitypoint: Math.round(
              //   (parseInt(item.intensity) / 60) * parseInt(item.duration)
              // ),
              activitypoint: parseFloat(
                (
                  (parseInt(item.intensity) / 60) *
                  parseInt(item.duration)
                ).toFixed(6)
              ),
              entrydate: values.date,
            });
          }
        });
      }
      // console.log("activityArr: ", activityArr);

      let filterValue = _.filter(sliderArr, function (o) {
        return o.key == "water";
      });
      // console.log("filterValue: ", filterValue);
      // console.log("water.data: ", water.data);
      let filter = _.filter(water.data, function (item) {
        return filterValue[0].value == item.quantity;
      });
      // console.log("filter: ", filter);
      let waterPoint = filter[0].points;
      let waterDataLength = water.data.length;
      let waterSelectedValue = filterValue[0].value;
      let waterStartRange = water.data[0].quantity;
      let waterEndRange = water.data[waterDataLength - 1].quantity;

      filterValue = _.filter(sliderArr, function (o) {
        return o.key == "fruit";
      });
      filter = _.filter(fruit.data, function (item) {
        return filterValue[0].value == item.quantity;
      });
      let fruitPoint = filter[0].points;
      let fruitDataLength = fruit.data.length;
      let fruitSelectedValue = filterValue[0].value;
      let fruitStartRange = fruit.data[0].quantity;
      let fruitEndRange = fruit.data[fruitDataLength - 1].quantity;

      filterValue = _.filter(sliderArr, function (o) {
        return o.key == "veges";
      });
      filter = _.filter(veges.data, function (item) {
        return filterValue[0].value == item.quantity;
      });
      let vegesPoint = filter[0].points;
      let vegesDataLength = veges.data.length;
      let vegesSelectedValue = filterValue[0].value;
      let vegesStartRange = veges.data[0].quantity;
      let vegesEndRange = veges.data[vegesDataLength - 1].quantity;

      filterValue = _.filter(sliderArr, function (o) {
        return o.key == "foods";
      });
      filter = _.filter(foods.data, function (item) {
        return filterValue[0].value == item.quantity;
      });
      let foodsPoint = filter[0].points;
      let foodsDataLength = foods.data.length;
      let foodsSelectedValue = filterValue[0].value;
      let foodsStartRange = foods.data[0].quantity;
      let foodsEndRange = foods.data[foodsDataLength - 1].quantity;

      filterValue = _.filter(sliderArr, function (o) {
        return o.key == "sugar";
      });
      filter = _.filter(sugar.data, function (item) {
        return filterValue[0].value == item.quantity;
      });
      let sugarPoint = filter[0].points;
      let sugarDataLength = sugar.data.length;
      let sugarSelectedValue = filterValue[0].value;
      let sugarStartRange = sugar.data[0].quantity;
      let sugarEndRange = sugar.data[sugarDataLength - 1].quantity;

      filterValue = _.filter(sliderArr, function (o) {
        return o.key == "sleep";
      });
      filter = _.filter(sleep.data, function (item) {
        return (
          filterValue[0].value >= item.starttime &&
          filterValue[0].value <= item.endtime
        );
      });
      let sleepPoint = filter[0].points;
      let sleepDataLength = sleep.data.length;
      let sleepSelectedValue = filterValue[0].value;
      let sleepStartRange = sleep.data[0].starttime;
      let sleepEndRange = sleep.data[sleepDataLength - 1].endtime;

      filterValue = _.filter(sliderArr, function (o) {
        return o.key == "screentime";
      });
      //console.log("screentime filterValue", filterValue);
      filter = _.filter(screentime.data, function (item) {
        return (
          filterValue[0].value >= item.starttime &&
          filterValue[0].value <= item.endtime
        );
      });
      //console.log("screentime filter", filter);
      let screentimePoint = filter[0].points;
      let screentimeDataLength = screentime.data.length;
      let screentimeSelectedValue = filterValue[0].value;
      let screentimeStartRange = screentime.data[0].starttime;
      let screentimeEndRange =
        screentime.data[screentimeDataLength - 1].endtime;

      filterValue = _.filter(sliderArr, function (o) {
        return o.key == "mindfulness";
      });
      filter = _.filter(mindfulness.data, function (item) {
        return filterValue[0].value == item.quantity;
      });
      let mindfulnessPoint = filter[0].points;
      let mindfulnessDataLength = mindfulness.data.length;
      let mindfulnessSelectedValue = filterValue[0].value;
      let mindfulnessStartRange = mindfulness.data[0].quantity;
      let mindfulnessEndRange =
        mindfulness.data[mindfulnessDataLength - 1].quantity;

      let resourceLiveData = [
        {
          memberidfk: session.userid,
          teamidfk: parseInt(homeScreenData.data.teamid),
          steps: parseInt(values.steps),

          sleep: sleepPoint,
          sleepstartrange: sleepStartRange,
          sleependrange: sleepEndRange,
          sleepselectedvalue: sleepSelectedValue,

          screentime: screentimePoint,
          screentimestartrange: screentimeStartRange,
          screentimeendrange: screentimeEndRange,
          screentimeselectedvalue: screentimeSelectedValue,

          water: waterPoint,
          waterstartrange: waterStartRange,
          waterendrange: waterEndRange,
          waterselectedvalue: waterSelectedValue,

          fruit: fruitPoint,
          fruitstartrange: fruitStartRange,
          fruitendrange: fruitEndRange,
          fruitselectedvalue: fruitSelectedValue,

          veges: vegesPoint,
          vegesstartrange: vegesStartRange,
          vegesendrange: vegesEndRange,
          vegesselectedvalue: vegesSelectedValue,

          foods: foodsPoint,
          foodsstartrange: foodsStartRange,
          foodsendrange: foodsEndRange,
          foodsselectedvalue: foodsSelectedValue,

          sugar: sugarPoint,
          sugarstartrange: sugarStartRange,
          sugarendrange: sugarEndRange,
          sugarselectedvalue: sugarSelectedValue,

          mindfulness: mindfulnessPoint,
          mindfulnessstartrange: mindfulnessStartRange,
          mindfulnessendrange: mindfulnessEndRange,
          mindfulnessselectedvalue: mindfulnessSelectedValue,

          entrydate: values.date,
          enteredby: "self",
        },
      ];

      //console.log("resourceLiveData: ", resourceLiveData);
      //debugger;
      let teamid = parseInt(homeScreenData.data.teamid);
      let teampoints = parseInt(homeScreenData.data.teampoints);
      let teamsteps = parseInt(homeScreenData.data.teamsteps);
      let totalactivitypoints = 0;
      if (activityArr.length > 0) {
        _.map(activityArr, (item) => {
          totalactivitypoints += item.activitypoint;
        });
      }
      let totalbonuspoints =
        sleepPoint +
        screentimePoint +
        waterPoint +
        fruitPoint +
        vegesPoint +
        foodsPoint +
        sugarPoint +
        mindfulnessPoint;

      // console.log("totalactivitypoints: ", totalactivitypoints);
      // console.log("totalbonuspoints: ", totalbonuspoints);
      // console.log("teampoints: ", teampoints);
      // console.log("teamsteps: ", teamsteps);

      let totalPoints =
        parseInt(values.steps) + totalactivitypoints + totalbonuspoints;
      //console.log("totalPoints: ", totalPoints);

      let dailymaxindividualopen =
        eventDetails?.resource?.dailymaxindividualopen;

      let teamavgpttofinishalllegsopen =
        eventDetails?.resource?.teamavgpttofinishalllegsopen;

      if (totalPoints > dailymaxindividualopen) {
        setLoading(false);

        let content = `You are exceeding total points by ${parseFloat(
          totalPoints - dailymaxindividualopen
        ).toFixed(3)} point(s).`;
        addToast(content, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        if (showModal.data?.type == "mydata") {
          fetchDataEntry(
            session,
            homeScreenData.data.teamid,
            "mydata",
            values.date,
            (resDataEntry) => {
              console.log("resDataEntry: ", resDataEntry);

              if (resDataEntry.success) {
                if (resDataEntry.data.length > 0) {
                  let content =
                    "Data is already entered on this day for this user";
                  addToast(content, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                  setLoading(false);
                  closeModal();
                }
                //
                else {
                  let requestData = [
                    {
                      ...resourceLiveData[0],
                      memberliveactivity_by_memberlivedataidfk: activityArr,
                    },
                  ];

                  addMemberLiveData(session, requestData, (res) => {
                    //console.log("res: ", res);

                    if (res.success) {
                      let teampoints_mydata =
                        teampoints +
                        parseInt(values.steps) +
                        totalactivitypoints +
                        totalbonuspoints;
                      let teamsteps_mydata = teamsteps + parseInt(values.steps);

                      // console.log("eventDetails: ", eventDetails);

                      let nextLeg = false;
                      let nextLegId = "";

                      let eachLegReqPt = 0;
                      if (homeScreenData.data.currentdivision == "open") {
                        eachLegReqPt =
                          eventDetails.resource
                            ?.teamavgptreqtofinisheachlegopen;
                        if (
                          teampoints_mydata >
                          parseInt(homeScreenData.data?.legno) *
                            eventDetails.resource
                              ?.teamavgptreqtofinisheachlegopen
                        ) {
                          nextLeg = true;
                        }
                      } else if (
                        homeScreenData.data.currentdivision == "advance"
                      ) {
                        eachLegReqPt =
                          eventDetails.resource?.teamavgptreqtofinisheachlegadv;
                        if (
                          teampoints_mydata >
                          parseInt(homeScreenData.data?.legno) *
                            eventDetails.resource
                              ?.teamavgptreqtofinisheachlegadv
                        ) {
                          nextLeg = true;
                        }
                      }

                      if (nextLeg) {
                        let teammembersforteamdataentry = JSON.parse(
                          JSON.stringify(homeScreenData.data.allteammemberdata)
                        );
                        let teamavg =
                          teampoints_mydata /
                          teammembersforteamdataentry.length;

                        let order = Math.ceil(teamavg / eachLegReqPt);

                        let filterNextLeg = _.filter(eventClientLegsList.data, {
                          order: order,
                        });

                        if (filterNextLeg && filterNextLeg.length > 0) {
                          nextLegId = filterNextLeg[0].id;
                        }
                      }

                      let teamResourceObj =
                        nextLeg && nextLegId
                          ? {
                              teampoints: teampoints_mydata,
                              teamsteps: teamsteps_mydata,
                              currentlegidfk: nextLegId,
                            }
                          : {
                              teampoints: teampoints_mydata,
                              teamsteps: teamsteps_mydata,
                            };

                      updateTeamData(
                        session,
                        teamid,
                        teamResourceObj,
                        (res2) => {
                          //console.log("res2: ", res2);
                          if (res2.success) {
                            fetchMyDataList(session, teamid, (res) => {});
                            fetchHomeScreenData(session, (res) => {});
                            setLoading(false);

                            let content = "Data added successfully.";
                            addToast(content, {
                              appearance: "success",
                              autoDismiss: true,
                            });

                            closeModal();
                            if (
                              values.actionaftersave &&
                              values.actionaftersave == "home"
                            ) {
                              history.push("/");
                            }
                          } else {
                            let content = "Something went wrong.";
                            addToast(content, {
                              appearance: "error",
                              autoDismiss: true,
                            });

                            closeModal();
                          }
                        }
                      );
                    } else {
                      let content = "Something went wrong.";
                      addToast(content, {
                        appearance: "error",
                        autoDismiss: true,
                      });
                    }
                  });
                }
              }
              //
              else {
                let content = "Something went wrong.";
                addToast(content, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            }
          );
        } else {
          // Class Entry

          fetchDataEntry(
            session,
            homeScreenData.data.teamid,
            "classdata",
            values.date,
            (resDataEntry) => {
              console.log("resDataEntry: ", resDataEntry);

              if (resDataEntry.success) {
                if (
                  resDataEntry.data.length ==
                  homeScreenData.data.allteammemberdata.length
                ) {
                  let content =
                    "Data is already entered on this day for all team members";
                  addToast(content, {
                    appearance: "error",
                    autoDismiss: true,
                  });

                  setLoading(false);
                  closeModal();
                }
                //
                else {
                  let teammembersforteamdataentry = [];

                  if (resDataEntry.data.length == 0) {
                    teammembersforteamdataentry = JSON.parse(
                      JSON.stringify(homeScreenData.data.allteammemberdata)
                    );
                  } else {
                    _.map(homeScreenData.data.allteammemberdata, (item) => {
                      // let filter = _.filter(resDataEntry.data, {
                      //   memberidfk: parseInt(item.id),
                      // });
                      let filter = _.filter(resDataEntry.data, function (o) {
                        return o.memberidfk == item.id;
                      });
                      if (filter.length == 0) {
                        teammembersforteamdataentry.push(item);
                      }
                    });
                  }
                  console.log(
                    "teammembersforteamdataentry: ",
                    teammembersforteamdataentry
                  );

                  let resourceLiveDataArr = [];
                  _.map(teammembersforteamdataentry, (item) => {
                    resourceLiveDataArr.push({
                      memberidfk: parseInt(item.id),
                      teamidfk: parseInt(homeScreenData.data.teamid),
                      steps: parseInt(values.steps),

                      sleep: sleepPoint,
                      sleepstartrange: sleepStartRange,
                      sleependrange: sleepEndRange,
                      sleepselectedvalue: sleepSelectedValue,

                      screentime: screentimePoint,
                      screentimestartrange: screentimeStartRange,
                      screentimeendrange: screentimeEndRange,
                      screentimeselectedvalue: screentimeSelectedValue,

                      water: waterPoint,
                      waterstartrange: waterStartRange,
                      waterendrange: waterEndRange,
                      waterselectedvalue: waterSelectedValue,

                      fruit: fruitPoint,
                      fruitstartrange: fruitStartRange,
                      fruitendrange: fruitEndRange,
                      fruitselectedvalue: fruitSelectedValue,

                      veges: vegesPoint,
                      vegesstartrange: vegesStartRange,
                      vegesendrange: vegesEndRange,
                      vegesselectedvalue: vegesSelectedValue,

                      foods: foodsPoint,
                      foodsstartrange: foodsStartRange,
                      foodsendrange: foodsEndRange,
                      foodsselectedvalue: foodsSelectedValue,

                      sugar: sugarPoint,
                      sugarstartrange: sugarStartRange,
                      sugarendrange: sugarEndRange,
                      sugarselectedvalue: sugarSelectedValue,

                      mindfulness: mindfulnessPoint,
                      mindfulnessstartrange: mindfulnessStartRange,
                      mindfulnessendrange: mindfulnessEndRange,
                      mindfulnessselectedvalue: mindfulnessSelectedValue,

                      entrydate: values.date,
                      enteredby:
                        parseInt(item.ismanager) == 1 ? "self" : "manager",
                      memberliveactivity_by_memberlivedataidfk: activityArr,
                    });
                  });
                  //console.log("resourceLiveDataArr: ", resourceLiveDataArr);

                  let requestData = resourceLiveDataArr;
                  //console.log("requestData: ", requestData);
                  // console.log(
                  //   "teamLength: ",
                  //   teammembersforteamdataentry.length
                  // );
                  //debugger;
                  addMemberLiveData(session, requestData, (res) => {
                    if (res.success) {
                      let teamsteps_classdata =
                        teamsteps +
                        parseInt(values.steps) * resourceLiveDataArr.length;
                      let teampoints_classdata =
                        teampoints +
                        parseFloat(
                          (
                            (parseInt(values.steps) +
                              totalactivitypoints +
                              totalbonuspoints) *
                            resourceLiveDataArr.length
                          ).toFixed(6)
                        );
                      let nextLeg = false;
                      let nextLegId = "";
                      let eachLegReqPt = 0;
                      if (homeScreenData.data.currentdivision == "open") {
                        eachLegReqPt =
                          eventDetails.resource
                            ?.teamavgptreqtofinisheachlegopen;
                        if (
                          teampoints_classdata >
                          parseInt(homeScreenData.data?.legno) *
                            eventDetails.resource
                              ?.teamavgptreqtofinisheachlegopen
                        ) {
                          nextLeg = true;
                        }
                      } else if (
                        homeScreenData.data.currentdivision == "advance"
                      ) {
                        eachLegReqPt =
                          eventDetails.resource?.teamavgptreqtofinisheachlegadv;
                        if (
                          teampoints_classdata >
                          parseInt(homeScreenData.data?.legno) *
                            eventDetails.resource
                              ?.teamavgptreqtofinisheachlegadv
                        ) {
                          nextLeg = true;
                        }
                      }
                      if (nextLeg) {
                        let teamavg =
                          teampoints_classdata /
                          teammembersforteamdataentry.length;

                        let order = Math.ceil(teamavg / eachLegReqPt);

                        let filterNextLeg = _.filter(eventClientLegsList.data, {
                          order: order,
                        });
                        if (filterNextLeg && filterNextLeg.length > 0) {
                          nextLegId = filterNextLeg[0].id;
                        }
                      }
                      let teamResourceObj =
                        nextLeg && nextLegId
                          ? {
                              teampoints: teampoints_classdata,
                              teamsteps: teamsteps_classdata,
                              currentlegidfk: nextLegId,
                            }
                          : {
                              teampoints: teampoints_classdata,
                              teamsteps: teamsteps_classdata,
                            };

                      updateTeamData(
                        session,
                        teamid,
                        teamResourceObj,
                        (res2) => {
                          console.log("res2: ", res2);
                          if (res2.success) {
                            fetchHomeScreenData(session, (res) => {});
                            fetchClassDataList(
                              session,
                              homeScreenData.data.teamid,
                              (res) => {}
                            );
                            setLoading(false);
                            let content = "Data added successfully.";
                            addToast(content, {
                              appearance: "success",
                              autoDismiss: true,
                            });
                            closeModal();

                            if (
                              values.actionaftersave &&
                              values.actionaftersave == "home"
                            ) {
                              history.push("/");
                            }
                          } else {
                            let content = "Something went wrong.";
                            addToast(content, {
                              appearance: "error",
                              autoDismiss: true,
                            });
                            closeModal();
                          }
                        }
                      );
                    } else {
                      let content = "Something went wrong.";
                      addToast(content, {
                        appearance: "error",
                        autoDismiss: true,
                      });
                      closeModal();
                    }
                  });
                }
              } else {
                let content = "Something went wrong.";
                addToast(content, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            }
          );
        }
      }
    }
    //
    else {
      let content = "Please provide required fields.";
      addToast(content, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const submitHome = (key) => {
    //console.log("key: ", key);
    props.autofill("actionaftersave", key);
  };

  //console.log("eventDetails: ", eventDetails);
  //console.log("homescreen: ", homeScreenData);
  //console.log("screentime: ", screentime);
  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <div className="flex justify-start">
                    <h3 className="text-base font-medium">
                      {/* {showModal.data?.callfrom == "home"
                        ? showModal.data?.type == "mydata"
                          ? LocaleStrings.data_entry_for_yourself
                          : session.eventtype == "school"
                          ? LocaleStrings.data_entry_for_your_class
                          : LocaleStrings.data_entry_for_your_team
                        : `${showModal.data?.date}, William Saliba`} */}

                      {showModal.data?.callfrom == "home"
                        ? showModal.data?.type == "mydata"
                          ? LocaleStrings.data_entry_for_yourself
                          : session.eventtype == "school"
                          ? LocaleStrings.data_entry_for_your_class
                          : LocaleStrings.data_entry_for_your_team
                        : showModal.data?.callfrom == "dataentry"
                        ? moment(showModal.data?.item?.date).format(
                            "DD MMM YYYY"
                          ) === moment().format("DD MMM YYYY")
                          ? `Today, ${showModal.data?.name}`
                          : `${moment(showModal.data?.item?.date).format(
                              "DD MMM YYYY"
                            )}, ${showModal.data?.name}`
                        : showModal.data?.callfrom == "classentry"
                        ? moment(showModal.data?.item?.date).format(
                            "DD MMM YYYY"
                          ) === moment().format("DD MMM YYYY")
                          ? `Today, ${showModal.data?.name}`
                          : `${moment(showModal.data?.item?.date).format(
                              "DD MMM YYYY"
                            )}, ${showModal.data?.name}`
                        : ""}
                    </h3>
                  </div>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <Field
                    name="actionaftersave"
                    type="text"
                    component={renderHiddenFieldText}
                  />
                  <Field
                    name="date"
                    type="text"
                    component={renderHiddenFieldText}
                  />
                  <div className="enter-data-modal-body relative flex-auto">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2">
                      <div className="sm:border-0 md:border-r">
                        <div className="points-entry-container">
                          <div>{LocaleStrings.activity_points_entry}</div>
                          <div>
                            {/* <img src={info_white} /> */}

                            <Popup
                              trigger={
                                <img
                                  className="cursor-pointer"
                                  src={info_white}
                                />
                              }
                              position="left center"
                              on="hover"
                              style={{ width: "50%" }}
                            >
                              <div>
                                <p className="px-3 pb-2">
                                  Please select the Activity you participated
                                  in, then the Intensity level and Time you did
                                  it for.{" "}
                                </p>
                                <p className="px-3 pb-2">
                                  High Intensity is when you are breathing
                                  heavily and can not carry on a conversation -
                                  you usually only do high intensity for a short
                                  period of time e.g. Sprinting, riding up a
                                  steep hill{" "}
                                </p>
                                <p className="px-3 pb-2">
                                  Medium Intensity is when you are breathing
                                  faster and you heart rate is higher but you
                                  could carry on a conversation. E.g. riding a
                                  bike on the flat, low hills.{" "}
                                </p>
                                <p className="px-3">
                                  Low Intensity is when you are exercising but
                                  not breathing heavily and can talk easily e.g.
                                  going for a walk.
                                </p>
                              </div>
                            </Popup>
                          </div>
                        </div>

                        <div className="p-4">
                          {/* <Field
                            name="date"
                            label="Date"
                            placeholder="Enter Date"
                            component={renderDatePickerWithLabel}
                            // mandatory='false'
                            // selected={moment()}
                            minDate={
                              new Date(
                                `${homeScreenData.data.challengecoursestartdate}`
                              )
                            }
                            maxDate={
                              new Date(
                                `${homeScreenData.data.challengecourseclosedate}`
                              )
                            }
                            disabled={
                              showModal.data?.callfrom == "dataentry" ||
                              showModal.data?.callfrom == "classentry"
                                ? true
                                : false
                            }
                          /> */}

                          <Field
                            name="steps"
                            type="number"
                            component={renderField}
                            label="Steps"
                            placeholder="Enter Steps"
                            min="1"
                            // max="24000"
                          />

                          <FieldArray
                            name="activity"
                            component={renderActivity}
                          />
                        </div>
                      </div>

                      <div>
                        <div className="points-entry-container">
                          <div>{LocaleStrings.bonus_points_entry}</div>
                          <div>
                            {/* <img src={info_white} /> */}
                            <Popup
                              trigger={
                                <img
                                  className="cursor-pointer"
                                  src={info_white}
                                />
                              }
                              position="left center"
                              on="hover"
                              style={{ width: 300 }}
                            >
                              <div className="w-full">
                                <p className="pb-1">
                                  <b>Fruit:</b> Select fruit eaten. E.g. If you
                                  had 1 apple, 1 banana then drag slider to 2
                                </p>
                                <p className="pb-1">
                                  <b>Water:</b> Select how many measures of
                                  200mls you had e.g. If you drank a litre of
                                  water drag the slider to 5.
                                </p>
                                <p className="pb-1">
                                  <b>Veges:</b> Select vegetables eaten. E.g. If
                                  you had 1 carrot, 1 serving of broccoli then
                                  drag slider to 2{" "}
                                </p>
                                <p className="pb-1">
                                  <b>Snacks:</b> These are things like biscuits,
                                  chocolate, ice cream, chips. Drag the slider
                                  to the total.
                                </p>
                                <p className="pb-1">
                                  <b>Mindfulness:</b> How many times did you
                                  pause in your day to just focus on your
                                  breathing and relax?{" "}
                                </p>
                                <p className="pb-1">
                                  <b>Sleep Time:</b> How long did you sleep for?
                                  Just drag the slider to the closest ½ hour.
                                </p>
                                <p>
                                  <b>Screen Time:</b> This is the time you spent
                                  playing video games, watching TV or Youtube.
                                  Do not count school or study / work screen
                                  time.
                                </p>
                              </div>
                            </Popup>
                          </div>
                        </div>

                        {_.map(bonusPointsArr, (item, i) => {
                          let filter = _.filter(sliderArr, function (o) {
                            return o.key == item.name;
                          });
                          //console.log("filter: ", filter);

                          return (
                            <div className="p-2 border-b flex items-center">
                              <div className="" style={{ width: 21 }}>
                                <img src={item.img} />
                              </div>
                              <div className="ml-4">
                                <div>
                                  <input
                                    className={"slider-" + item.name}
                                    type="range"
                                    min={item.data.min}
                                    max={item.data.max}
                                    step={item.data.step}
                                    defaultValue={item.data.defaultvalue}
                                    // value='1'
                                    onChange={(e) =>
                                      handleChangeSlider(e, item)
                                    }
                                    style={
                                      filter.length > 0 &&
                                      filter[0].colorstopval > 0
                                        ? {
                                            backgroundImage: `-webkit-gradient(linear, left top, right top, color-stop(${filter[0].colorstopval}, ${filter[0].colorstopsecondarycolor}), color-stop(${filter[0].colorstopval}, #e6e8f2))`,
                                          }
                                        : {}
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="ml-4 flex justify-end"
                                style={{ width: 52 }}
                              >
                                <div>
                                  <div className="text-right text-sm font-medium">
                                    {filter.length > 0 ? (
                                      <div className="text-right text-sm font-medium">
                                        {filter[0].key == "screentime"
                                          ? filter[0].value == -1
                                            ? `${filter[0].unit}`
                                            : Math.floor(filter[0].value / 60) +
                                              "h " +
                                              (filter[0].value % 60) +
                                              "m"
                                          : filter[0].value == -1 ||
                                            filter[0].value == 0
                                          ? `${filter[0].unit}`
                                          : `${filter[0].value} ${filter[0].unit}`}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="text-right"
                                    style={{ fontSize: 8 }}
                                  >
                                    {item.unit}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <div className="mt-1 p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                          <div>
                            <button
                              type="submit"
                              className="w-full bg-shades-of-green flex items-center justify-center text-sm text-black font-medium rounded-lg p-4"
                              style={{ height: 37 }}
                              // disabled={submitting}
                              onClick={() => submitHome("home")}
                            >
                              {loading ? (
                                <svg
                                  className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                                  viewBox="0 0 24 24"
                                ></svg>
                              ) : (
                                ""
                              )}
                              Submit & return to Home
                            </button>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="w-full bg-shades-of-cyan flex items-center justify-center text-sm text-black font-medium rounded-lg p-4"
                              style={{ height: 37 }}
                              // disabled={submitting}
                              onClick={() => submitHome("stay")}
                            >
                              {loading ? (
                                <svg
                                  className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                                  viewBox="0 0 24 24"
                                ></svg>
                              ) : (
                                ""
                              )}
                              Submit & enter more data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                {/*footer*/}
                {/* <div className='flex items-center justify-center sm:justify-end p-4 rounded-b border-t'>
                  <button
                    className='bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer'
                    style={{
                      height: 37,
                      width: deviceSize.width >= 414 ? 367 : "100%",
                    }}
                  >
                    <span className='text-base'>Confirm Course</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const validate = (values) => {
  var errors = {};
  //console.log("values", values);
  // if (!values.date || values.date == "") {
  //   errors.date = "Required";
  // }

  if (!values.steps) {
    errors.steps = "Required";
  }

  // if (values.steps && values.steps > 24000) {
  //   errors.steps = `You are exceeding by ${values.steps - 24000} step(s).`;
  // }

  // if (!values.activity || !values.activity.length) {
  //   errors.activity = { _error: "At least one activity must be entered" };
  //   // errors.activity = "At least one activity must be entered";
  // } else {
  //   const activityArrayErrors = [];
  //   // console.log("values.activity: ", values.activity);

  //   values.activity.forEach((act, actIndex) => {
  //     const activityErrors = {};
  //     // console.log("act: ", act);

  //     if (!act || !act.name) {
  //       activityErrors.name = "Required";
  //       activityArrayErrors[actIndex] = activityErrors;
  //     }
  //     if (!act || !act.intensity) {
  //       activityErrors.intensity = "Required";
  //       activityArrayErrors[actIndex] = activityErrors;
  //     }
  //     if (!act || !act.duration) {
  //       activityErrors.duration = "Required";
  //       activityArrayErrors[actIndex] = activityErrors;
  //     }
  //     return activityErrors;
  //   });
  //   if (activityArrayErrors.length) {
  //     errors.activity = activityArrayErrors;
  //   }
  // }

  // console.log("errors: ", errors);
  return errors;
};

const mapStateToProps = (state) => {
  // console.log("activityListReducer: ", state.activityListReducer);
  // console.log("eventDetails: ", state.eventDetails);
  // console.log("showEnterDataModalReducer: ", state.showEnterDataModalReducer);

  var initial = {
    date: moment().format(DATE_FORMAT),
    activity: [{ name: "", intensity: "", duration: "" }],
  };
  //console.log("initial: ", initial);
  initial.date =
    state.showEnterDataModalReducer.data?.callfrom == "dataentry" ||
    state.showEnterDataModalReducer.data?.callfrom == "classentry"
      ? state.showEnterDataModalReducer.data &&
        state.showEnterDataModalReducer.data.item &&
        state.showEnterDataModalReducer.data.item.date
        ? moment(state.showEnterDataModalReducer.data.item.date).format(
            DATE_FORMAT
          )
        : moment().format(DATE_FORMAT)
      : moment().format(DATE_FORMAT);

  return {
    deviceSize: state.deviceSize,
    showModal: state.showEnterDataModalReducer,
    session: state.session,
    activityList: state.activityListReducer,
    bonusPointsList: state.bonusPointsListReducer,
    homeScreenData: state.homeScreenDataReducer,
    initialValues: initial,
    eventDetails: state.eventDetails,
    eventClientLegsList: state.eventClientLegsListReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showEnterDataModal,
      fetchActivityList,
      fetchBonusPointsList,
      addMemberLiveData,
      addMemberLiveActivity,
      fetchHomeScreenData,
      updateTeamData,
      fetchDataEntry,
      fetchMyDataList,
      fetchEventClientLegs,
      fetchClassDataList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "DataEntryForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EnterData)
);
