/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import { login } from "../../actions";
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../components/Common/constant";
// import { validateEmail } from "../../components/Common/base-components";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const Login = (props) => {
  const { deviceSize, login, session, eventDetails } = props;

  const { addToast } = useToasts();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginBg, setLoginBg] = useState([]);
  const [currentBgInd, setCurrentBgInd] = useState(0);

  let loginbgimgArr = [];
  let src = "";
  if (!_.isEmpty(eventDetails.resource)) {
    if (eventDetails.resource.loginbgimage) {
      src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }

    if (eventDetails.resource.loginbgimage1) {
      src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage1}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }

    if (eventDetails.resource.loginbgimage2) {
      src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage2}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }

    if (eventDetails.resource.loginbgimage3) {
      src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage3}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }

    if (eventDetails.resource.loginbgimage4) {
      src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage4}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }
  }

  useEffect(() => {
    // let loginbgimgArr = [];
    // let src = "";
    // if (eventDetails?.resource?.bgtype === "image") {
    //   if (eventDetails.resource.loginbgimage) {
    //     src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   if (eventDetails.resource.loginbgimage1) {
    //     src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage1}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   if (eventDetails.resource.loginbgimage2) {
    //     src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage2}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   if (eventDetails.resource.loginbgimage3) {
    //     src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage3}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   if (eventDetails.resource.loginbgimage4) {
    //     src = `${BASE_AZIONV2_URL}${eventDetails.resource.loginbgimage4}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   setLoginBg(loginbgimgArr);
    // }

    // let interval = setInterval(() => changeBackgroundImage(), 1000);

    // return () => {
    //   if (interval) {
    //     clearInterval(interval);
    //   }
    // };

    setTimeout(() => {
      let newCurrentInd = 0;
      const noOfImages = loginbgimgArr.length;
      if (currentBgInd !== noOfImages - 1) {
        newCurrentInd = currentBgInd + 1;
      }
      setCurrentBgInd(newCurrentInd);
    }, 10000);
  });

  // const changeBackgroundImage = () => {
  //   let newCurrentInd = 0;
  //   const noOfImages = loginBg.length;

  //   if (currentBgInd !== noOfImages - 1) {
  //     newCurrentInd = currentBgInd + 1;
  //   }

  //   setCurrentBgInd(newCurrentInd);
  // };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let values = {};
    values.email = email;
    values.password = password;

    if (!validateEmail(email)) {
      values.email = email + "@teamchallenge.com";
    }

    login(session, values, (response) => {
      setLoading(false);
      console.log("response :- ", response);

      if (response.success === 1) {
        let content = "Logged in successful.";
        addToast(content, {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/");
      } else if (response.success === 2) {
        let content = "Inactive profile.";
        addToast(content, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        let content = "Email/Password is incorrect.";
        addToast(content, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  // console.log("loginBg: ", loginBg);
  // console.log("currentBgInd: ", currentBgInd);
  // console.log("loginbgimgArr: ", loginbgimgArr);
  // console.log(
  //   "loginbgimgArr[currentBgInd].src: ",
  //   loginbgimgArr[currentBgInd].src
  // );

  return (
    <div className="p-2">
      <div
        className="bg-cover p-4 flex items-center justify-center login-bg-image-container"
        style={{
          height: 657,
          borderRadius: 13,
          // backgroundImage: `url(${loginBg[currentBgInd]?.src})`,
          backgroundImage:
            loginbgimgArr.length > 0
              ? `url("${loginbgimgArr[currentBgInd].src}")`
              : "",
        }}
      >
        <div className="bg-white rounded-lg" style={{ width: 350 }}>
          <div className="border-b px-4 pt-6">
            <div>
              <div className="text-xl-1 text-black font-semibold">Login</div>
              <div className="text-sm text-secondary-2 pt-1">
                Enter Your Email & Password to Login
              </div>
            </div>

            <div className="mt-6.5">
              <form onSubmit={handleSubmit}>
                <div>
                  <label className="text-sm text-primary-black">
                    Username / Email Address
                  </label>
                  <input
                    className="input-text-login mt-1"
                    style={{ borderRadius: 6 }}
                    id="email"
                    type="text"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={handleChangeEmail}
                    required
                  />
                  {/* <p className='text-red-500 text-xs'>Invalid Email.</p> */}
                </div>
                <div className="mt-1">
                  <label className="text-sm text-primary-black">Password</label>
                  <input
                    className="input-text-login mt-1"
                    style={{ borderRadius: 6, height: 47 }}
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={handleChangePassword}
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn my-4 bg-refresh text-white h-10"
                    style={{ borderRadius: 6, height: 47 }}
                  >
                    {loading ? (
                      <svg
                        // className='animate-spin h-5 w-5 rounded-full border-t-2 border-b-2 border-white mr-3'
                        className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                        viewBox="0 0 24 24"
                      ></svg>
                    ) : (
                      ""
                    )}
                    <span className="text-sm">Login</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            className="p-4 text-primary text-sm flex justify-center items-center cursor-pointer"
            onClick={() => history.push("/forgot-password")}
          >
            Recover Password
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
