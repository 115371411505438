import moment from "moment";
import LocalStrings from "../../languages";

export var BASE_URL = "https://api.tc.teks.co.in/";

export var BASE_FILES_URL = `${BASE_URL}api/v2/files/`;
export var BASE_AZIONV2_URL = `${BASE_URL}api/v2/azionv2/`;

export var APP_API_KEY =
  "f9ff29a2c43c7f0598b50de6ca8cef7abdf65c4b8f20d5ea442eb4703831baee";
export var FILE_ACCESS_KEY =
  "bcc63b14eb9bb09f0dfc6462f172eeb849f841d1d70efffac510e6c217fa830a";
export var OPEN_ACCESS =
  "6a8acaee64a69d4503cceaf0c6e86fb6c79e5515709bd4883f977ed2159bf115";

export var COMMON_FAIL_MESSAGE = LocalStrings.common_fail_message;
export var CUSTOM_ITEM_COUNT = 10;
export var LABEL_POSITION_TOP = "TOP";
export var LABEL_POSITION_LEFT = "LEFT";
export var DATE_FORMAT = "YYYY-MM-DD";
export var DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export var DATE_TIME_FORMAT_WITH_SECOND = "YYYY-MM-DD HH:mm:ss";
export var LOCAL_TIMEZONE = localTimezone();
export var LOCAL_TO_GMT_TIME = converLocalDateIntoGMT();
export var DEFAULT_PASSWORD = "123456";

function localTimezone() {
  // const tz = momentz.tz.guess();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
}

function converLocalDateIntoGMT() {
  var today = new Date();
  var str = today.toGMTString();
  var datetimeGMT = moment(str, "ddd, DD MMM YYYY HH:mm:ss"); // Parse string in local time
  var datetime = datetimeGMT.format("YYYY-MM-DD HH:mm:ss");

  return datetime;
}

export function converDateIntoLocal(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local();
  // .format('YYYY-MM-DD HH:mm:ss');
  return local;
}
