import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const regFormClassThree = (props) => {
  const {
    deviceSize,
    session,
    eventDetails,
    handleSubmit,
    pristine,
    reset,
    submitting,
  } = props;

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center mb-3">
              <h2 className="text-5xl pb-3 pt-3 mb-3">
                Teachers Register Your Class
              </h2>

              <span className="p-6 text-center">
                Pedometers are a fun and motivating tool for tamariki to use
                during this program, but are not required if you do not want
                them. With points being earned for activity time, eating well,
                drinking plenty of water, sleeping well and more, the pedometer
                is not an essential part of the program. So you have the choice
                of whether you would like all students to have one, some to
                share or none at all. We have selected pedometers with a battery
                life that is at least a year and can be replaced so they can be
                used year after year, if taken good care of.
              </span>
            </div>
            <div
              className="bg-secondary-2 py-2 rounded-lg"
              style={{ height: 300 }}
            >
              <form onSubmit={handleSubmit}>
                <div className="p-12">
                  <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                    Please select your preferred option for pedometers
                  </label>
                  <select
                    name="gender"
                    className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 text-gray-700 rounded-md"
                  >
                    <option>-- select --</option>
                    <option value="option1">
                      I want pedometers for all my students
                    </option>
                    <option value="option2">
                      I want some pedometers that my studets can share
                    </option>
                    <option value="option3">
                      I don't want any pedometers for my class at all
                    </option>
                  </select>
                </div>

                {/* form submit */}
                <div className="flex justify-center m-6">
                  <button
                    type="submit"
                    disabled={pristine || submitting}
                    className="mt-6 btn bg-refresh text-white rounded-lg h-9"
                    style={{ width: 200 }}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(regFormClassThree);
