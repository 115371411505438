/* eslint-disable no-unused-vars */
import axios from "axios";
import _ from "lodash";

import { BASE_URL, APP_API_KEY } from "../components/Common/constant";
import { SAVE_AVATAR_OPTION_DATA } from "../pages/avatarBuilder/actions";

import { getRequest } from "../components/Common/network-call";
import { getHeaders } from "../components/Common/common-utils";

export const DEVICE_SIZE = "DEVICE_SIZE";
export const SESSION_TOKEN = "SESSION_TOKEN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const EVENT = "EVENT";
export const EVENT_DETAILS = "EVENT_DETAILS";
export const LOGIN_USER_DETAILS = "LOGIN_USER_DETAILS";

export function deviceSize(obj) {
  return (dispatch) => {
    dispatch({ type: DEVICE_SIZE, payload: obj });
  };
}

export function checkEvent(session, subdomain, callback) {
  // var subdomain = "junglesafari";
  //var subdomain = "myschooltekschallenge";
  // var subdomain = "schools2021";
  var filter = encodeURI(`subdomain=${subdomain}`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/event?filter=(${filter})`;

  return (dispatch) => {
    if (
      session.subdomain &&
      session.subdomain !== "" &&
      session.subdomain.toLowerCase() !== subdomain.toLowerCase()
    ) {
      dispatch({
        type: EVENT,
        payload: {},
      });
      dispatch(logout(session));
    }

    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        //console.log('data :- ', data);
        if (data.resource && data.resource.length > 0) {
          dispatch({
            type: EVENT,
            payload: data.resource[0],
          });
          dispatch({
            type: EVENT_DETAILS,
            payload: { resource: data.resource[0] },
          });
          callback({ success: 1, data: data.resource[0] });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {}
    );
  };
}

export function login(session, values, callback) {
  var config = {
    headers: {
      "content-type": "application/json",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
    password: values.password,
    type: "email",
    callfrom: "frontend",
    //duration: 0
  };

  var url = `${BASE_URL}api/v2/user/session`;
  var response = axios.post(url, data, config);

  // console.log("response login: ", response);

  return (dispatch) => {
    response
      .then(({ data }) => {
        // console.log("data: ", data); // logging data upon successful login
        // console.log("session: ", session);

        if (data) {
          if (
            session.eventid === data.eventidfk &&
            !data?.isremoved &&
            !data?.withdrawndate
          ) {
            dispatch({ type: SESSION_TOKEN, payload: data });
            dispatch({ type: LOGIN, payload: true });
            dispatch({ type: LOGIN_USER_DETAILS, payload: data });
            callback({ success: 1 });

            // storing avatar data for a loggedin users
            if (data?.avatar) {
              dispatch({
                type: SAVE_AVATAR_OPTION_DATA,
                payload: JSON.parse(data.avatar),
              });
            }
          } else {
            callback({
              success: 2,
              message: "Profile Inactive",
            });
          }
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function logout(session) {
  var url = `${BASE_URL}api/v2/user/session`;
  var response = axios.delete(url, getHeaders(session));

  return (dispatch) => {
    //dispatch({ type: LOGOUT, payload: false });
    response
      .then(({ data }) => {
        dispatch({ type: LOGOUT, payload: false });
      })
      .catch((error) => {
        // nothing to do
        dispatch({ type: LOGOUT, payload: false });
      });
  };
}

export function initiateReset(values, callback) {
  //This function is used To initiate Reset
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
  };

  var url = `${BASE_URL}/api/v2/user/password?reset=true`;
  var response = axios.post(url, data, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, message: "Success" });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}
