import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import timer from "../../../assets/img/5-timer.png";
import LocaleStrings from "../../../languages";
import moment from "moment";

const Timer = (props) => {
  const { data } = props;

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  //   const timerComponents = [];

  // ------ Timer Calculation -------
  const calculateTimeLeft = () => {
    let starttime = data?.challengecoursestartdate;
    let endtime = data?.challengecourseclosedate;

    let currentDate = moment();
    let daysleft = "";
    let daysText = "";
    if (
      currentDate.isAfter(moment(starttime)) &&
      currentDate.isAfter(moment(endtime))
    ) {
      daysText = "Course completed";
    } else if (currentDate.isBetween(moment(starttime), moment(endtime))) {
      daysText = "Course Close in";
      let edate = moment(endtime, "YYYY-MM-DD");
      daysleft = edate.diff(currentDate, "days");
      daysleft += 1; // to include today's date
    } else {
      let sdate = moment(starttime, "YYYY-MM-DD");
      daysleft = sdate.diff(currentDate, "days");
      daysleft += 1; // to include today's date
      daysText = "Challenge Starts in";
    }

    // let daysleft = "";
    // if (new Date() > new Date(`${starttime}`)) {
    //   console.log("Date is greater");
    //   starttime = new Date();
    // } else {
    //   let sdate = moment(starttime, "YYYY-MM-DD");
    //   let edate = moment(endtime, "YYYY-MM-DD");
    //   let daysleft = edate.diff(sdate, "days");
    //   daysleft += 1; // to include today's date
    // }

    console.log("daysleft: ", daysleft);
    let obj = { daysleft: daysleft, daysText: daysText };
    // return timeLeft;
    return obj;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  //   Object.keys(timeLeft).forEach((interval) => {
  //     if (!timeLeft[interval]) {
  //       return;
  //     }
  //     timerComponents.push(
  //       <span>
  //         {timeLeft[interval]} {interval}{" "}
  //       </span>
  //     );
  //   });
  //--------- timer calculation -------

  //   console.log("timeLeft: ", timeLeft);

  // var admission = moment("{2021-09-15}", "YYYY-MM-DD");
  // var discharge = moment("{2021-09-24}", "YYYY-MM-DD");
  // console.log(discharge.diff(admission, "days"));

  return (
    <>
      <div
        className="h-12 rounded-b-lg p-4 flex justify-between"
        style={{ backgroundColor: "white" }}
      >
        <div className="inline-flex items-center">
          <div>
            <img src={timer} />
          </div>
          <div className="text-sm ml-2">{timeLeft.daysText}</div>
        </div>
        {/* <div className='text-sm'>12d : 30h : 24m : 53s</div> */}
        <div className="text-sm">
          {/* {timeLeft.days > 1 ? `${timeLeft.days} days` : `${timeLeft.days} day`} */}
          {timeLeft.daysleft
            ? parseInt(timeLeft.daysleft) > 1
              ? `${timeLeft.daysleft} days`
              : `${timeLeft.daysleft} day`
            : ""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
