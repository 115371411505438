import {
  FETCH_HOME_SCREEN_DATA,
  SHOW_MEMBER_MODAL,
  SET_HOME_DATA,
  SHOW_LEG_VIDEO_MODAL,
} from "../actions";

export function homeScreenDataReducer(state = {}, action) {
  if (action.type === FETCH_HOME_SCREEN_DATA) {
    return action.payload;
  }
  return state;
}

export function showMemberModalReducer(state = {}, action) {
  if (action.type === SHOW_MEMBER_MODAL) {
    return action.payload;
  }
  return state;
}

export function homeData(state = {}, action) {
  if (action.type === SET_HOME_DATA) {
    return action.payload;
  }
  return state;
}

export function showLegVideo(state = {}, action) {
  if (action.type === SHOW_LEG_VIDEO_MODAL) {
    return action.payload;
  }
  return state;
}
