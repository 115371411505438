import axios from "axios";
import _ from "lodash";
import moment from "moment";

import {
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";

import { BASE_URL, LOCAL_TIMEZONE } from "../../../components/Common/constant";
import { parse } from "@babel/core";

export const SHOW_ENTER_DATA_MODAL = "SHOW_ENTER_DATA_MODAL";
export const SHOW_VIEW_DATA_MODAL = "SHOW_VIEW_DATA_MODAL";
export const ACTIVITY_LIST = "ACTIVITY_LIST";
export const BONUSPOINTS_LIST = "BONUSPOINTS_LIST";
export const ENTERED_DATA_LIST = "ENTERED_DATA_LIST";
export const FETCH_MY_DATA = "FETCH_MY_DATA";
export const FETCH_MY_CHART_DATA = "FETCH_MY_CHART_DATA";
export const EVENTCLIENTLEGS_LIST = "EVENTCLIENTLEGS_LIST";
export const FETCH_CLASS_DATA = "FETCH_CLASS_DATA";

export function showEnterDataModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_ENTER_DATA_MODAL, payload: obj });
  };
}

export function showViewDataModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_VIEW_DATA_MODAL, payload: obj });
  };
}

export function fetchActivityList(session, callback) {
  var filter = encodeURI(`(isdeleted=0) and (eventtype=${session.eventtype})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/activitylist?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ACTIVITY_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchBonusPointsList(session, callback) {
  var filter = encodeURI(`(eventtype=${session.eventtype})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/bonuspoints?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: BONUSPOINTS_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export const addMemberLiveData = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/memberlivedata`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response.resource });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export const addMemberLiveActivity = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/memberliveactivity`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response.resource });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function updateTeamData(session, id, resource, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function fetchDataEntry(session, teamid, type, date, callback) {
  if (type == "mydata") {
    var filter = encodeURI(
      `(memberidfk=${session.userid}) and (teamidfk=${teamid}) and (entrydate=${date})`
    );
  } else {
    var filter = encodeURI(`(teamidfk=${teamid}) and (entrydate=${date})`);
  }

  var url = `${BASE_URL}api/v2/teamchallenge/_table/memberlivedata?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ENTERED_DATA_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchMyDataList(session, teamid, callback) {
  var url = `${BASE_URL}api/v2/custom_script?type=fetchMyData&eventid=${session.eventid}&userid=${session.userid}&teamid=${teamid}&timezone=${LOCAL_TIMEZONE}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("fetchMyData res: ", response);

        dispatch({
          type: FETCH_MY_DATA,
          payload: { data: response.dataentry },
        });
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: [] });
      }
    );
  };
}

const numberCheck = (num) => {
  if (num == -1) {
    return 0;
  }
  return num;
};

export const groupBy = (data, keys) => {
  return Object.values(
    data.reduce((acc, val) => {
      const date = val.entrydate;
      var arr = [],
        total_activity = 0;
      
      val.memberliveactivity.length > 0 &&
        val.memberliveactivity.map((o) => {
          var obj = {
            name: o.name,
            points: parseInt(o.activitypoint),
            time: o.activitytime,
            intensity: o.intensity,
          };
          arr.push(obj);
          total_activity += parseInt(o.activitypoint);
        });
      
      var screentimehours = moment(
        val.screentimeselectedvalue,
        "minutes"
      ).format("hh:mm"); // string

      var sleepinmin = val.sleepselectedvalue * 60

      if (acc[date]) {
        acc[date].steps.push(parseInt(val.steps));
        acc[date].total_steps += parseInt(val.steps);

        acc[date].total_activity = total_activity;
        acc[date].memberliveactivity = arr;

        acc[date].sleep.push(parseInt(sleepinmin));
        acc[date].total_sleep += parseInt(sleepinmin);
        acc[date].screentime.push(parseInt(val.screentimeselectedvalue));
        acc[date].total_screentime += parseInt(val.screentimeselectedvalue);

        acc[date].fruit.push(numberCheck(parseInt(val.fruitselectedvalue)));
        acc[date].total_fruit += numberCheck(parseInt(val.fruitselectedvalue));
        acc[date].foods.push(numberCheck(parseInt(val.foodsselectedvalue)));
        acc[date].total_foods += numberCheck(parseInt(val.foodsselectedvalue));
        acc[date].water.push(numberCheck(parseInt(val.waterselectedvalue)));
        acc[date].total_water += numberCheck(parseInt(val.waterselectedvalue));
        acc[date].sugar.push(numberCheck(parseInt(val.sugarselectedvalue)));
        acc[date].total_sugar += numberCheck(parseInt(val.sugarselectedvalue));
        acc[date].veges.push(numberCheck(parseInt(val.vegesselectedvalue)));
        acc[date].total_veges += numberCheck(parseInt(val.vegesselectedvalue));

        acc[date].mindfulness.push(numberCheck(parseInt(val.mindfulnessselectedvalue)));
        acc[date].total_mindfulness += numberCheck(parseInt(val.mindfulnessselectedvalue));

        acc[date].totalPoints +=
          parseInt(val.steps) +
          parseInt(val.sleep) +
          parseInt(val.screentime) +
          parseInt(val.fruit) +
          parseInt(val.foods) +
          parseInt(val.water) +
          parseInt(val.mindfulness) +
          parseInt(val.sugar) +
          parseInt(val.veges) +
          total_activity;

        acc[date].moments = val.mindfulnessselectedvalue;
        acc[date].sleep_hour = val.sleepselectedvalue;
        acc[date].screen_hour = screentimehours;
      } else {
        acc[date] = {
          date,
          total_steps: parseInt(val.steps),
          steps: [parseInt(val.steps)],
          total_activity,
          memberliveactivity: arr,

          total_sleep: parseInt(sleepinmin),
          sleep: [parseInt(sleepinmin)],
          total_screentime: parseInt(val.screentimeselectedvalue),
          screentime: [parseInt(val.screentimeselectedvalue)],

          total_fruit: numberCheck(parseInt(val.fruitselectedvalue)),
          fruit: [numberCheck(parseInt(val.fruitselectedvalue))],
          total_foods: numberCheck(parseInt(val.foodsselectedvalue)),
          foods: [numberCheck(parseInt(val.foodsselectedvalue))],
          total_water: numberCheck(parseInt(val.waterselectedvalue)),
          water: [numberCheck(parseInt(val.waterselectedvalue))],
          total_sugar: numberCheck(parseInt(val.sugarselectedvalue)),
          sugar: [numberCheck(parseInt(val.sugarselectedvalue))],
          total_veges: numberCheck(parseInt(val.vegesselectedvalue)),
          veges: [numberCheck(parseInt(val.vegesselectedvalue))],

          total_mindfulness: numberCheck(parseInt(val.mindfulnessselectedvalue)),
          mindfulness: [numberCheck(parseInt(val.mindfulnessselectedvalue))],

          totalPoints:
            parseInt(val.steps) +
            parseInt(val.sleep) +
            parseInt(val.screentime) +
            parseInt(val.fruit) +
            parseInt(val.foods) +
            parseInt(val.water) +
            parseInt(val.mindfulness) +
            parseInt(val.sugar) +
            parseInt(val.veges) +
            total_activity,
          
          moments: val.mindfulnessselectedvalue,
          sleep_hour: val.sleepselectedvalue,
          screen_hour: screentimehours,
        };
      }
      return acc;
    }, {})
  );
};

export const chartWiseData = (
  session,
  startDate,
  endDate,
  chartType,
  chartOption,
  callback
) => {
  var url = `${BASE_URL}api/v2/custom_script?type=mydatachart&eventid=${session.eventid}&userid=${session.userid}&charttype=${chartType}&chartoption=${chartOption}&start_date=${startDate}&end_date=${endDate}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.data && response.data.length > 0) {
          // console.log("response.data: ", response.data);
          var data = groupBy(response.data, ["month", "year"]);
          dispatch({
            type: FETCH_MY_CHART_DATA,
            payload: { data: data },
          });
          callback({ success: 1, data: data });
        }
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
};

export function fetchEventClientLegs(session, callback) {
  var filter = encodeURI(
    `(eventidfk=${session.eventid}) and (eventclientidfk=${session.eventclientid})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclientlegs?filter=(${filter})&order=order%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: EVENTCLIENTLEGS_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchClassDataList(session, teamid, callback) {
  var url = `${BASE_URL}api/v2/custom_script?type=fetchClassData&eventid=${session.eventid}&teamid=${teamid}&timezone=${LOCAL_TIMEZONE}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("fetchMyData res: ", response);

        dispatch({
          type: FETCH_CLASS_DATA,
          payload: { data: response.dataentry },
        });
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: [] });
      }
    );
  };
}

export const resetChartReducer = () => {
  return (dispatch) =>
    dispatch({
      type: FETCH_MY_CHART_DATA,
      payload: { data: {} },
    });
};
