import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";
import { useToasts } from "react-toast-notifications";

import {
  showEditCourseModal,
  addEventClientLegs,
  removeEventClientLegs,
  updateEventClientLegs,
  showEditCourse,
  fetchCourseDetails,
} from "../actions";

import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../../components/Common/constant";

import cross from "../../../assets/img/cross.png";
// import info from "../../../assets/img/info.png";
import info from "../../../assets/img/info.svg";
import australia from "../../../assets/img/australia.png";
import newzeland from "../../../assets/img/newzeland.png";
import peru from "../../../assets/img/peru.png";
import egypt from "../../../assets/img/egypt.png";
import china from "../../../assets/img/china.png";
import canada from "../../../assets/img/canada.png";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const EditCourse = (props) => {
  const {
    showModal,
    showEditCourseModal,
    deviceSize,
    showEditCourseReducer,
    session,
    addEventClientLegs,
    removeEventClientLegs,
    updateEventClientLegs,
    eventClientReducer,
    showEditCourse,
    fetchCourseDetails,
  } = props;

  const { addToast } = useToasts();

  // console.log("showEditCourseReducer: ", showEditCourseReducer);

  const state1 = [];
  _.map(showEditCourseReducer.data?.yourcourse, (item) => {
    state1.push({
      id: `${item.eventlegid}`,
      name: item.masterleg_by_materlegidfk?.name.capitalize(),
      img: `${BASE_AZIONV2_URL}${item.leggallerymain?.masterleggallery_by_leggalleryidfk?.path}?api_key=${FILE_ACCESS_KEY}`,
      materlegid: item.materlegidfk,
      type: item.type,
    });
  });

  const state2 = [];
  _.map(showEditCourseReducer.data?.alternatelegs, (item) => {
    state2.push({
      id: `${item.eventlegid}`,
      name: item.masterleg_by_materlegidfk?.name.capitalize(),
      img: `${BASE_AZIONV2_URL}${item.leggallerymain?.masterleggallery_by_leggalleryidfk?.path}?api_key=${FILE_ACCESS_KEY}`,
      materlegid: item.materlegidfk,
      type: item.type,
    });
  });

  // console.log("state1: ", state1);
  // console.log("state2: ", state2);

  // const [state, setState] = useState([
  //   [
  //     { id: "item-1", name: "Australia", img: australia },
  //     { id: "item-2", name: "Newzland", img: newzeland },
  //     { id: "item-3", name: "Peru", img: peru },
  //     { id: "item-4", name: "Egypt", img: egypt },
  //     { id: "item-5", name: "China", img: china },
  //     { id: "item-6", name: "Canada", img: canada },
  //     { id: "item-7", name: "Australia", img: australia },
  //     { id: "item-8", name: "Newzland", img: newzeland },
  //     { id: "item-9", name: "Peru", img: peru },
  //     { id: "item-10", name: "Egypt", img: egypt },
  //     { id: "item-11", name: "China", img: china },
  //   ],
  //   [
  //     { id: "item-12", name: "Australia", img: australia },
  //     { id: "item-13", name: "Newzland", img: newzeland },
  //     { id: "item-14", name: "Peru", img: peru },
  //     { id: "item-15", name: "Egypt", img: egypt },
  //     { id: "item-16", name: "China", img: china },
  //     { id: "item-17", name: "Canada", img: canada },
  //     { id: "item-18", name: "Australia", img: australia },
  //     { id: "item-19", name: "Newzland", img: newzeland },
  //     { id: "item-20", name: "Peru", img: peru },
  //     { id: "item-21", name: "Egypt", img: egypt },
  //     { id: "item-22", name: "China", img: china },
  //     { id: "item-23", name: "Canada", img: canada },
  //     { id: "item-24", name: "Australia", img: australia },
  //   ],
  // ]);

  const [state, setState] = useState([state1, state2]);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(true);

  // console.log("state: ", state);

  // const [items, setItems] = useState([
  //   { id: "item-1", name: "Australia", img: australia },
  //   { id: "item-2", name: "Newzland", img: newzeland },
  //   { id: "item-3", name: "Peru", img: peru },
  //   { id: "item-4", name: "Egypt", img: egypt },
  //   { id: "item-5", name: "China", img: china },
  //   { id: "item-6", name: "Canada", img: canada },
  //   { id: "item-7", name: "Australia", img: australia },
  //   { id: "item-8", name: "Newzland", img: newzeland },
  //   { id: "item-9", name: "Peru", img: peru },
  //   { id: "item-10", name: "Egypt", img: egypt },
  //   { id: "item-11", name: "China", img: china },
  // ]);

  // const [selected, setSelected] = useState([
  //   { id: "item-1", name: "Australia", img: australia },
  //   { id: "item-2", name: "Newzland", img: newzeland },
  //   { id: "item-3", name: "Peru", img: peru },
  //   { id: "item-4", name: "Egypt", img: egypt },
  //   { id: "item-5", name: "China", img: china },
  //   { id: "item-6", name: "Canada", img: canada },
  //   { id: "item-7", name: "Australia", img: australia },
  //   { id: "item-8", name: "Newzland", img: newzeland },
  //   { id: "item-9", name: "Peru", img: peru },
  //   { id: "item-10", name: "Egypt", img: egypt },
  //   { id: "item-11", name: "China", img: china },
  //   { id: "item-12", name: "Canada", img: canada },
  //   { id: "item-13", name: "Australia", img: australia },
  // ]);

  // const yourCourseArr = [
  //   { name: "Australia", img: australia },
  //   { name: "Newzland", img: newzeland },
  //   { name: "Peru", img: peru },
  //   { name: "Egypt", img: egypt },
  //   { name: "China", img: china },
  //   { name: "Canada", img: canada },
  //   { name: "Australia", img: australia },
  //   { name: "Newzland", img: newzeland },
  //   { name: "Peru", img: peru },
  //   { name: "Egypt", img: egypt },
  //   { name: "China", img: china },
  // ];

  // const alternateLegsArr = [
  //   { name: "Australia", img: australia },
  //   { name: "Newzland", img: newzeland },
  //   { name: "Peru", img: peru },
  //   { name: "Egypt", img: egypt },
  //   { name: "China", img: china },
  //   { name: "Canada", img: canada },
  //   { name: "Australia", img: australia },
  //   { name: "Newzland", img: newzeland },
  //   { name: "Peru", img: peru },
  //   { name: "Egypt", img: egypt },
  //   { name: "China", img: china },
  //   { name: "Canada", img: canada },
  //   { name: "Australia", img: australia },
  // ];

  // const [items, setItems] = useState({
  //   left: [
  //     { id: 1, name: "ben" },
  //     { id: 2, name: "joe" },
  //     { id: 3, name: "jason" },
  //     { id: 4, name: "chris" },
  //     { id: 5, name: "heather" },
  //     { id: 6, name: "Richard" },
  //   ],
  //   right: [
  //     { id: 7, name: "george" },
  //     { id: 8, name: "rupert" },
  //     { id: 9, name: "alice" },
  //     { id: 10, name: "katherine" },
  //     { id: 11, name: "pam" },
  //     { id: 12, name: "katie" },
  //   ],
  // });

  const closeModal = () => {
    showEditCourseModal({ show: false });
  };

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  // const id2List = {
  //   droppable: "items",
  //   droppable2: "selected",
  // };

  // const getList = (id) => this.state[this.id2List[id]];

  // onDragEnd = (result) => {
  //   const { source, destination } = result;

  //   // dropped outside the list
  //   if (!destination) {
  //     return;
  //   }

  //   if (source.droppableId === destination.droppableId) {
  //     const items = reorder(
  //       this.getList(source.droppableId),
  //       source.index,
  //       destination.index
  //     );

  //     let state = { items };

  //     if (source.droppableId === "droppable2") {
  //       state = { selected: items };
  //     }

  //     this.setState(state);
  //   } else {
  //     const result = move(
  //       this.getList(source.droppableId),
  //       this.getList(destination.droppableId),
  //       source,
  //       destination
  //     );

  //     this.setState({
  //       items: result.droppable,
  //       selected: result.droppable2,
  //     });
  //   }
  // };

  const getListStyle = (isDraggingOver, ind) =>
    ind == 0 && state[0]?.length < state[1]?.length
      ? {
          // background: isDraggingOver ? "lightblue" : "white",
          background: isDraggingOver ? "white" : "white",
          height: 100 * state[1].length,
        }
      : ind == 1 && state[1]?.length < state[0]?.length
      ? {
          background: isDraggingOver ? "white" : "white",
          height: 100 * state[0].length,
        }
      : {
          background: isDraggingOver ? "white" : "white",
          maxHeight: 488,
        };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "white",
    background: isDragging ? "white" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /** Moves an item from one list to another list. */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  function onDragEnd(result) {
    console.log("result: ", result);

    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    console.log("sInd: ", sInd);
    console.log("dInd: ", dInd);

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
      setDisableConfirm(false);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      // setState(newState.filter((group) => group.length));
      setState(newState);
      setDisableConfirm(false);
    }
  }

  // function onChange(sourceId, sourceIndex, targetIndex, targetId) {
  //   if (targetId) {
  //     const result = move(
  //       items[sourceId],
  //       items[targetId],
  //       sourceIndex,
  //       targetIndex
  //     );
  //     return setItems({
  //       ...items,
  //       [sourceId]: result[0],
  //       [targetId]: result[1],
  //     });
  //   }

  //   const result = swap(items[sourceId], sourceIndex, targetIndex);
  //   return setItems({
  //     ...items,
  //     [sourceId]: result,
  //   });
  // }

  const handleConfirmCourse = () => {
    setLoadingConfirm(true);
    // console.log("state handleConfirmCourse:", state);

    const yourcourseState = JSON.parse(JSON.stringify(state[0]));

    if (yourcourseState.length) {
      _.map(yourcourseState, (item, i) => {
        item.order = i + 1;
      });
    }
    // console.log("state:", state);
    // console.log("yourcourseState:", yourcourseState);
    // console.log("showEditCourseReducer:", showEditCourseReducer);
    // console.log("eventClientReducer:", eventClientReducer);

    // Added Legs
    let addedLegs = [];
    _.map(yourcourseState, (item) => {
      let filter = _.filter(showEditCourseReducer.data.yourcourse, {
        eventlegid: parseInt(item.id),
      });
      // console.log("filter: ", filter);
      if (filter.length == 0) {
        addedLegs.push({
          eventidfk: session.eventid,
          eventclientidfk: session.eventclientid,
          clientidfk: eventClientReducer.data[0]?.clientidfk,
          eventlegidfk: parseInt(item.id),
          legidfk: item.materlegid,
          type: item.type,
          order: item.order,
        });
      }
    });
    console.log("addedLegs: ", addedLegs);

    // Removed Legs
    let removedLegs = [];
    _.map(showEditCourseReducer.data.yourcourse, (item) => {
      let filter = _.filter(yourcourseState, {
        id: `${item.eventlegid}`,
      });
      // console.log("filter: ", filter);
      if (filter.length == 0) {
        removedLegs.push({
          eventidfk: session.eventid,
          eventclientidfk: session.eventclientid,
          clientidfk: eventClientReducer.data[0]?.clientidfk,
          eventlegidfk: item.eventlegid,
          legidfk: item.materlegidfk,
          type: item.type,
          order: item.order,
        });
      }
    });
    console.log("removedLegs: ", removedLegs);

    //Update Legs
    let updateLegs = [];
    _.map(yourcourseState, (item) => {
      updateLegs.push({
        eventidfk: session.eventid,
        eventclientidfk: session.eventclientid,
        clientidfk: eventClientReducer.data[0]?.clientidfk,
        eventlegidfk: parseInt(item.id),
        legidfk: item.materlegid,
        type: item.type,
        order: item.order,
      });
    });
    console.log("updateLegs: ", updateLegs);

    if (addedLegs.length > 0) {
      let resource = [];
      _.map(addedLegs, (item) => {
        resource.push(item);
      });
      // console.log("resource: ", resource);

      addEventClientLegs(session, resource, (res) => {
        if (res.success) {
          let content = "Leg(s) added successfully.";
          addToast(content, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          let content = "Something went wrong.";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }

    if (removedLegs.length > 0) {
      let arrPromise = [];
      let counter = removedLegs.length;
      let uploadCounter = 0;

      _.map(removedLegs, (item) => {
        // console.log("item removedLegs: ", item);

        arrPromise.push(
          new Promise((resolve, reject) => {
            removeEventClientLegs(session, item, (res) => {
              if (res.success) {
                uploadCounter++;
                resolve();
              } else {
                resolve();
              }
            });
          })
        );
      });

      Promise.all(arrPromise).then(() => {
        if (counter == uploadCounter) {
          let content = "Leg(s) removed successfully.";
          addToast(content, {
            appearance: "success",
            autoDismiss: true,
          });
        }
        //
        else {
          let content = "Something went wrong.";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }

    if (updateLegs.length > 0) {
      let arrPromise = [];
      let counter = updateLegs.length;
      let uploadCounter = 0;

      _.map(updateLegs, (item) => {
        // console.log("item updateLegs: ", item);

        arrPromise.push(
          new Promise((resolve, reject) => {
            updateEventClientLegs(session, item, (res) => {
              if (res.success) {
                uploadCounter++;
                resolve();
              } else {
                resolve();
              }
            });
          })
        );
      });

      Promise.all(arrPromise).then(() => {
        if (counter == uploadCounter) {
          setLoadingConfirm(false);
          fetchCourseDetails(session);
          showEditCourse(session);
          closeModal();

          let content = "Leg(s) updated successfully.";
          addToast(content, {
            appearance: "success",
            autoDismiss: true,
          });
        }
        //
        else {
          setLoadingConfirm(false);
          // closeModal();
          // fetchCourseDetails(session);
          // showEditCourse(session);
          let content = "Something went wrong.";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }
  };

  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className='modal'>
            <div className='modal-container'>
              {/*content*/}
              <div className='modal-content'>
                {/*header*/}
                <div className='modal-header'>
                  <div className='flex justify-start'>
                    <h3 className='text-base font-medium'>Edit Course</h3>
                    <img className='ml-3' src={info} />
                  </div>
                  <button
                    className='modal-close-btn'
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                {/* -------------------------- Static Div -------------------------- */}
                {/* <div className='relative flex-auto'>
                  <div className='grid grid-cols-2'>
                    <div>
                      <div className='bg-primary px-5 py-4 text-white text-sm font-medium'>
                        Your Course
                      </div>
                      <div
                        className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-3.5 gap-x-10 p-4 pr-10 overflow-y-auto'
                        style={{ maxHeight: 488 }}
                      >
                        {_.map(yourCourseArr, (item, i) => {
                          return (
                            <div className='relative cursor-pointer'>
                              <img src={item.img} />
                              <div
                                className='absolute text-base text-white w-full text-center'
                                style={{ bottom: 6 }}
                              >
                                {item.name}
                              </div>
                            </div>
                          );
                        })}

                        <div
                          className='border-2 border-dotted rounded'
                          style={
                            deviceSize.width >= 640
                              ? { maxHeight: 80, maxWidth: 140 }
                              : { height: 80, width: 140 }
                          }
                        ></div>
                      </div>
                    </div>

                    <div>
                      <div className='bg-primary-black px-5 py-4 text-white text-sm font-medium'>
                        Alternate Legs
                      </div>
                      <div
                        className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-3.5 gap-x-10 p-4 pr-10 border-l overflow-y-auto'
                        style={{ maxHeight: 488 }}
                      >
                        {_.map(alternateLegsArr, (item, i) => {
                          return (
                            <div className='relative cursor-pointer col-span-3 sm:col-span-1'>
                              <img src={item.img} />
                              <div
                                className='absolute text-base text-white w-full text-center'
                                style={{ bottom: 6 }}
                              >
                                {item.name}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* -------------------------- END * Static Div -------------------------- */}

                {/* -------------------------------- React Beautiful DND -------------------------------- */}
                {/* <div className='relative flex-auto'>
                  <div className='grid grid-cols-2'>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <div>
                        <div className='bg-primary px-5 py-4 text-white text-sm font-medium'>
                          Your Course
                        </div>
                        <Droppable droppableId='droppable'>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              className='grid grid-cols-1 gap-y-3.5 gap-x-10 p-4 pr-10 overflow-y-auto'
                            >
                              {_.map(items, (item, i) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      className='relative cursor-pointer'
                                    >
                                      <img src={item.img} />
                                      <div
                                        className='absolute text-base text-white w-full text-center'
                                        style={{ bottom: 6 }}
                                      >
                                        {item.name}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>

                      <div>
                        <div className='bg-primary-black px-5 py-4 text-white text-sm font-medium'>
                          Alternate Legs
                        </div>
                        <Droppable droppableId='droppable2'>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              className='grid grid-cols-1 gap-y-3.5 gap-x-10 p-4 pr-10 border-l overflow-y-auto'
                            >
                              {_.map(selected, (item, i) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      className='relative cursor-pointer col-span-3 sm:col-span-1'
                                    >
                                      <img src={item.img} />
                                      <div
                                        className='absolute text-base text-white w-full text-center'
                                        style={{ bottom: 6 }}
                                      >
                                        {item.name}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </DragDropContext>
                  </div>
                </div> */}

                <div className='relative flex-auto'>
                  <div className='grid grid-cols-2 divide-x divide-gray-300'>
                    <div className='bg-primary px-5 py-4 text-white text-sm font-medium'>
                      Your Course
                    </div>
                    <div className='bg-primary-black px-5 py-4 text-white text-sm font-medium'>
                      Alternate Legs
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                      {state.map((el, ind) => (
                        <div>
                          {/* {ind == 0 ? (
                            <div className='bg-primary px-5 py-4 text-white text-sm font-medium'>
                              Your Course
                            </div>
                          ) : (
                            <div className='bg-primary-black px-5 py-4 text-white text-sm font-medium'>
                              Alternate Legs
                            </div>
                          )} */}

                          <Droppable key={ind} droppableId={`${ind}`}>
                            {(provided, snapshot) => (
                              <div
                                // className={
                                //   ind == 0
                                //     ? "grid grid-cols-1 gap-y-3.5 gap-x-10 p-4 pr-10 overflow-y-auto"
                                //     : "grid grid-cols-1 gap-y-3.5 gap-x-10 p-4 pr-10 overflow-y-auto"
                                // }
                                className='grid grid-cols-1 gap-y-3.5 gap-x-10 p-4 pr-10 overflow-y-auto'
                                ref={provided.innerRef}
                                style={getListStyle(
                                  snapshot.isDraggingOver,
                                  ind
                                )}
                                {...provided.droppableProps}
                              >
                                {el.map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <div className='relative cursor-pointer'>
                                          <img
                                            className='rounded'
                                            src={item.img}
                                            style={{ height: 80, width: 140 }}
                                          />
                                          <div
                                            className='absolute text-base text-white w-full text-center'
                                            style={{ bottom: 6 }}
                                          >
                                            {item.name}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      ))}
                    </DragDropContext>
                  </div>
                </div>
                {/* -------------------------------- END * React Beautiful DND -------------------------------- */}

                {/* ------------------------------------------------- React Grid DND ------------------------------------------------- */}
                {/* <div className='relative flex-auto'>
                  <div className='grid grid-cols-2'>
                    <GridContextProvider onChange={onChange}>
                      <div>
                        <div className='bg-primary px-5 py-4 text-white text-sm font-medium'>
                          Your Course
                        </div>
                        <GridDropZone
                          className='dropzone grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-3.5 gap-x-10 p-4 pr-10 overflow-y-auto'
                          style={{ maxHeight: 488 }}
                          boxesPerRow={3}
                          rowHeight={150}
                        >
                          {_.map(yourCourseArr, (item, i) => {
                            return (
                              <GridItem key={item.name}>
                                <div className=''>
                                  <div className='relative cursor-pointer'>
                                    <img src={item.img} />
                                    <div
                                      className='absolute text-base text-white w-full text-center'
                                      style={{ bottom: 6 }}
                                    >
                                      {item.name}
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            );
                          })}

                          <div
                            className='border-2 border-dotted rounded'
                            style={
                              deviceSize.width >= 640
                                ? { maxHeight: 80, maxWidth: 140 }
                                : { height: 80, width: 140 }
                            }
                          ></div>
                        </GridDropZone>
                      </div>

                      <div>
                        <div className='bg-primary-black px-5 py-4 text-white text-sm font-medium'>
                          Alternate Legs
                        </div>
                        <GridDropZone
                          className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-3.5 gap-x-10 p-4 pr-10 border-l overflow-y-auto'
                          style={{ maxHeight: 488 }}
                          boxesPerRow={3}
                          rowHeight={70}
                        >
                          {_.map(alternateLegsArr, (item, i) => {
                            return (
                              <GridItem key={item.name}>
                                <div className='relative cursor-pointer col-span-3 sm:col-span-1'>
                                  <img src={item.img} />
                                  <div
                                    className='absolute text-base text-white w-full text-center'
                                    style={{ bottom: 6 }}
                                  >
                                    {item.name}
                                  </div>
                                </div>
                              </GridItem>
                            );
                          })}
                        </GridDropZone>
                      </div>
                    </GridContextProvider>
                  </div>
                </div> */}

                {/* --- Example --- */}
                {/* <GridContextProvider onChange={onChange}>
                  <div className='container'>
                    <GridDropZone
                      className='dropzone left'
                      id='left'
                      boxesPerRow={4}
                      rowHeight={70}
                    >
                      {items.left.map((item) => (
                        <GridItem key={item.name}>
                          <div className='grid-item'>
                            <div className='grid-item-content'>
                              {item.name[0].toUpperCase()}
                            </div>
                          </div>
                        </GridItem>
                      ))}
                    </GridDropZone>

                    <GridDropZone
                      className='dropzone right'
                      id='right'
                      boxesPerRow={4}
                      rowHeight={70}
                    >
                      {items.right.map((item) => (
                        <GridItem key={item.name}>
                          <div className='grid-item'>
                            <div className='grid-item-content'>
                              {item.name[0].toUpperCase()}
                            </div>
                          </div>
                        </GridItem>
                      ))}
                    </GridDropZone>
                  </div>
                </GridContextProvider> */}
                {/* ------------------------------------------------- END * React Grid DND ------------------------------------------------- */}

                {/*footer*/}
                <div className='modal-footer'>
                  <button
                    className={
                      disableConfirm
                        ? "bg-refresh text-white rounded inline-flex items-center justify-center cursor-not-allowed"
                        : "bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer"
                    }
                    style={{
                      height: 37,
                      width: deviceSize.width >= 414 ? 367 : "100%",
                    }}
                    onClick={() => handleConfirmCourse()}
                    disabled={disableConfirm}
                  >
                    {loadingConfirm ? (
                      <svg
                        // className='animate-spin h-5 w-5 rounded-full border-t-2 border-b-2 border-white mr-3'
                        className='animate-spin h-5 w-5 rounded-full border-b border-white mr-3'
                        viewBox='0 0 24 24'
                      ></svg>
                    ) : (
                      ""
                    )}
                    <span className='text-base'>Confirm Course</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-background'></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    showModal: state.showEditCourseModalReducer,
    showEditCourseReducer: state.showEditCourseReducer,
    eventClientReducer: state.eventClientReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showEditCourseModal,
      addEventClientLegs,
      removeEventClientLegs,
      updateEventClientLegs,
      showEditCourse,
      fetchCourseDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditCourse);
