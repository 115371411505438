import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import LocaleStrings from "../../languages";
import Loader from "../../components/Common/loader";
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../components/Common/constant";

import PhotoView from "./components/photo-view";

import { showPhotoViewModal, fetchCourseVideo } from "./actions";

import kevin_et from "../../assets/img/kevin-et-laurianne-langlai.png";
import backArrow from "../../assets/img/backarrow.png";
import fynn_schmidt from "../../assets/img/fynn-schmidt.png";
import martin_widenka from "../../assets/img/martin-widenka.png";
import adrian_dascal from "../../assets/img/adrian-dascal.png";
import coursevideobg from "../../assets/img/coursevideobg.png";
import leftarrow from "../../assets/img/leftarrow.png";
import rightarrow from "../../assets/img/rightarrow.png";
import watch_your_video_play from "../../assets/img/watch-your-video-play.png";
import all_cartoons from "../../assets/img/allcartoons.png";
import fynn_schmidt_large from "../../assets/img/fynn-schmidt-large.png";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const CourseVideo = (props) => {
  const {
    session,
    deviceSize,
    showPhotoViewModal,
    fetchCourseVideo,
    courseVideoReducer,
  } = props;

  const [currentLegIndex, setCurrentLegIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState({});

  const history = useHistory();
  const [loading, setLoading] = useState(
    _.isEmpty(courseVideoReducer) ? true : false
  );

  useEffect(() => {
    if (_.isEmpty(courseVideoReducer)) {
      fetchCourseVideo(session, (res) => {
        console.log("res: ", res);
        setLoading(false);

        // console.log("courseVideoReducer: ", courseVideoReducer);

        let arr = JSON.parse(JSON.stringify(res.data.eventclientlegs));
        let obj = {
          main: {},
          other: [],
        };

        if (arr.length > 0) {
          let leggallery = arr[0].leggallery;
          if (leggallery.length > 0) {
            let mainFilter = _.filter(leggallery, { ismain: true });
            let otherFilter = _.filter(leggallery, { ismain: false });

            obj = {
              main: mainFilter?.length > 0 ? mainFilter[0] : {},
              other: otherFilter?.length > 0 ? otherFilter : [],
            };
          }
        }

        setGalleryImages(obj);
      });
    } else {
      // console.log("courseVideoReducer: ", courseVideoReducer);
      let arr = JSON.parse(
        JSON.stringify(courseVideoReducer.data.eventclientlegs)
      );
      let obj = {
        main: {},
        other: [],
      };

      if (arr.length > 0) {
        let leggallery = arr[0].leggallery;
        if (leggallery.length > 0) {
          let mainFilter = _.filter(leggallery, { ismain: true });
          let otherFilter = _.filter(leggallery, { ismain: false });

          obj = {
            main: mainFilter?.length > 0 ? mainFilter[0] : {},
            other: otherFilter?.length > 0 ? otherFilter : [],
          };
        }
      }

      setGalleryImages(obj);
    }
  }, []);

  const handleBack = () => {
    const path = "/";
    history.push(path);
  };

  const openModal = (item) => {
    console.log("item: ", item);
    showPhotoViewModal({ show: true, item: item });

    // let img = "";
    // if (img == "") {
    //   showPhotoViewModal({ show: true, img: fynn_schmidt_large });
    // } else {
    //   showPhotoViewModal({ show: true, img: img });
    // }
  };

  const handleRightSlider = (e) => {
    console.log("handleRightSlider");

    let index = currentLegIndex;
    let arr = JSON.parse(
      JSON.stringify(courseVideoReducer.data.eventclientlegs)
    );

    if (index == arr.length - 1) {
      index = 0;
    } else {
      index++;
    }

    if (arr.length > 0) {
      let leggallery = arr[index].leggallery;
      let obj = {
        main: {},
        other: [],
      };

      if (leggallery.length > 0) {
        let mainFilter = _.filter(leggallery, { ismain: true });
        let otherFilter = _.filter(leggallery, { ismain: false });

        obj = {
          main: mainFilter?.length > 0 ? mainFilter[0] : {},
          other: otherFilter?.length > 0 ? otherFilter : [],
        };
      }

      setCurrentLegIndex(index);
      setGalleryImages(obj);
    }
  };

  const handleLeftSlider = (e) => {
    console.log("handleLeftSlider");

    let index = currentLegIndex;
    let arr = JSON.parse(
      JSON.stringify(courseVideoReducer.data.eventclientlegs)
    );

    if (index == 0) {
      index = arr.length - 1;
    } else {
      index--;
    }

    if (arr.length > 0) {
      let leggallery = arr[index].leggallery;
      let obj = {
        main: {},
        other: [],
      };

      if (leggallery.length > 0) {
        let mainFilter = _.filter(leggallery, { ismain: true });
        let otherFilter = _.filter(leggallery, { ismain: false });

        obj = {
          main: mainFilter?.length > 0 ? mainFilter[0] : {},
          other: otherFilter?.length > 0 ? otherFilter : [],
        };
      }

      setCurrentLegIndex(index);
      setGalleryImages(obj);
    }
  };

  console.log("galleryImages: ", galleryImages);

  return (
    <>
      {!loading ? (
        <div className="p-0 sm:p-2">
          <div
            className="bg-secondary p-2 sm:p-4"
            style={
              deviceSize.width > 640
                ? { borderRadius: 13 }
                : { borderRadius: 0 }
            }
          >
            <div>
              <div className="flex items-center">
                <div
                  className="bg-primary p-3 rounded cursor-pointer"
                  onClick={() => handleBack()}
                >
                  <img src={backArrow} />
                </div>
                <div className="text-base ml-3">
                  {courseVideoReducer?.data?.eventclientlegs?.length > 0
                    ? `${courseVideoReducer.data.eventclientlegs[
                        currentLegIndex
                      ]?.masterleg_by_legidfk?.name?.capitalize()}`
                    : ""}
                </div>
              </div>

              <div
                className="mt-3 bg-no-repeat bg-cover bg-center rounded-xl"
                // bg-kevin-et
                style={{
                  backgroundImage: `url('${BASE_AZIONV2_URL}${galleryImages?.main?.path}?api_key=${FILE_ACCESS_KEY}')`,
                }}
              >
                <div id="top-images-container">
                  <div className="p-4 flex items-center justify-start sm:justify-end">
                    {/* <div
                      className='cursor-pointer'
                      onClick={() => openModal(fynn_schmidt_large)}
                    >
                      <img src={fynn_schmidt} />
                    </div>
                    <div className='cursor-pointer ml-4'>
                      <img src={martin_widenka} onClick={() => openModal("")} />
                    </div>
                    <div className='cursor-pointer ml-4'>
                      <img src={adrian_dascal} onClick={() => openModal("")} />
                    </div> */}

                    {_.map(galleryImages.other, (item, i) => {
                      let src = `${BASE_AZIONV2_URL}${item.path}?api_key=${FILE_ACCESS_KEY}`;
                      return (
                        <div
                          className={
                            i ? "cursor-pointer ml-4" : "cursor-pointer"
                          }
                        >
                          <img
                            className="object-cover rounded"
                            src={src}
                            onClick={() => openModal(item)}
                            style={{ height: 58, width: 104 }}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className="flex justify-start sm:justify-end text-sm text-white pl-4 sm:pl-0 pr-0 sm:pr-4"
                    // sm:pr-32
                  >
                    {/* The Mortuary Temple of Khufu, Giza */}
                    {/* {galleryImages?.main?.title} */}
                    Click to view your photos
                  </div>
                </div>

                {deviceSize.width > 1000 ? (
                  <div id="watch-your-video-container">
                    <div className="flex justify-between px-4 pt-40 pb-8 relative">
                      <div>
                        <div
                          className="arrow-container-course-video"
                          onClick={(e) => handleLeftSlider(e)}
                        >
                          <img src={leftarrow} />
                        </div>
                      </div>
                      <div className="absolute" style={{ left: 130, top: 80 }}>
                        <div className="relative">
                          <img src={coursevideobg} />
                          <img
                            className="absolute"
                            src={watch_your_video_play}
                            style={{ top: 90, left: 90 }}
                          />
                          <div
                            className="absolute text-white text-sm"
                            style={{ top: 150, left: 55 }}
                          >
                            {LocaleStrings.watch_your_video}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          className="arrow-container-course-video"
                          onClick={(e) => handleRightSlider(e)}
                        >
                          <img src={rightarrow} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div id="watch-your-video-container-mobile">
                    <div className="flex justify-between px-4 pt-44 relative">
                      <div className="pt-20">
                        <div
                          className="arrow-container-course-video"
                          onClick={(e) => handleLeftSlider(e)}
                        >
                          <img src={leftarrow} />
                        </div>
                      </div>
                      <div>
                        <div className="relative">
                          <img className="h-48 w-48" src={coursevideobg} />
                          <img
                            className="absolute"
                            src={watch_your_video_play}
                            style={{ top: 68, left: 73 }}
                          />
                          <div
                            className="absolute text-white text-xs"
                            style={{ top: 125, left: 46 }}
                          >
                            {LocaleStrings.watch_your_video}
                          </div>
                        </div>
                      </div>
                      <div className="pt-20">
                        <div
                          className="arrow-container-course-video"
                          onClick={(e) => handleRightSlider(e)}
                        >
                          <img src={rightarrow} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div id="all-cartoons-container">
                  <div className="flex justify-center sm:justify-end items-center py-12 pl-12 pr-12 sm:pr-44">
                    <img src={all_cartoons} />
                  </div>
                </div>
              </div>
            </div>

            <PhotoView />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    courseVideoReducer: state.courseVideoReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showPhotoViewModal, fetchCourseVideo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CourseVideo);
