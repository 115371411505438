/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deviceSize, checkEvent } from "./actions";

import routes from "./routes";
import Navbar from "./components/Navbar";
import Dropdown from "./components/Dropdown";
import Loader from "./components/Common/loader";
import { BASE_FILES_URL, FILE_ACCESS_KEY } from "./components/Common/constant";

const App = (props) => {
  const { deviceSizeReducer, session, isLoggedIn, eventDetails } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isEventLoad, setEventLoad] = useState("");
  const history = useHistory();
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    var hostname = window.location.hostname;
    var subdomain = hostname.split(".")[0];
    // console.log("hostname :- ", hostname);
    // console.log("subdomain :- ", subdomain);

    props.checkEvent(session, subdomain, (response) => {
      // console.log("response: ", response);
      if (response.success) {
        setEventLoad("loadedcorrect");
      } else {
        setEventLoad("loadedwrong");
      }
    });

    if (!isLoggedIn && history?.location?.pathname !== "/registration-form") {
      const path = "/login";
      history.push(path);
    }

    // const hideMenu = () => {
    //   if (window.innerWidth > 768 && isOpen) {
    //     setIsOpen(false);
    //     console.log("i resizeeddd");
    //   }
    // };

    // window.addEventListener("resize", hideMenu);

    // return () => {
    //   window.removeEventListener("resize", hideMenu);
    // };
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const updateSize = () => {
    if (window.innerWidth < 576) {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: true,
      });
    } else if (window.screen.width < 576) {
      props.deviceSize({
        width: window.screen.width,
        height: window.screen.height,
        isMobile: true,
      });
    } else {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: false,
      });
    }

    // if (window.innerWidth > 768 && isOpen) {
    //   setIsOpen(false);
    //   console.log("i resizeeddd");
    // }
  };

  const getRoutes = (routes) => {
    return routes.map((item, idx) => {
      return (
        <Route path={item.path} exact component={item.component} key={idx} />
      );
    });
  };

  // console.log("eventDetails: ", eventDetails);
  let backgroundimage = "";
  if (eventDetails?.resource?.bgtype === "image") {
    backgroundimage = `${BASE_FILES_URL}${eventDetails.resource.bgvalue}?api_key=${FILE_ACCESS_KEY}`;
  }
  // console.log("backgroundimage: ", backgroundimage);

  return (
    <div>
      {isEventLoad !== "" ? (
        isEventLoad === "loadedcorrect" &&
        session &&
        session.eventid &&
        session.eventid !== "" ? (
          <div
            className="bg-no-repeat bg-cover md:px-8 lg:px-8 xl:px-32 pt-4 antialiased"
            style={
              eventDetails.resource.bgtype === "image"
                ? {
                    height: deviceSizeReducer.height > 766 ? "100vh" : "100%",
                    backgroundImage: `url(${backgroundimage})`,
                  }
                : {
                    height: deviceSizeReducer.height > 766 ? "100vh" : "100%",
                    backgroundColor: `${eventDetails.resource.bgvalue}`,
                  }
            }
          >
            <div
              className={deviceSizeReducer.width > 1440 ? "container" : ""}
              style={deviceSizeReducer.width > 1440 ? { width: 1180 } : {}}
            >
              <div>
                <Navbar routes={routes} toggle={toggle} />
                <Dropdown
                  key="menu-dropdown"
                  routes={routes}
                  isOpen={isOpen}
                  toggle={toggle}
                />
                <div
                  style={deviceSizeReducer.width > 1440 ? { width: 1180 } : {}}
                >
                  <Switch>{getRoutes(routes)}</Switch>
                </div>
                {isLoggedIn ? (
                  <>
                    {/* <Navbar routes={routes} toggle={toggle} />
              <Dropdown routes={routes} isOpen={isOpen} toggle={toggle} />
              <Switch>{getRoutes(routes)}</Switch> */}
                  </>
                ) : (
                  <>
                    {/* <Switch>
                {getRoutes(routes)}
                <Redirect from='/' to='/login' />
              </Switch> */}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Switch>
            <Redirect from="/" to="/404" />
          </Switch>
        )
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log("deviceSize: ", state.deviceSize);
  // console.log("eventDetails: ", state.eventDetails);

  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    deviceSizeReducer: state.deviceSize,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deviceSize, checkEvent }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
