/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { logout } from "../actions";
import { fetchAvatarDBData } from "../pages/avatarBuilder/actions";
import { fetchHomeScreenData } from "../pages/home/actions";
import arrowRotation from "../assets/img/arrow-rotation-interface.png";
import HomeIcon from "../assets/img/home.png";
import LocaleStrings from "../languages";

const Dropdown = (props) => {
  const {
    isOpen,
    toggle,
    routes,
    session,
    logout,
    fetchHomeScreenData,
    fetchAvatarDBData,
  } = props;

  const [loadRefresh, setLoadRefresh] = useState(false);

  const history = useHistory();

  const createLinks = () => {
    const routesFiltered = _.filter(props.routes, function (list) {
      return list.display;
    }); // custom check to not to display Home in navbar

    return routesFiltered.map((item, idx) => {
      // if (session.ismanager) {
      //   if (session.eventtype === "company") {
      //     if (item.path !== "/view-my-team" && item.path !== "/manage-class") {
      //       return (
      //         <Link className='p-4' to={item.path}>
      //           {item.name}
      //         </Link>
      //       );
      //     }
      //   } else {
      //     if (item.path !== "/view-my-team" && item.path !== "/manage-team") {
      //       return (
      //         <Link className='p-4' to={item.path}>
      //           {item.name}
      //         </Link>
      //       );
      //     }
      //   }
      // } else {
      //   if (
      //     item.path !== "/view-all-teams" &&
      //     item.path !== "/manage-class" &&
      //     item.path !== "/manage-team"
      //   ) {
      //     return (
      //       <Link className='p-4' to={item.path}>
      //         {item.name}
      //       </Link>
      //     );
      //   }
      // }

      // --- new changes ---
      if (session.ismanager) {
        if (session.eventtype == "company") {
          if (item.path != "/manage-class") {
            return (
              <Link
                key={`nav_key_${idx}`}
                className="p-4 text-white"
                to={item.path}
              >
                {item.name == "Home" ? (
                  <span className="">
                    <img src={HomeIcon} />
                  </span>
                ) : (
                  item.name
                )}
              </Link>
            );
          }
        } else {
          if (item.path != "/manage-team") {
            return (
              <Link
                key={`nav_key_${idx}`}
                className="p-4 text-white"
                to={item.path}
              >
                {item.name == "Home" ? (
                  <span className="">
                    <img src={HomeIcon} />
                  </span>
                ) : (
                  item.name
                )}
              </Link>
            );
          }
        }
      } else {
        if (item.path != "/manage-class" && item.path != "/manage-team") {
          return (
            <Link
              key={`nav_key_${idx}`}
              className="p-4 text-white"
              to={item.path}
            >
              {item.name == "Home" ? (
                <span className="">
                  <img src={HomeIcon} />
                </span>
              ) : (
                item.name
              )}
            </Link>
          );
        }
      }
    });
  };

  const handleLogout = () => {
    logout(session);
    history.push("/login");
  };

  const handleRefresh = () => {
    setLoadRefresh(true);
    fetchHomeScreenData(session, (res) => {
      // console.log("res:", res);
      fetchAvatarDBData(session, session.userid, (res1) => {
        // console.log("res: ", res1);
      });
      setLoadRefresh(false);
    });
  };
  return (
    <div
      className={
        isOpen
          ? "grid grid-rows-4 justify-center text-center items-center text-white"
          : "hidden"
      }
      onClick={toggle}
    >
      {createLinks()}
      <button
        className="bg-refresh rounded inline-flex items-center py-2 px-4 ml-2"
        onClick={() => handleRefresh()}
      >
        {loadRefresh ? (
          <svg
            className="animate-spin h-4 w-4 rounded-full border-t-2 border-b-2 border-white mr-0"
            // className='animate-spin h-5 w-5 rounded-full border-b border-white mr-3'
            viewBox="0 0 24 24"
          ></svg>
        ) : (
          <img src={arrowRotation} />
        )}
        <span className="ml-2">{LocaleStrings.button_refresh}</span>
      </button>
      <button
        className="bg-refresh rounded inline-flex items-center py-2 px-4 ml-2 cursor-pointer"
        onClick={() => handleLogout()}
      >
        <span className="">{LocaleStrings.button_logout}</span>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log("eventDetails: ", state.eventDetails);

  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    deviceSizeReducer: state.deviceSize,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { logout, fetchHomeScreenData, fetchAvatarDBData },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
